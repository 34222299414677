let vehicleModelCode = [
  //#region 理想

  //#region 理想L6
  {//理想L6 Pro
    title: "理想L6 Pro",
    codeNumber: "5def3ff49d034e3a8c55a4cf11ef6109",//车型版本code
    description1: "LI Auto фокусируется на создании интеллектуальных электромобилей, которые подходят для семейного отдыха, и LI L6 Pro, как важный представитель серии LI L6, поддерживает философию бренда - комфорт, интеллект и практичность - и предоставляет пользователям экономически эффективные решения для передвижения.",
    description2: "Длина, ширина и высота LI L6 Pro составляют 4925/1960/1735 мм соответственно, колесная база - 2920 мм, а общие линии плавные и выразительные. Его простой и атмосферный внешний вид, с закрытой решеткой радиатора спереди и уникальными передними фарами, демонстрирующими ощущение современных технологий.",
    description3: "Оснащенный двухмоторной интеллектуальной системой полного привода, автомобиль разгоняется до 100 километров всего за 5,4 секунды, что делает его мощным транспортным средством. Сиденья в салоне выполнены из кожи Nappa с вентиляцией, подогревом и функцией 10-точечного массажа для передних сидений, что делает поездку комфортной. Оснащен интерактивной системой высокой четкости с четырьмя экранами, включая экран для пассажиров и HUD сверхвысокой четкости, для улучшения впечатлений от вождения. Благодаря использованию литий-железо-фосфатной батареи, запас хода составляет 1 390 км, запас хода на электротяге - 212 км, поддерживается быстрая зарядка."
  },
  {//理想L6 Max
    title: "理想L6 Max",
    codeNumber: "a49bf07baeb84a04b394819bff50e5ba",//车型版本code
    description1: "Компания LI Auto стремится к тому, чтобы обеспечить пользователям первоклассный опыт путешествий. LI L6 Max занимает верхнюю позицию в серии LI L6 и предназначен для семейных пользователей, которые ищут максимальную комплектацию и интеллектуальные возможности.",
    description2: "По внешнему виду LI L6 Max такой же, как L6 Pro, с плавными и вытянутыми линиями кузова, такими же размерами по длине, ширине и высоте, а также колесной базой. Уникальная закрытая решетка радиатора в сочетании с острыми фарами создает полное ощущение технологичности.",
    description3: "LI L6 MAX сопоставим с L6 Pro по мощности и комфорту: тот же интеллектуальный полный привод с двумя двигателями и сиденья из кожи Nappa с вентиляцией, подогревом и массажем. Разница в том, что в L6 Max добавлен небольшой холодильник и более совершенная аудиосистема. Что касается интеллектуального вождения, то в стандартную комплектацию входит система интеллектуального вождения AD Max, добавляющая 1 камеру и 1 LIDAR на базе L6 Pro, оснащенная двумя чипами NVIDIA Orin - X с арифметической мощностью 508TOPS, которые могут поддерживать более сложные функции интеллектуального городского вождения на последующих этапах, а опыт интеллектуального вождения становится еще лучше."
  },
  //#endregion

  //#region 理想L7
  {//理想L7 Pro
    title: "理想L7 Pro",
    codeNumber: "26b3235dae1c45d4acde98cb679fa1cb",//车型版本code
    description1: "LI Auto глубоко предана рынку семейных путешествий. LI L7 Pro, как основная модель LI L7, удовлетворяет потребности семьи в ежедневных поездках и дальних путешествиях, обладая отличными пространственными характеристиками и интеллектуальной конфигурацией, и пользуется большой популярностью у потребителей.",
    description2: "LI L7 Pro имеет широкий кузов, а его общая форма выглядит стильно и атмосферно. Передняя часть имеет уникальный дизайн, группа фар сочетается с решеткой радиатора, демонстрируя неповторимый стиль. Боковые линии кузова плавные, создавая у людей ощущение стабильности. В задней части автомобиля установлены светодиодные фары, которые отличаются простотой и выразительностью, а также обеспечивают высокий уровень видимости в темное время суток.",
    description3: "LI L7 PRO оснащен полным приводом с двумя 1,5T двигателями с увеличенным запасом хода , что позволяет легко справляться с различными дорожными условиями. Благодаря системе увеличения дальности хода, чисто электрический режим может обеспечить ежедневные поездки, а запас хода на топливе и электричестве превышает 1100 км для дальних поездок.Что касается интеллектуальной системы помощи при вождении, то в стандартную комплектацию входит система пневматической подвески Magic Carpet pro, которая также обновлена до AD Max Intelligent Driving Assistance с более мощными интеллектуальными функциями вождения, а для улучшения общего впечатления от автомобиля добавлены новый интеллектуальный холодильник двойного назначения с функциями охлаждения и подогрева и аудиосистема Platinum."
  },
  {//理想L7 Max
    title: "理想L7 Max",
    codeNumber: "93264309a5b942619099a84b90b95cb4",//车型版本code
    description1: "LI L7 MAX LI Auto всегда ориентируется на потребности семейных пользователей, и LI L7 Max, как первоклассная модель LI L7, стал выбором для семейных поездок благодаря богатым конфигурациям и отличным характеристикам, и продолжает способствовать развитию интеллектуальных электромобилей в семейном секторе.",
    description2: "LI L7 MAX сохраняет тот же стиль, что и другие модели серии L7, с широким кузовом и плавными линиями. Передняя часть имеет уникальную форму, а фары и решетка радиатора выполнены гармонично, подчеркивая чувство стиля. Боковые линии автомобиля плавные и естественные, а задние светодиодные фары отличаются простым дизайном,  а также обеспечивают высокий уровень видимости в темное время суток.",
    description3: "LI L7 MAX оснащен полным приводом с двумя 1,5T двигателями с увеличенным запасом хода и разгоняется от 0 до 100 км/ч за 5,3 секунды.  Чисто электрический режим может удовлетворить повседневные поездки, а запас хода на топливно-электрической синергии для дальних путешествий превышает 1100 км. Что касается систем интеллектуального вождения, то модель оснащена 1 LIDAR и двумя чипами NVIDIA Orin-X с арифметической мощностью 508TOPS, способными обеспечить интеллектуальное вождение в любых условиях."
  },
  {//理想L7 Air
    title: "理想L7 Air",
    codeNumber: "3b80d4bc3d0444ad9eea5c8d480983d0",//车型版本code
    description1: "",
    description2: "",
    description3: ""
  },
  {//理想L7 Ultra
    title: "理想L7 Ultra",
    codeNumber: "54adce5cfe044d04bdbd5b4f636bdedb",//车型版本code
    description1: "Компания LI делает ставку на семейные поездки, и LI L7 Ultra, как топовая версия линейки LI L7, объединяет в себе передовые технологии бренда и первоклассные комплектации, стремясь обеспечить максимальную роскошь и комфорт в путешествиях для семейных пользователей.",
    description2: "С кузовом более 5 метров и колесной базой 3 метра LI L7 Ultra просторен и надежен. Дизайн кузова стильный и элегантный, уникальные фары и решетка радиатора дополняют друг друга. Боковые линии автомобиля плавные, а багажник на крыше выдерживает нагрузку в 40 кг, что одновременно практично и красиво. В задней части автомобиля установлены габаритные фары, отличающиеся простым и ярким дизайном и высокой узнаваемостью.",
    description3: "LI L7 Ultra оснащен полным приводом с двумя 1,5T двигателями с увеличенным запасом хода и мощностью в 449 л.с. и разгоняется от 0 до 100 км/ч за 5,3 секунды. С системой увеличенного запаса хода чисто электрический режим позволяет преодолевать 180 км в день, а дальний пробег на топливно-электрической синергии превышает 1100 км.  По сравнению с другими версиями, L7 Ultra оснащен 15,7-дюймовым задним развлекательным экраном, обновленной батареей емкостью 52,3 кВт-ч, увеличенным запасом хода на электротяге CLTC до 286 км и 21-дюймовыми колесами,  с более мощными интеллектуальными функциями помощи водителю."
  },
  //#endregion

  //#region 理想L8
  {//理想L8 Pro
    title: "理想L8 Pro",
    codeNumber: "7b7b3e4cf6cc4fa296b21b5581dbab32",//车型版本code
    description1: "LI L8 Pro, являясь ключевой моделью LI Auto, следует философии бренда, сосредоточенной на создании высококачественных интеллектуальных электромобилей большого пространства для семей, и имеет хорошую репутацию на рынке.",
    description2: "LI L8 Pro обладает внушительными размерами кузова и крупными формами. В дизайне передней части чувствуется иерархия, а уникальная форма фар с решеткой воздухозаборника создает выдающийся визуальный эффект. Боковые линии кузова жесткие и плавные, с соблюдением пропорций, демонстрируют стабильный и атмосферный стиль. Дизайн задней части прост, а задние фары обеспечивают высокий уровень четкости.",
    description3: "LI L8 Pro оснащен большим центральным экраном управления и богатой интеллектуальной системой взаимосвязи, которая удобна и плавна в управлении, и может реализовать различные интеллектуальные интерактивные возможности. Что касается мощности, LI L8 Pro оснащен высокоэффективной системой увеличения запаса хода, которая в сочетании с электрическим приводом обеспечивает высокую мощность. Интеллектуальная система помощи водителю постоянно совершенствуется для оказания точной помощи в соответствии с дорожными условиями и сценариями движения, обеспечивая водителям более безопасное вождение."
  },
  {//理想L8 Max
    title: "理想L8 Max",
    codeNumber: "684c217019c748eea098fd53ccddf0e7",//车型版本code
    description1: "LI Auto уделяет особое внимание потребностям семейных поездок, и LI L8 Max занимает верхнюю позицию в серии LI L8, обладая превосходной конфигурацией и производительностью, обеспечивая лучшее интеллектуальное решение для семей, стремящихся к высокому качеству жизни.",
    description2: "По внешнему дизайну LI L8 Max не отличается от L8 Pro, обладая мощным кузовом и внушительными размерами. Уникальный язык дизайна передней части кузова сочетается с острыми фарами, что создает большой визуальный эффект.  Боковые линии кузова плавные, дизайн задних фар прост, но элегантен, а также обеспечивают высокий уровень четкости.",
    description3: "LI L8 Max оснащен большим центральным и задним развлекательным экраном, образуя универсальный интеллектуальный интерактивный кокпит, удовлетворяющий потребности пассажиров в развлечениях в дороге. Что касается мощности, то автомобиль оснащен четырехцилиндровым двигателем объемом 1,5 л и аккумуляторной батареей большой емкости, обеспечивающей высокую мощность и большой запас хода. Что касается систем интеллектуального вождения, то автомобиль оснащен одним LiDAR, двумя чипами NVIDIA Orin-X с арифметической мощностью 508TOPS и мощной системой интеллектуального вождения LI AD Max, которая позволяет реализовать более высокий уровень интеллектуальной помощи при вождении, а также повысить безопасность и удобство поездок."
  },
  {//理想L8 Air
    title: "理想L8 Air",
    codeNumber: "7d3c744b9467497891c4cf3469728cf7",//车型版本code
    description1: "",
    description2: "",
    description3: ""
  },
  {//理想L8 Ultra
    title: "理想L8 Ultra",
    codeNumber: "1aa02044f9e8417c8b3429ff086c6dda",//车型版本code
    description1: "LI Auto всегда ориентируется на семейных пользователей, и LI L8 Ultra, как флагманская версия LI L8, сочетает в себе лучшие технологии и роскошные характеристики бренда, и стремится создать необыкновенное удовольствие от поездок для семейных пользователей и установить новую планку для интеллектуальных электрических внедорожников.",
    description2: "LI L8 Ultra обладает большим размером кузова, а его общий стиль отличается элегантностью и стабильностью, не теряя при этом чувства стиля. Уникальный стиль дизайна передней части автомобиля и изящный дизайн фар оказывают сильный визуальный эффект. Боковые линии автомобиля плавные и естественные, с гармоничными пропорциями, демонстрирующие темперамент роскошного внедорожника. LI L8 Ultra обладает большим размером кузова, а его общий стиль отличается элегантностью и стабильностью, не теряя при этом чувства стиля. Уникальный стиль дизайна передней части автомобиля и изящный дизайн фар оказывают сильный визуальный эффект. Боковые линии автомобиля плавные и естественные, с гармоничными пропорциями, демонстрирующие темперамент роскошного внедорожника. В задней части автомобиля установлены светодиодные фары, которые отличаются простотой и выразительностью, а также обеспечивают высокий уровень видимости в темное время суток.",
    description3: "LI L8 Ultra - просторный и роскошный, с сиденьями из высококачественных материалов и широким спектром передовых функций комфорта. С точки зрения интеллектуального вождения, в дополнение к мощной системе LI AD Max Intelligent Driving System, он также усовершенствован в деталях, таких как колеса большего размера и т.д., и общая конфигурация стала еще более роскошной, обеспечивая пользователям всесторонние высококлассные впечатления от поездки."
  },
  //#endregion

  //#region 理想L9 
  {//理想L9 Ultra
    title: "理想L9 Ultra",
    codeNumber: "32bdc3183c06488eb3130103ae11f736",//车型版本code
    description1: "LI Auto продолжает осваивать сферу интеллектуальной электрической мобильности. LI L9 Ultra, как топовая версия LI L9, представляет собой отличное сочетание стиля и производительности. L9 Ultra идеально подходит для долгих поездок, предоставляя непревзойденный уровень комфорта и высокие технологии в каждом аспекте.",
    description2: "Внешне LI L9 Ultra и L9 Pro обладают одинаковым стилем, с большим и внушительным кузовом. Уникальный стиль дизайна передней части кузова с острыми фарами. Боковые линии автомобиля плавные, демонстрирующие атмосферу роскошного внедорожника. В задней части автомобиля установлены светодиодные фары, которые отличаются простотой и выразительностью, а также обеспечивают высокий уровень видимости в темное время суток.",
    description3: "Сиденья LI L9 Ultra выполнены из высококачественных материалов и оснащены множеством функций, таких как электрорегулировка в нескольких направлениях, подогрев, вентиляция и массаж. Оснащенный большим центральным экраном управления и задним развлекательным экраном, он образует универсальный интеллектуальный интерактивный кокпит с богатыми развлекательными функциями. Что касается мощности, то он оснащен 1,5-цилиндровым четырехцилиндровым двигателем и аккумуляторной батареей большой емкости, что обеспечивает высокую мощность и отличный запас хода."
  },
  {//理想L9 Pro
    title: "理想L9 Pro",
    codeNumber: "7be1d587e893482e9840e8074d08336c",//车型版本code
    description1: "Позиционируемый как полноразмерный интеллектуальный электрический внедорожник класса люкс, LI L9 Pro является важной моделью в серии элитных автомобилей LI Auto, которая специально разработана для семейных пользователей, стремящихся к максимальному комфорту и интеллектуальному взаимодействию.",
    description2: "LI L9 Pro обладает внушительными размерами кузова, демонстрируя свое превосходство. Передняя часть автомобиля выполнена в уникальном стиле, а комбинация фар и решетки радиатора дополняет друг друга, оказывая мощный визуальный эффект. Линии кузова простые и плавные, а боковые части высокие и мощные. Дизайн задней части кузова относительно прост, задние фары еще больше повышают узнаваемость автомобиля, общий вид дизайна сочетает в себе роскошь и технологии.",
    description3: "LI L9 PRO оснащен большим центральным экраном управления и интеллектуальной системой взаимосвязи, которая проста в управлении и может реализовать различные интеллектуальные интерактивные возможности. Что касается мощности, то он оснащен 1,5-тонным четырехцилиндровым двигателем и аккумуляторным блоком большой емкости. Запас хода на чистом электричестве WLTC составляет 180 км, что позволяет удовлетворить потребности в поездках на дальние расстояния."
  },
  //#endregion

  //#region 理想Mega
  {//
    title: "理想Mega",
    codeNumber: "e2aa550b884d4040a83398686c68b160",//车型版本code
    description1: "LI Mega - это инновационный проект LI Auto в области производства минивэнов, продолжающий глубокий анализ и изучение потребностей семейных путешествий, предлагающий пользователям новый интеллектуальный электрический MPV и открывающий новые возможности для семейных поездок.",
    description2: "LI Mega отличается уникальным и инновационным дизайном кузова, приняв новую концепцию дизайна с плавными и динамичными линиями. Передняя часть кузова имеет уникальную форму со светодиодными фарами и закрытой решеткой радиатора, что придает ему футуристический вид.",
    description3: "LI Mega оснащен передовой интеллектуальной системой кокпита с большим центральным экраном управления с интеллектуальными возможностями подключения, удобным управлением и богатыми развлекательными функциями. Что касается мощности, то LI Mega оснащен высокоэффективной системой электропривода, обеспечивающей плавную и мощную отдачу энергии, а также запас хода, способный удовлетворить ежедневные и дальние поездки. Что касается безопасности, то он оснащен множеством систем активной и пассивной безопасности для поездок."
  },
  
  //#endregion

  //#endregion


  //#region 极氪

  //#region 极氪001
  {//极氪001 YOU版
    title: "极氪001 YOU版",
    codeNumber: "9fa63496aa0c4b999e8ad0f266cea387",//车型版本code
    description1: "Zeekr - дочерняя компания Geely, является китайской автомобильной компанией и брендом, специализирующимся на премиальных электромобилях. Слияние стильного дизайна, передовых технологий и стремления к устойчивости делает электромобили Zeekr идеальным выбором для тех, кто ищет экологически чистые и инновационные автомобили. Zeekr 001 YOU Edition - это флагман серии Zeekr 001 с богатой комплектацией, удовлетворяющий стремление пользователей к высококлассным путешествиям.",
    description2: "Zeekr 001 YOU Edition обрел форму охотничьего купе с плавными линиями, очерчивающими динамичный облик, с размерами кузова 4970 мм x 1999 мм x 1560 мм и колесной базой 3005 мм. Запоминается прежде всего характерным силуэтом, а также стильными полосками ходовых огней на передних крыльях, а уникальный дизайн воздухозаборника создает ощущение технологичности. Безрамная конструкция дверей не только эстетична, но и усиливает ощущение премиальности всего автомобиля, а крупногабаритные диски имеют уникальную форму, подчеркивая спортивный характер.",
    description3: "Zeekr 001 YOU оснащен двухмоторной системой полного привода и разгоняется с нуля до сотни всего за 3,8 секунды. Оснащенный литий-ионным троичным аккумулятором емкостью 100 кВт/ч, запас хода CLTC составляет 732 км, а салон оборудован сиденьями из высококачественной кожи Nappa с электрорегулировками в нескольких направлениях, функциями вентиляции, подогрева и массажа, а также 15,4-дюймовым центральным экраном управления с чипом 8155, который обеспечивает бесперебойную работу системы автомобиля. В стандартную комплектацию входит интеллектуальная система помощи водителю ZAD Zeekr, оснащенная множеством камер и радаров для обеспечения всесторонней безопасности во время движения. "
  },
  {//极氪001 ME版
    title: "极氪001 ME版",
    codeNumber: "cf7d1529038b4e2583a781b1e70460bf",//车型版本code
    description1: "Компания Zeekr всегда ориентировалась на пользователя, и модель Zeekr 001 ME Edition продолжает стремление бренда к качеству и производительности, предоставляя потребителям более экономичные решения благодаря оптимизированным конфигурациям.",
    description2: "По внешнему виду версия Zeekr 001 ME повторяет версию YOU и выполнена в стиле охотничьего купе, сочетающего в себе элегантность седана и практичность внедорожника. Запоминается прежде всего характерным силуэтом, а также стильными полосками ходовых огней на передних крыльях. Безрамная конструкция дверей не только эстетична, но и усиливает ощущение премиальности всего автомобиля, а крупногабаритные диски имеют уникальную форму, подчеркивая спортивный характер.",
    description3: "Zeekr 001 ME также оснащен двухмоторной системой полного привода и разгоняется с нуля до сотни всего за 3,8 секунды. Оснащенный батареей Kirin емкостью 140 кВт/ч, запас хода CLTC достигает 1032 км, что решает проблемы пользователей с дальностью поездки. Интерьер выполнен из высококачественных материалов, а сиденья удобны благодаря множеству регулируемых функций. Интеллектуальные конфигурации включают большую центральную панель управления и широкий спектр бортовых систем для удовлетворения потребностей повседневного использования."
  },
  {//极氪001 WE版
    title: "极氪001 WE版",
    codeNumber: "dfc0b0601691462389b0cf8611f3c1d1",//车型版本code
    description1: "Компания Zeekr стремится к созданию разнообразной линейки товаров, Zeekr 001 WE Edition, как стартовая модель, позволяет большему числу потребителей ощутить все прелести бренда Zeekr благодаря практичным конфигурациям и доступным ценам. ",
    description2: "Дизайн Zeekr 001 WE Edition продолжает дизайн серии Zeekr 001: характерный силуэт, стильные полоски ходовых огней на передних крыльях. Безрамная конструкция дверей не только эстетична, но и усиливает ощущение премиальности всего автомобиля, а крупногабаритные диски имеют уникальную форму, подчеркивая спортивный характер.",
    description3: "Zeekr 001 WE оснащен двухмоторной системой полного привода и разгоняется с нуля до сотни за 3,8 секунды. На выбор предлагаются батареи емкостью 86 и 100 кВт-ч, с запасом хода CLTC 546 км и 712 км соответственно. Салон оборудован в соответствии с потребностями повседневного использования, с базовыми регулировками сидений, а экран на центральной консоли объединяет множество автомобильных функций, обеспечивая водителю удобство управления."
  },
  //#endregion

  //#region 极氪009
  {//极氪009 六座行政版四驱
    title: "极氪009 六座行政版四驱",
    codeNumber: "79adf5dbf6b54c759afc1c633f296187",//车型版本code
    description1: "Zeekr 009 - шедевр бренда Zeekr в области MPV, 6-местный представительский полноприводный Zeekr 009 определяет стандарты высококлассных MPV с роскошной конфигурацией и пространством, удовлетворяя различные потребности деловых и семейных поездок.",
    description2: "Zeekr 009 отличается квадратным дизайном кузова с габаритами 5209 мм x 2024 мм x 1856 мм и колесной базой 3205 мм. Особенностью дизайна Zeekr 009 являются аэродинамические линии кузова, крупная решетка радиатора, типичная для роскошных минивэнов, и выразительная оптика выполненная с использованием 154 светодиодов. Боковые линии кузова просты и плавны, а плавающая линия крыши создает легкий визуальный эффект.",
    description3: "Zeekr 009 стандартно поставляется с полным приводом и двумя двигателями, с мощностью в 400 кВт и максимальным крутящим моментом 686 Нм, разгоняясь от 0 до 100 км/ч за 4,5 секунды. Есть две версии с аккумуляторами ёмкостью 116 и 140 кВтч, обеспечивающими запас хода по циклу CLTC в 702 и 822 км соответственно. В салоне установлены высококачественные сиденья из супермягкой анилиновой кожи, а второй ряд сидений представлен отдельными креслами представительского класса с электрорегулировками в нескольких направлениях, а также с функциями вентиляции, подогрева и массажа. Автомобиль оснащен большим центральным экраном управления и задним развлекательным экраном с богатой интеллектуальной конфигурацией"
  },
  {//极氪009 七座过道版四驱
    title: "极氪009 七座过道版四驱",
    codeNumber: "e76b7c8c99024844ba09c4632bf54b68",//车型版本code
    description1: "Zeekr 009 7-местный Aisle Edition 4WD продолжает демонстрировать высокий уровень роскоши Zeekr 009 и предоставляет более гибкие возможности для организации пространства, оптимизируя расположение сидений для удовлетворения потребностей в семейных поездках",
    description2: "Дизайн кузова 7-местного Zeekr 009 Aisle Edition 4WD остался таким же, как и у других версий Zeekr 009, квадратный кузов обеспечивает массу свободного пространства в салоне. Zeekr 009 выделяется своим запоминающимся дизайном, который сочетает в себе строгие линии и элегантные формы.",
    description3: "Zeekr 009 7-местный Aisle Edition 4WD также оснащен полным приводом и двумя двигателями, обеспечивающими высокую мощность. Что касается сидений, то они расположены по схеме 2+2+3, второй ряд сидений оснащен электрическими подставками для ног, функциями подогрева и вентиляции, а пассажирам удобно заходить и выходить из третьего ряда. Салон богат интеллектуальными конфигурациями, оснащен большим центральным экраном управления и бортовыми системами, повышающими удобство вождения и поездок."
  },
  {//极氪009 七座过道版
    title: "极氪009 七座过道版",
    codeNumber: "16e6c436b80b4eb0aacf28f5c3397200",//车型版本code
    description1: "Компания Zeekr стремится предоставить пользователям разнообразные варианты путешествий, и 7-местная модель Zeekr 009 Aisle Edition - это надежный выбор для семейных и деловых поездок с удобными функциями и просторным салоном.",
    description2: "Дизайн кузова Zeekr 009 7-seater Aisle Edition сохраняет единый стиль с другими версиями, а квадратная форма кузова не только увеличивает внутреннее пространство, но и создает атмосферу стабильности и величия. Уникальные задние фары. Интегрированная световая панель длиной 1,47 м придаёт смелый стиль и усиливает уникальную эстетику ZEEKR 009, делая его узнаваемой на дороге.",
    description3: "Zeekr 009 7-местный Aisle Edition приводится в движение одним задним электромотором. Внутри автомобиль имеет семиместную компоновку 2+2+3 с гибкой посадкой. Второй ряд индивидуальных сидений оснащен электрическими подставками для ног, функциями подогрева и вентиляции, третий ряд также более просторный. Автомобиль оснащен крупногабаритным центральным экраном управления, который объединяет в себе множество функций автомобиля."
  },
  //#endregion

  //#region 极氪X
  {//极氪X 四座魔方版
    title: "极氪X 四座魔方版",
    codeNumber: "0df783b774bd4df18f49bc02248ade83",//车型版本code
    description1: "Четырехместный ZEEKR X Rubik's Cube Edition - это новинка бренда ZEEKR в сегменте компактных чисто электрических внедорожников, которая отвечает потребностям молодых пользователей в индивидуальности и практичности благодаря уникальному дизайну и гибкой планировке пространства.",
    description2: "Четырехместный ZEEKR X Rubik's Cube Edition получил уникальный дизайн с чистыми и плавными линиями кузова и футуристической передней частью с закрытой решеткой радиатора в сочетании с острыми кластерами фар. Скрытые дверные ручки и большие колеса автомобиля подчеркивают стиль всего автомобиля.",
    description3: "Четырехместный ZEEKR X Rubik's Cube Edition предлагает как одномоторную заднеприводную, так и двухмоторную полноприводную силовую установку для удовлетворения потребностей различных пользователей. Салон автомобиля выполнен в четырехместной компоновке, второй ряд сидений представлен отдельными креслами с электрорегулировками и различными функциями комфорта. Центральная консоль оснащена большим центральным экраном управления, а автомобильная система - многофункциональная и оснащена интеллектуальными функциями помощи водителю для повышения безопасности движения."
  },
  {//极氪X 五座运动版
    title: "极氪X 五座运动版",
    codeNumber: "c9df01123c5d429b9faded0ddfb9f574",//车型版本code
    description1: "5-местный ZEEKR X Sport Edition продолжает стильный дизайн и интеллектуальные технологии бренда ZEEKR, а благодаря спортивному дизайну и комплектации обеспечивает потребителям еще более увлекательное путешествие.",
    description2: "5-местный ZEEKR X Sport Edition отличается от других моделей спортивным дизайном с плавными линиями кузова, а неповторимый дизайн передней части кузова и большие воздухозаборники придают ему еще более спортивный вид. Профиль автомобиля дополняют большие 19-дюймовые колеса с толстыми шинами размером 245/45 R19, которые не только улучшают управляемость и устойчивость на дороге, но и подчеркивают мощь и уверенность ZEEKR.",
    description3: "5-местный ZEEKR X Sport Edition оснащен системой полного привода с двумя мощными двигателями. В салоне пятиместная компоновка со спортивными сиденьями, обеспечивающими хорошую фиксацию и поддержку. Центральная консоль оснащена большим экраном центрального управления, а система автомобиля работает без сбоев. Он также оснащен множеством интеллектуальных функций помощи водителю и систем безопасности."
  },
  {//极氪X 五座长续航版
    title: "极氪X 五座长续航版",
    codeNumber: "31fb319106b444a9b65f47eb3e6af7ac",//车型版本code
    description1: "5-местный ZEEKR X Long Range Edition предлагает пользователям улучшенные характеристики по дальности хода для повседневных поездок и длительных путешествий, сохраняя при этом стильный дизайн и интеллектуальные функции бренда ZEEKR.",
    description2: "Дизайн 5-местного ZEEKR X Long Range Edition не отличается от других версий ZEEKR X. Чистые и плавные линии кузова и уникальный дизайн передней части демонстрируют стиль автомобиля. Боковые линии кузова просты, а плавающая линия крыши создает легкий визуальный эффект. Профиль автомобиля дополняют большие 19-дюймовые колеса с толстыми шинами размером 245/45 R19, которые не только улучшают управляемость и устойчивость на дороге, но и подчеркивают мощь и уверенность ZEEKR.",
    description3: "5-местный ZEEKR X Long Range Edition оснащен системой заднего привода с одним мотором и батареей повышенной емкости, а дальность хода на CLTC составляет до 560 км или 580 км. Салон автомобиля имеет пятиместную компоновку, удобные сиденья и ряд регулируемых функций. Центральная консоль оснащена большим центральным экраном управления, который объединяет в себе множество автомобильных функций, обеспечивая водителю удобство управления."
  },
  {//极氪X 五座后驱版
    title: "极氪X 五座后驱版",
    codeNumber: "bb4210f0e6754cb9b51f206a15f805cc",//车型版本code
    description1: "5-местная заднеприводная версия ZEEKR X представляет собой компактный чисто электрический внедорожник с практичными характеристиками и экономичной ценой, позволяя большему числу людей насладиться качеством бренда ZEEKR.",
    description2: "Дизайн кузова 5-местного ZEEKR X Rear Drive Edition стильный и динамичный, с закрытой решеткой радиатора и острыми кластерами фар спереди, создающими ощущение технологичности. Боковые линии кузова просты, а плавающая линия крыши создает легкий визуальный эффект.",
    description3: "5-местная заднеприводная версия ZEEKR X оснащена системой заднего привода с одним мотором, вполне  удовлетворяет потребности в ежедневных поездках. Салон имеет пятиместную компоновку, а сиденья выполнены из высококачественных материалов, обеспечивающих комфорт и долговечность. Центральная консоль оснащена большим центральным экраном управления, а автомобильная система - многофункциональная и оснащена базовыми интеллектуальными функциями помощи водителю для повышения безопасности движения."
  },
  //#endregion

  //#region 极氪007
  {//极氪007 后驱智驾版
    title: "极氪007 后驱智驾版",
    codeNumber: "3babb2ff800c4d3c8bac20f0779b6ddf",//车型版本code
    description1: "Zeekr 007 с задним приводом, ключевая модель в сегменте седанов марки Zeekr, сочетает в себе стильный дизайн, интеллектуальные технологии и превосходные ходовые качества, предлагая пользователям новый вариант передвижения.",
    description2: "Zeekr 007 Rear Drive Intelligent Drivе Edition отличается современным и спортивным дизайном, с обтекаемыми линиями, большими воздухозаборниками и изящными фарами. Аэродинамическая форма автомобиля способствует не только улучшению его управляемости, но и уменьшению сопротивления воздуха. Профиль автомобиля дополняют большие 19-дюймовые колеса с толстыми шинами размером 245/45 R19, которые не только улучшают управляемость и устойчивость на дороге, но и подчеркивают мощь и уверенность ZEEKR.",
    description3: "Заднеприводная версия Zeekr 007 оснащена одним задним электромотором, обеспечивающим большую мощность. Салон оснащен большим центральным экраном управления и интеллектуальной автомобильной системой, которой легко управлять. В стандартной комплектации автомобиль оснащен интеллектуальной системой помощи водителю ZAD Zeekr Intelligent Driver Assistance System, которая оборудована множеством камер и радаров для обеспечения всесторонней безопасности во время движения."
  },
  {//极氪007 长续航后驱智驾版
    title: "极氪007 长续航后驱智驾版",
    codeNumber: "d34c7761284e4368ba1419e91c86ec86",//车型版本code
    description1: "Zeekr 007 Long Range Rear Drive Intelligent Driving Edition решает проблемы пользователей, связанные с дальностью поездки, увеличивая запас хода и улучшая интеллектуальные системы помощи  водителю",
    description2: "Дизайн кузова полностью повторяет дизайн Zeekr 007 Rear Drive Intelligent Drive Edition: современный и спортивный дизайн, с обтекаемыми линиями, большими воздухозаборниками и изящными фарами. Задние фары Zeekr 007 имеют характерный и уникальный дизайн, который добавляет автомобилю дополнительную нотку эксклюзивности.",
    description3: "Zeekr 007 Long Range Rear Drive Intelligent Drivе Edition оснащен одним задним электромотором и батареей повышенной емкости, что увеличивает запас хода по CLTC. В салоне установлены удобные сиденья с множеством функций регулировки. В стандартную комплектацию входит интеллектуальная система помощи водителю ZAD Zeekr Intelligent Driver Assistance System, обеспечивающая более высокий уровень функций помощи водителю."
  },
  {//极氪007 四驱智驾版
    title: "极氪007 四驱智驾版",
    codeNumber: "2697eee126fb4d40ae6950010df9f640",//车型版本code
    description1: "Zeekr 007 4WD Intelligent Drivе Edition повышает мощность и устойчивость автомобиля за счет двухмоторной системы полного привода, а также сочетается с передовой интеллектуальной системой помощи водителю, чтобы подарить пользователям более яркие впечатления от поездок.",
    description2: "Zeekr 007 4WD Intelligent Drivе Edition продолжает традиции дизайна Zeekr 007: современный и спортивный дизайн, с обтекаемыми линиями, большими воздухозаборниками и изящными фарами. Профиль автомобиля дополняют большие 19-дюймовые колеса с толстыми шинами размером 245/45 R19, которые не только улучшают управляемость и устойчивость на дороге, но и подчеркивают мощь и уверенность ZEEKR.",
    description3: "Zeekr 007 4WD Intelligent Drivе Edition оснащен системой полного привода с двумя моторами, отличается более быстрым разгоном от нуля до сотни и мощной динамикой. Салон оснащен большим центральным экраном управления и интеллектуальной системой управления автомобилем. В стандартную комплектацию входит интеллектуальная система помощи водителю ZAD Zeekr Intelligent Driver Assistance System, которая оснащена большим количеством датчиков и более мощными алгоритмами для обеспечения более продвинутых функций помощи водителю."
  },
  {//极氪007 四驱性能版
    title: "极氪007 四驱性能版",
    codeNumber: "4839c6cdaed946f79a6b9ddcfcf196c1",//车型版本code
    description1: "",
    description2: "",
    description3: ""
  },
  //#endregion

  //#region 极氪001FR
  {//极氪001FR
    title: "极氪001FR",
    codeNumber: "09e73a66e1294c74b7d6ecbc5129b771",//车型版本code
    description1: "Являясь высокопроизводительной версией серии Zeekr 001, Zeekr 001 FR представляет собой прорыв в технологии и производительности, создавая первоклассный автомобиль на новой энергии для тех, кто стремится получить максимальный опыт вождения.",
    description2: "Zeekr 001 FR добавляет больше спортивных элементов дизайна, сохраняя при этом стиль охотничьего купе. Использование углеродного волокна не только снижает вес автомобиля, но и усиливает спортивное ощущение от всего автомобиля. Уникальный дизайн дисков и увеличенные воздухозаборники подчеркивают его высокую производительность",
    description3: "Zeekr 001 FR - первый в мире серийно выпускаемый электромобиль с распределенным электроприводом с четырьмя двигателями и системой векторизации крутящего момента ZVC Zeekr. Пиковый крутящий момент колес превышает 10 000 Нм, а разгон с нуля до сотни составляет всего 2,07 секунды. Аккумулятор Qilin емкостью 100 кВт-ч поддерживает высоковольтную платформу 800 В для более быстрой зарядки. В интерьере использовано большое количество материалов Alcantara, создающих спортивную атмосферу. Система Intelligent Driving оснащена передовыми датчиками и алгоритмами, обеспечивающими более высокий уровень функций помощи водителю."
  },
  //#endregion

  //#endregion


  //#region 蔚来

  //#region 蔚来ES7
  {//蔚来ES7 签名版
    title: "蔚来ES7 签名版",
    codeNumber: "ddb7b57adfee43588628411863e7314c",//车型版本code
    description1: "NIO, как новый бренд роскошных электромобилей, всегда стремился к созданию высококлассных путешествий благодаря инновационным технологиям и высочайшему уровню обслуживания. ES7 Signature Edition, как первоклассная версия ES7, объединяет в себе лучшие технологии и мастерство бренда, и создан по индивидуальному заказу для пользователей, которые стремятся к совершенству и индивидуальности.",
    description2: "ES7 Signature Edition продолжает дизайнерский язык семейства NIO: плавные и мощные линии кузова, параметры корпуса в длину, ширину и высоту составляют 4912 мм x 1987 мм x 1720 мм, а колесная база - 2960 мм. Кузов из гибрида стали и алюминия, а также расположение датчиков в виде башни Design for AD очень узнаваемы. Дизайн передней части «X-Bar» сочетается с полностью закрытой решеткой радиатора, а раздельные кластеры фар выглядят резко и привлекательно.",
    description3: "Что касается мощности, то ES7 Signature Edition оснащен системой полного привода с двумя двигателями спереди и сзади, причем передний двигатель на постоянных магнитах имеет максимальную мощность 180 кВт, а задний асинхронный двигатель - 300 кВт, а суммарная мощность составляет 480 кВт, разгон от нуля до сотни происходит всего за 3,9 секунды. Батарея емкостью 100 кВт/ч обеспечивает значительный запас хода CLTC. Салон выполнен из высококачественных материалов, а сиденья заднего ряда в стандартной комплектации оснащены функцией массажа и мягкими подушками, что повышает уровень комфорта. Модель оснащена 12,8-дюймовым центральным AMOLED-экраном с четким изображением. Имеется 16-дюймовый усовершенствованный дисплей (HUD) с горизонтальным обзором."
  },
  {//蔚来ES7
    title: "蔚来ES7",
    codeNumber: "13047c878f1b44139f2d176321dba353",//车型版本code
    description1: "Компания NIO стремится проникнуться духом интеллектуальной электрической мобильности, и ES7, главная модель бренда в сегменте среднеразмерных внедорожников, демонстрирует харизму бренда благодаря выдающимся характеристикам и интеллектуальным функциям, которые отвечают потребностям пользователей в ежедневных поездках и на дальние расстояния.",
    description2: "Дизайн кузова ES7 прост и резок, в нем воплощен новый язык дизайна, а концепция Design for AD впервые применена к внедорожнику.  Более «чистый» дизайн и выдающаяся аэродинамика, спортивный передний бампер, активная решетка радиатора и передний спойлер придают ES7 ярко выраженную спортивную атмосферу. Боковые линии кузова плавные, а дизайн плавающей линии крыши стильный и красивый. Передний и задний ряды в стандартной комплектации оснащены двухслойными бесшумными стеклами, эффективно изолирующими внешний шум. Люк открывается на 707 мм в длину, обеспечивая хорошее освещение и вентиляцию.",
    description3: "ES7 оснащен интеллектуальной системой Banyan второго поколения, в стандартную комплектацию входит сверхчувствительная система NIO Aquila и сверхмощная вычислительная платформа NIO Adam, которая может реализовать функцию автономного вождения NAD. Также в стандартную комплектацию входит пневматическая подвеска и система CDC (Dynamic Damping Control), а также система 4D контроля кузова, которая распознает неровности дороги и заранее настраивает подвеску."
  },
  //#endregion

  //#region 蔚来ES8
  {//蔚来ES8 签名版
    title: "蔚来ES8 签名版",
    codeNumber: "e1d7f62875404a25800a590e947beefa",//车型版本code
    description1: "Будучи топовой  моделью бренда NIO, флагманский внедорожник ES8 Signature Edition воплощает в себе стремление бренда к роскоши и производительности, создавая высококлассный автомобиль для путешествий и устанавливая новую планку для роскошных электрических внедорожников.",
    description2: "Дизайн кузова ES8 Signature Edition отличается роскошью, повторяя элементы фирменного дизайна, с большой решеткой радиатора и острыми фарами, создающими большой визуальный эффект. Боковые линии автомобиля строгие, а плавающие линии крыши добавляют ощущение стиля. Автомобиль большого размера и с просторным салоном. Дизайн задней части прост и выразителен, а дизайн задних фар усиливает узнаваемость.",
    description3: "ES8 Signature Edition оснащен высокопроизводительной системой полного привода с двумя моторами, которая обеспечивает высокую мощность и быстрое ускорение. Салон автомобиля выполнен из высококачественных материалов, а сиденья удобны и оснащены рядом высококлассных функций регулировки и комфорта, таких как вентиляция, подогрев и массаж. Оснащен центральным экраном управления большого размера и интеллектуальной системой, что делает управление удобным и плавным. Аудиосистема премиум-класса обеспечивает захватывающие впечатления от вождения. Интеллектуальная система помощи водителю оснащена множеством датчиков и передовых алгоритмов, обеспечивающих полный спектр помощи в обеспечении безопасности."
  },
  {//蔚来ES8 行政版
    title: "蔚来ES8 行政版",
    codeNumber: "af8d5ac25a5048f1b411ba53c7d3763d",//车型版本code
    description1: "Ориентируясь на потребности деловых поездок, модель ES8 Executive Edition сочетает в себе роскошные конфигурации и интеллектуальные технологии, чтобы предоставить бизнес-клиентам престижное, комфортное и эффективное решение для путешествий, демонстрируя глубокое понимание компанией NIO бизнес-рынка.",
    description2: "Дизайн кузова ES8 Executive Edition отличается стабильностью и атмосферностью, продолжая классические элементы дизайна серии NIO, а уникальная форма решетки радиатора в паре с фарами демонстрирует роскошный стиль. Боковые линии автомобиля плавные, а пропорции кузова гармоничны, демонстрируя безупречный стиль автомобиля представительского класса. Задняя часть автомобиля выполнена в простом дизайне, а задние фары имеют выразительную форму и хорошо различимы при ночном освещении.",
    description3: "ES8 Executive Edition имеет просторный салон с семиместной компоновкой 2+2+3, во втором ряде - индивидуальные сиденья представительского класса, которые поддерживают электрорегулировки в нескольких направлениях, вентиляцию, подогрев, массаж и другие функции, обеспечивая пассажирам максимальный комфорт. Автомобиль оснащен большим центральным экраном управления и развлекательным экраном в задней части салона, что делает его удобным для работы и развлечений. Мощная силовая установка и большой запас хода. Интеллектуальная система помощи водителю может оказывать точную помощь в зависимости от дорожных условий, повышая безопасность и удобство поездки."
  },
  {//蔚来ES8
    title: "蔚来ES8",
    codeNumber: "60e1dd2e0f264a6fbaf44fdf22b7946c",//车型版本code
    description1: "Будучи звездной моделью внедорожника бренда, ES8 с момента своего появления стал популярным выбором для семей и деловых путешественников, благодаря своей вместительности, отличным характеристикам и качественному обслуживанию, стимулируя развитие рынка роскошных электрических внедорожников.",
    description2: "ES8 имеет в целом атмосферные очертания, с жесткими и мощными линиями кузова. В передней части использован культовый язык дизайна, разделенные фары и уникальная комбинация решетки радиатора, высокая узнаваемость. Боковые линии кузова просты, а крупногабаритные колеса добавляют ощущение спортивности. Дизайн задней части относительно прост, уникальные кластеры фар подчеркивают общую эстетику автомобиля",
    description3: "ES8 оснащен системой полного привода с двумя моторами, которая обеспечивает достаточную мощность и легко справляется с различными дорожными условиями. Салон просторный, сиденья хорошо расположены, а для комфорта и долговечности использованы высококачественные материалы. Оснащен большим центральным экраном управления, объединяющим множество автомобильных функций, которыми удобно управлять водителю. Интеллектуальная система помощи водителю оснащена базовыми функциями, обеспечивающими необходимую степень безопасности вождения "
  },
  //#endregion

  //#region 蔚来EC7
  {//蔚来EC7 签名版
    title: "蔚来ES7 签名版",
    codeNumber: "6021d8fbbbf540f2afc30a03fbee0a63",//车型版本code
    description1: "Позиционируемый как роскошный электрический купе-внедорожник, EC7 Signature Edition отвечает потребностям элитных пользователей, которые стремятся к стилю и удовольствию от вождения, благодаря своему уникальному дизайну и отличным характеристикам, демонстрируя инновационную силу NIO в этом сегменте рынка.",
    description2: "Плавные линии боковых поверхностей кузова EC7 Signature Edition придают автомобилю динамичный и современный вид, подчёркивая спортивный характер. EC7 Signature Edition обладает большим кузовом и просторным салоном. Передняя часть кузова отличается уникальным дизайном: острые кластеры фар и закрытая решетка радиатора создают ощущение технологичности. Большие колеса еще больше усиливают спортивный характер автомобиля.",
    description3: "EC7 Signature Edition оснащен высокопроизводительной системой полного привода с двумя моторами, обеспечивающей мощное ускорение от нуля до сотни. Интерьер автомобиля выполнен из высококлассных материалов, а сиденья обладают отличной упругостью и поддержкой, а также множеством функций регулировки. Оснащен центральным экраном управления большого размера и интеллектуальной системой, что делает управление удобным и плавным. Интеллектуальная система помощи водителю оснащена передовыми датчиками и способна реализовать функции помощи водителю на высоком уровне."
  },
  {//蔚来EC7
    title: "蔚来ES7",
    codeNumber: "4918c7050cf04353bb91a72761b97ee5",//车型版本code
    description1: "Стильный электрический купе-внедорожник EC7 сочетает в себе передовые технологии NIO и модные дизайнерские концепции, предоставляя потребителям возможности для путешествий, отличающиеся как стоимостью, так и производительностью, расширяя ассортимент продукции бренда.",
    description2: "Дизайн кузова EC7 очень своеобразен, благодаря покатой форме задней части и плавным линиям кузова, демонстрирующим сочетание элегантности и спортивности. Передняя часть кузова повторяет дизайн семейства NIO, с уникальными кластерами фар и воздухозаборниками, подчеркивающими индивидуальность автомобиля. Боковые линии автомобиля просты и плавны, а колеса большого размера усиливают ощущение стиля всего автомобиля. Задняя часть автомобиля выполнена в простом дизайне, а задние фары имеют выразительную форму и хорошо различимы при ночном освещении.",
    description3: "EC7 оснащен системой полного привода с двумя моторами и мощной силовой установкой, способной удовлетворить потребности в различных сценариях вождения. Внутреннее пространство автомобиля довольно просторно, а сиденья удобны. Оснащен большим центральным экраном управления, система автомобиля богата функциями. Интеллектуальная система помощи водителю, оснащенная несколькими камерами и радаром, может обеспечить безопасность водителя."
  },
  //#endregion

  //#region 蔚来ET5
  {//蔚来ET5
    title: "蔚来ET5",
    codeNumber: "cf77cabaff044663b79cbee0681e1f86",//车型版本code
    description1: "Позиционируемый как роскошный среднеразмерный чисто электрический седан, ET5 привлекает молодых потребителей, стремящихся к высокому качеству и технологиям, своим молодежным и стильным дизайном, выдающимися характеристиками и продуманной конфигурацией, становясь представительской моделью бренда NIO.",
    description2: "Плавные линии боковых поверхностей кузова придают автомобилю динамичный и современный вид, подчёркивая спортивный характер. Передняя часть выполнена в семейном стиле X-Bar от NIO, а разделенная конструкция светодиодных дневных ходовых огней и блоков фар добавляет модный штрих. Скрытая конструкция дверных ручек усиливает ощущение технологичности всего автомобиля. Задняя часть автомобиля выполнена в простом дизайне, а задние фары имеют выразительную форму и хорошо различимы при ночном освещении.",
    description3: "ET5 оснащается одномоторной заднеприводной и двухмоторной полноприводной силовой установками для удовлетворения различных потребностей потребителей. Интерьер автомобиля выполнен из высококачественных материалов, а сиденья удобны и оснащены множеством регулируемых функций. Оснащенная 12,8-дюймовым центральным AMOLED-экраном система управления в автомобиле работает бесперебойно. Интеллектуальная система помощи водителю в стандартной комплектации оснащается суперсенсорной системой Aquila NIO, супервычислительной платформой Adam NIO с множеством датчиков, которая позволяет достичь определенной степени интеллектуальной помощи водителю."
  },
  //#endregion

  //#region 蔚来ET7
  {//蔚来ET7 行政签名版
    title: "蔚来ET7 行政签名版",
    codeNumber: "8984bc6b80fb428b9b36a1dd097d29ad",//车型版本code
    description1: "Являясь топовой моделью серии седанов NIO, ET7 Executive Signature Edition представляет собой идеальное сочетание роскоши, технологий и комфорта представительского уровня, создавая эксклюзивный мобильный офис и пространство для путешествий высокопоставленных людей.",
    description2: "Дизайн кузова ET7 Executive Signature Edition отличается сдержанностью и элегантностью, но при этом принадлежность авто к премиум-классу сразу видна. Передняя часть имеет уникальный дизайн, крупногабаритная решетка радиатора с острыми фарами, что создает потрясающий визуальный эффект. Боковые линии кузова просты, пропорциональны и гармоничны, подчеркивая роскошный стиль. Задняя часть автомобиля выполнена в простом дизайне, а задние фары имеют выразительную форму и хорошо узнаваемы.",
    description3: "Интерьер ET7 Executive Signature Edition выполнен из высококачественных материалов, а сиденья оснащены электрорегулировками в нескольких направлениях, вентиляцией, подогревом, массажем и другими функциями, обеспечивающими максимальный комфорт.  Оснащен большим центральным экраном управления и задним развлекательным экраном, что удобно для работы и отдыха. Оснащен высокопроизводительной системой полного привода с двумя моторами, обладающей высокой мощностью. Интеллектуальная система помощи водителю использует передовые датчики и алгоритмы, чтобы обеспечить всестороннюю безопасность и защиту."
  },
  {//蔚来ET7 行政版
    title: "蔚来ET7 行政版",
    codeNumber: "067854723e484fdfb37ae94b75cb55d1",//车型版本code
    description1: "ET7 Executive Edition специально разработан для деловых поездок, с просторным и удобным салоном, богатой комплектацией и интеллектуальными технологиями, чтобы удовлетворить потребности деловых людей в поездках, а также повысить качество и эффективность поездок.",
    description2: "Плавные линии боковых поверхностей кузова придают ET7 Executive Edition динамичный и современный вид. Передняя часть выполнена в семейном стиле NIO, а уникальная решетка радиатора и дизайн фар демонстрируют особую роскошь. ET7 Executive Edition  обладает большим кузовом и просторным салоном. Задняя часть автомобиля выполнена в простом дизайне, а задние фары имеют выразительную форму и хорошо узнаваемы.",
    description3: "Салон ET7 Executive Edition имеет четырехместную компоновку 2+2, а второй ряд оснащен индивидуальными сиденьями представительского класса и рядом комфортабельных функций. Он оснащен большим центральным экраном управления с интеллектуальной системой взаимодействия, которой удобно управлять. Мощная силовая установка отличается высокой производительностью и значительным запасом хода. Интеллектуальная система помощи водителю может выполнять функции помощи при движении в различных дорожных условиях, обеспечивая безопасность поездки."
  },
  //#endregion

  //#region 蔚来ET9
  {//蔚来ET9 签名版
    title: "蔚来ET9 签名版",
    codeNumber: "60b25c2ca41f45b7a3dc432c67e22d8a",//车型版本code
    description1: "ET9 Signature Edition, флагманский седан бренда Nio, представляет собой высочайший уровень технологий, дизайна и роскоши, являясь идеальным решением электрической мобильности для тех, кто стремится к максимальной степени комфорта.",
    description2: "Дизайн кузова ET9 Signature Edition выполнен в футуристическом стиле и представляет собой уникальный язык дизайна с плавными и четкими линиями. Передняя часть кузова отличается уникальной формой, с новым дизайном фар и закрытой решеткой радиатора, которая наполнена чувством технологии. Боковые линии автомобиля просты и плавны, а колеса большого размера усиливают ощущение стиля всего автомобиля. Задняя часть автомобиля выполнена в простом дизайне, а задние фары имеют выразительную форму и хорошо различимы при ночном освещении.",
    description3: "ET9 Signature Edition оснащен первоклассной системой полного привода с двумя моторами, которая обеспечивает превосходную мощность и быстрое ускорение от нуля до сотни. Интерьер автомобиля выполнен из высококачественных материалов, а сиденья оснащены множеством высококлассных функций регулировки и комфорта. Автомобиль оснащен большим центральным экраном управления и интеллектуальной системой взаимодействия, которой легко управлять. Интеллектуальная система помощи водителю оснащена передовыми датчиками и мощными алгоритмами, которые позволяют реализовать более совершенные функции интеллектуального вождения."
  },
  {//蔚来ET9
    title: "蔚来ET9",
    codeNumber: "ec9cb9ccc7af4abaa4c33f4fb447564c",//车型版本code
    description1: "ET9, как седан высшего класса, призван подарить пользователям незабываемые впечатления от поездок, и способствовать развитию рынка роскошных электрических седанов благодаря инновационным технологиям, роскошной конфигурации и выдающимся характеристикам.",
    description2: "Дизайн кузова ET9 отличается плавными линиями кузова, простотой и великолепием. Передняя часть кузова имеет уникальный дизайн, а фары и решетка радиатора - оригинальное сочетание. Боковые линии кузова простые и плавные, с согласованными пропорциями, демонстрирующие утонченный темперамент. Задняя часть автомобиля выполнена в простом дизайне, а задние фары имеют выразительную форму , что повышает общую эстетику и узнаваемость автомобиля.",
    description3: "ET9 оснащен высокопроизводительной системой электропривода, обеспечивающей высокую мощность. Просторный интерьер с использованием высококачественных материалов создает комфортные условия для вождения. Он оснащен центральным экраном управления большого размера с развитой бортовой системой и имеет множество интеллектуальных конфигураций. Интеллектуальная система помощи водителю оснащена множеством функций для оказания точной помощи в зависимости от дорожных условий."
  },
  //#endregion

  //#endregion


  //#region 阿维塔

  //#region 阿维塔11
  {//阿维塔11 Ultra 纯电版
    title: "阿维塔11 Ultra 纯电版",
    codeNumber: "d5e81e6f5f174e2c8011559f5dbdb776",//车型版本code
    description1: "Эта модель является воплощением стремления компании к совершенству, предлагая гармоничное сочетание ключевых аспектов, важных для современного автомобилиста. Avatr 12 Ultra Pure Electric представляет собой альтернативный выбор для тех, кто ценит технологичность, мощность и удобство в каждой поездке.",
    description2: "Avatr 11 Ultra Pure Electric Edition воплощает дизайнерскую концепцию Emotional Vortex, характеризующуюся обтекаемыми и современными  формами. Характерная изогнутая оптика и непрерывные световые линии обеспечивают мгновенную узнаваемость в темное время суток. Габариты автомобиля: длина – 4880 мм, ширина – 1970 мм, высота – 1601 мм, при колесной базе 2975 мм. Смещенная назад архитектура кузова, дополненная большими колесами, формирует изящный и энергичный силуэт,  а уникальная форма задней части и задний спойлер еще больше усиливают ощущение спортивности всего автомобиля.",
    description3: "Avatr 11 в комплектации Ultra Pure Electric использует силовую установку DriveONE от Huawei с двумя электродвигателями, обеспечивающими совокупную мощность 425 кВт и крутящий момент 850 Н·м, что позволяет ему разгоняться до 100 км/ч за 3,98 секунды. Питание осуществляется от литий-ионной батареи емкостью 116 кВт·ч, разработанной Ningde Times, обеспечивающей дальность хода до 680 км по циклу CLTC. Интерьер автомобиля включает в себя 10,25-дюймовый полноразмерный ЖК-дисплей приборной панели, крупный 15,6-дюймовый центральный экран управления и экран для развлечения пассажиров. Автомобильный компьютер работает на базе операционной системы Hongmeng OS, обеспечивающей удобство использования и высокую производительность."
  },
  {//阿维塔11 Ultra 纯电版四驱
    title: "阿维塔11 Ultra 纯电版四驱",
    codeNumber: "10736f21bfe742e994c9a1a5832d57d9",//车型版本code
    description1: "Полноприводная версия Avatr 11 Ultra Pure Electric Edition в линейке продукции бренда позиционируется как премиальная. Благодаря двухмоторной системе полного привода и богатой комплектации, она создана для потребителей, стремящихся к максимальной производительности и высокому качеству вождения, представляя собой топовый автомобиль и повышая конкурентоспособность бренда на рынке премиальных электромобилей.",
    description2: "Avatr 11 в комплектации Ultra Pure Electric Edition сохраняет узнаваемый облик всей линейки Avatr 11, отличаясь оригинальным дизайнерским решением, которое наделяет автомобиль футуристичными чертами. Плавные очертания кузова и крупные колесные диски акцентируют внимание на высоком классе электромобиля. Эта версия унаследовала стилистические особенности, присущие модельному ряду, но подчеркивает премиальность за счет элегантных деталей.",
    description3: "Avatr 11 Ultra Pure Electric, работающий исключительно на электричестве, использует двухмоторную систему полного привода DriveONE от Huawei, обеспечивающую значительную суммарную мощность, впечатляющее ускорение и высокую производительность. Питание осуществляется от литиевой батареи увеличенной емкости, поставляемой Ningde Times, что обеспечивает превосходный запас хода в соответствии с циклом CLTC. Внутреннее пространство автомобиля отделано материалами премиум-класса, а сиденья оборудованы множеством удобных функций, включая обогрев, вентиляцию и массажные режимы."
  },
  {//阿维塔11 皇家剧院版
    title: "阿维塔11 皇家剧院版",
    codeNumber: "c1feac0d57c14a1caddf632b377be7f2",//车型版本code
    description1: "Avatr 11 Royal Theatre Edition создан на базе серии Avatr 11 и сочетает в себе роскошную комплектацию и уникальные развлекательные возможности. Он предназначен для потребителей, стремящихся к высококлассному вождению и комфорту, предлагая им эксклюзивный автомобиль и повышая конкурентоспособность бренда на рынке премиальных интеллектуальных автомобилей.",
    description2: "Avatr 11 Royal Theatre Edition продолжает дизайнерские решения серии Avatr 11. Уникальный дизайн делает его легко узнаваемым, а плавные линии кузова и большие колесные диски подчеркивают премиальное качество. Эксклюзивные знаки отличия в некоторых деталях подчеркивают его особый статус",
    description3: "Avatr 11 в версии Royal Theatre Edition оснащен двухмоторной системой полного привода Huawei DriveONE, обеспечивающей мощную динамику. Автомобиль комплектуется тройной литиевой батареей CATL, запас хода которой удовлетворяет потребности как в повседневных, так и в длительных поездках. Салон оснащен первоклассными кожаными сиденьями Nappa с функциями подогрева, вентиляции и массажа."
  },
  {//阿维塔11 Max 增程版
    title: "阿维塔11 Max 增程版",
    codeNumber: "1b2887dcdfd94b20ab83352c3518826d",//车型版本code
    description1: "Сердцем Avatr 11 Max является передовая гибридная силовая установка, обеспечивающая впечатляющую мощность и динамику. Благодаря сочетанию электродвигателя и бензинового двигателя, этот автомобиль предлагает непревзойденную топливную экономичность и экологичность, не уступая при этом в производительности. Интеллектуальная система управления энергопотреблением позволяет оптимизировать работу силовой установки в зависимости от условий движения и предпочтений водителя.",
    description2: "Гибридная версия Avatr 11  Max и чисто электрическая версия Avatr 11 схожи по дизайну кузова. Это смелое сочетание инноваций и элегантности. Плавные линии кузова, рельефные элементы и оригинальная светодиодная оптика создают запоминающийся и узнаваемый образ. Автомобиль выглядит современно и динамично, при этом сохраняя изысканность и респектабельность.",
    description3: "Avatr 11 Max Extended Range Edition оснащен гибридной силовой установкой с увеличенным запасом хода: 1.5 литровый турбированный двигатель, функционирующий как генератор, работает в паре с системой полного привода Huawei DriveONE с двумя моторами, суммарная мощность которых составляет 360 кВт, а крутящий момент - 670 Н・м, что дает ему достаточно мощности. Автомобиль оснащен литиевой батареей Ningde Times емкостью 40,73 кВт-ч. Запас хода на электротяге CLTC составляет 230 км, а общий запас хода - 1160 км."
  },
  {//阿维塔11 Ultra 增程版
    title: "阿维塔11 Ultra 增程版",
    codeNumber: "ac1ff309d1cc41ee850dfa87984fcdd1",//车型版本code
    description1: "Avatr 11 Ultra EREV  по сравнению с Avatr 11 Max EREV предлагает еще больше улучшенные конфигурации и производительность, создавая флагманский автомобиль для потребителей, стремящихся к максимальному опыту вождения и длительной автономности, демонстрируя техническую мощь и инновационные возможности бренда в области электромобилей с увеличенной дальностью хода.",
    description2: "Дизайн Avatr 11 Ultra EREV отличается элегантностью и современностью. Плавные линии кузова, футуристичная оптика и премиальные материалы отделки создают неповторимый образ, который притягивает взгляды и подчеркивает статус владельца.",
    description3: "Avatr 11 Ultra Extended Range использует инновационную силовую установку, базирующуюся на 1.5-литровом турбированном двигателе внутреннего сгорания, служащем для увеличения запаса хода. В связке с ним работает интеллектуальная система полного привода DriveONE разработки Huawei, использующая два электромотора. Высокоэффективная литий-ионная батарея от Ningde Times обеспечивает впечатляющие показатели как в полностью электрическом режиме, так и в комбинированном цикле. Интерьер автомобиля отличается премиальной отделкой, сиденьями с широким спектром настроек, а также усовершенствованной аудиосистемой и системой ароматизации, что гарантирует беспрецедентный комфорт во время поездок."
  },
  //#endregion

  //#region 阿维塔12
  {//阿维塔12 Max 纯电版
    title: "阿维塔12 Max 纯电版",
    codeNumber: "8c9433503ca24b4b87b25c4558ca0fe9",//车型版本code
    description1: "Новейшая модель Avatr 12 поддерживает высокий уровень интеллектуальности, присущий бренду. Электрическая версия Avatr 12 Max, благодаря увеличенному запасу хода и расширенной комплектации, предлагает экологически чистое, эффективное и интеллектуальное решение для передвижения, демонстрируя непрерывное развитие бренда в сфере электромобилей.",
    description2: "Avita 12 Max Pure Electric Edition демонстрирует свежий дизайнерский подход, выделяясь общим динамичным и стильным обликом. Заниженный силуэт в сочетании с выразительными фарами формирует яркий спортивный характер. Плавные линии кузова, покатая задняя часть и увеличенные колесные диски подчеркивают современный вид автомобиля. Уникальный дизайн сплошных задних фар в задней части кузова отличается простотой и элегантностью, а после включения обеспечивает высокую узнаваемость.",
    description3: "Электрическая версия Avatr 12 Max оснащена одномоторной системой заднего привода, мощности двигателя достаточно для повседневных поездок. Автомобиль оснащен тройной литиевой батареей от CATL, обеспечивающей отличный запас хода по циклу CLTC. В салоне использованы высококачественные материалы отделки, сиденья поддерживают функции подогрева и вентиляции."
  },
  {//阿维塔12 Ultra 纯电版四驱
    title: "阿维塔12 Ultra 纯电版四驱",
    codeNumber: "5573e37e13bb426fa1954f5feed8063a",//车型版本code
    description1: "В продуктовой линейке бренда Avatr 12 Ultra в полноприводной электрической версии позиционируется как модель премиум-класса. Благодаря двухмоторной системе полного привода и богатому набору роскошных опций, этот автомобиль создан для потребителей, стремящихся к максимальной производительности и высокому качеству вождения, и представляет собой топовое транспортное средство, повышающее конкурентоспособность бренда на рынке электромобилей премиум-класса.",
    description2: "Внешний вид полноприводной электрической версии Avatr 12 Ultra соответствует серии Avatr 12. Уникальный язык дизайна придает ей футуристический вид, а плавные линии кузова и большие колесные диски подчеркивают высокое качество.",
    description3: "Полноприводная модель Avatr 12 Ultra Pure Electric Edition 4WD может похвастаться двухмоторной силовой установкой Huawei DriveONE, обеспечивающей впечатляющую совокупную мощность, динамичное ускорение и уверенную тягу. Питание осуществляется от емких литий-ионных аккумуляторов Ningde Times, гарантирующих превосходный запас хода по циклу CLTC. Внутреннее убранство автомобиля отличается использованием материалов премиум-класса, а сиденья предлагают широкий спектр функций, включая обогрев, вентиляцию и массаж. Богатая мультимедийная система включает в себя большой центральный дисплей, развлекательный экран для переднего пассажира и экран для задних пассажиров, в сочетании с высококлассной аудиосистемой, что создает неповторимые аудиовизуальные ощущения для всех находящихся в салоне."
  },
  {//阿维塔12 Master 纯电版四驱
    title: "阿维塔12 Master 纯电版四驱",
    codeNumber: "3aa51d7cdc5846fea9b107b6fff729f7",//车型版本code
    description1: "Avatr 12 Master 4WD, флагманская версия в линейке Avatr 12, объединяет передовые технологические решения и изысканный дизайн, характерные для бренда. Это эксклюзивный автомобиль, созданный для взыскательных клиентов, стремящихся получить максимум удовольствия от каждой поездки. Модель призвана укрепить репутацию бренда как лидера в сегменте премиальных, интеллектуальных и полностью электрических транспортных средств.",
    description2: "Внешний вид Avatr 12 Master Pure Electric 4WD соответствует модельному ряду Avatr 12, а отличительные элементы дизайна выделяют его из толпы. Плавные линии, большие колеса и эксклюзивные цвета кузова - все это элементы роскоши.",
    description3: "Электрическая версия Avatr 12 Master получила полноприводную систему Huawei DriveONE с двумя двигателями, обеспечивающими впечатляющую динамику. Питание осуществляется от высокопроизводительной троичной литий-ионной батареи Ningde Times, гарантирующей большой запас хода. Внутреннее убранство авто отличается премиальным исполнением: щедрое использование натурального дерева и металла создает атмосферу изысканности. Сиденья предлагают широкий спектр регулировок и индивидуальные программы массажа для максимального комфорта."
  },
  {//阿维塔12 皇家剧院版
    title: "阿维塔12 皇家剧院版",
    codeNumber: "207428bb3aa344dda17acff464262d82",//车型版本code
    description1: "Avatr 12 Theatre Royal Edition, построенный на основе стандартной модели Avatr 12, предлагает наивысший уровень роскоши и особые мультимедийные возможности. Этот автомобиль создан для тех, кто ищет максимум комфорта и развлечений на колесах. Он не только обеспечивает высочайший уровень вождения, но и подчеркивает статус владельца, одновременно усиливая конкурентные преимущества Avatr в элитном сегменте умных машин.",
    description2: "Avatr 12 Theatre Royal Edition не отклоняется от дизайнерской концепции, заложенной в основу линейки Avatar 12. Неповторимый облик автомобиля пронизан духом будущего. Обтекаемые формы кузова в сочетании с крупными колесами акцентируют внимание на премиальном уровне исполнения. Отличительные элементы, такие как эксклюзивные логотипы, безошибочно указывают на его эксклюзивный характер и принадлежность к особой серии. Автомобиль, как и вся линейка, выдержан в футуристическом стиле, выглядит современно и технологично.",
    description3: "Avatr 12 Theatre Royal Edition использует систему полного привода Huawei DriveONE с двумя двигателями, что гарантирует превосходную производительность. Электромобиль Avatr 12 Royal Theatre Edition, укомплектованный тройными литиевыми батареями от Ningde Times, демонстрирует достаточный запас хода для ежедневных поездок и более длительных путешествий. Внутреннее пространство отличается премиальными сиденьями, обтянутыми кожей Nappa, с функциями подогрева, вентиляции и массажа. Пассажиры могут наслаждаться развлечениями благодаря 23,6-дюймовому экрану, установленному спереди, и заднему развлекательному дисплею, а также высококачественной аудиосистеме с 14 динамиками, обеспечивающей полное погружение в звук и видео."
  },
  {//阿维塔12 Max 增程版
    title: "阿维塔12 Max 增程版",
    codeNumber: "6ccc66bfe7f7489bab966bf6eb2348df",//车型版本code
    description1: "Avatr 12 Max гибридная версия эффективно устраняет опасения пользователей относительно дальности поездок. Это достигается за счёт применения технологии увеличения запаса хода, интегрированной в философию бренда. При этом сохраняются преимущества, характерные для марки: продвинутые интеллектуальные системы и высокий уровень комфорта. Предложенное решение предоставляет клиентам более функциональные возможности для перемещения и расширяет модельный ряд Avatr.",
    description2: "Гибридный Avatr 12 Max сохраняет внешний вид полностью электрической модели Avatr 12, при этом обладая новым дизайнерским подходом, который придаёт ему стильный и современный облик. Уникальные фары и оформление задней части автомобиля способствуют его повышенной узнаваемости на дороге.",
    description3: "AVATR 12 Max Extended Range Edition оснащен гибридной силовой установкой с 1,5-литровым турбированным двигателем в качестве генератора и системой полного привода Huawei DriveONE с двумя моторами, обладающей широкими возможностями и мощной динамикой. Оснащенный литиевой батареей Ningde Times, емкостью 40,73 кВт/ч, имеет превосходный запас хода на чистом электричестве по CLTC и в комбинированном режиме."
  },
  {//阿维塔12 Ultra 增程版
    title: "阿维塔12 Ultra 增程版",
    codeNumber: "ed3f179418a1412c8208f51fd44328d2",//车型版本code
    description1: "Avatr 12 Ultra Extended Range создан на базе Avatr 12 Max Extended Range и еще более улучшен по конфигурации и характеристикам, создавая первоклассный автомобиль для потребителей, которые стремятся получить максимальный опыт вождения и большой запас хода, демонстрируя техническую мощь и инновации бренда в области гибридных электромобилей.",
    description2: "Avatr 12 Ultra Extended Range - это продолжение серии Avatr 12, с уникальным дизайном, который выделяет его из толпы, а плавные линии корпуса и большие колеса подчеркивают его премиальность.",
    description3: "Avatr 12 Ultra Extended Range оснащен гибридной силовой установкой с 1,5-литровым двигателем в качестве генератора, а также системой полного привода с двумя моторами DriveONE от Huawei. Оснащенный литий-ионной тройной батареей Ningde Times повышенной емкости, он обладает превосходными характеристиками дальности хода на чистом электричестве и в комбинированном режиме."
  },
  //#endregion

  //#endregion


  //#region 岚图

  //#region Free 318
  {//岚图free 318 后驱环游版
    title: "岚图free 318 后驱环游版",
    codeNumber: "f760ac4bbf664927a8873e26f1d4369b",//车型版本code
    description1: "Заднеприводный Voyah free 318 Touring Edition предназначен для любителей путешествий, а его большой запас хода и комфортабельные характеристики обеспечивают пользователям отдых и удовольствие от поездок, что отражает стремление бренда удовлетворить самые разнообразные потребности потребителей",
    description2: "Voyah free 318 Rear Drive Touring Edition имеет современный и элегантный дизайн кузова, который подчеркивает его премиальный статус и отличает его от других SUV на рынке. Фронтальная часть украшена массивной решеткой радиатора с логотипом Voyah, а также светодиодными фарами с ДХО. Его боковые линии выглядят динамично и изящно, а задняя часть имеет светодиодные фонари, соединенные полосой. Крыша отличается панорамным остеклением, которое создает ощущение простора, а также добавляет света в салоне.  ",
    description3: "Voyah Free 318 в комплектации Rear Drive Touring Edition использует задний привод с одним электродвигателем, выдающим до 160 кВт мощности и обеспечивающим плавную динамику. Автомобиль оснащен тройной литий-ионной батареей емкостью 93 кВт/ч, что позволяет проехать до 601 км по циклу CLTC. Внутри салона вы найдете премиальную отделку и комфортабельные сиденья с электрорегулировками, функциями обогрева и вентиляции, создающими атмосферу роскоши и удобства."
  },
  {//岚图free 318 四驱探索版
    title: "岚图free 318 四驱探索版",
    codeNumber: "964e13e678714d488c7fba7159050221",//车型版本code
    description1: "Эта модель отвечает запросам путешественников, обеспечивая уверенное передвижение по различным типам местности. Благодаря системе 4WD, автомобиль демонстрирует превосходные ходовые качества, что делает его идеальным выбором для тех, кто ценит свободу и независимость в своих путешествиях. Voyah Free 318 4WD Discovery Edition укрепляет позиции бренда в сегменте автомобилей для активного отдыха.",
    description2: "Внешний дизайн Voyah free 318 4WD Discovery Edition такой же, как и у заднеприводного Touring Edition, со стильными и динамичными линиями кузова, уникальным дизайном передней и задней части, что в совокупности создает неповторимый образ.",
    description3: "Voyah Free 318 4WD Discovery Edition использует полноприводную систему с двумя электромоторами, обеспечивающими общую мощность в 360 кВт и внушительную производительность. Автомобиль, оборудованный тройной литий-ионной батареей на 93 кВт/ч, предлагает запас хода в 520 км по стандарту CLTC. Интерьер повторяет комплектацию Touring Edition с задним приводом, гарантируя удобство во время поездок."
  },
  //#endregion

  //#region 全新岚图梦想家
  {//全新岚图梦想家 四驱卓越鲲鹏版PHEV
    title: "全新岚图梦想家 四驱卓越鲲鹏版PHEV",
    codeNumber: "de7a7838fb384b77bea5ef9fc1579ba8",//车型版本code
    description1: "Voyah Dreamer PHEV 4WD Excellence Kunpeng Edition – это воплощение технологического лидерства бренда в сфере MPV, работающих на новых источниках энергии. Автомобиль предлагает покупателям сочетание высокой производительности, значительного запаса хода и повышенного комфорта в дальних поездках.",
    description2: "Voyah Dreamer PHEV 4WD Excellence Kunpeng Edition, воплощая фирменный стиль «Kunpeng Spreading Wings», демонстрирует узнаваемые черты дизайна семейства. Обтекаемые контуры кузова, чьи габариты достигают 5315 мм в длину, 1998 мм в ширину и 1800 мм в высоту, в сочетании с колесной базой в 3200 мм, создают ощущение простора и визуального комфорта. Дизайн Kunpeng эффектно подчеркнут фарами с хрустальными элементами и уникальными, сверкающими задними фонарями, форма которых при освещении выглядит впечатляюще и создает ощущение величия.",
    description3: "Voyah Dreamer PHEV 4WD Excellence Kunpeng Edition использует гибридную систему Arashi Power PHEV, включающую 1.5-литровый турбированный двигатель и два электромотора (передний и задний). Суммарная мощность силовой установки достигает 420 кВт (эквивалентно 571 лошадиной силе), а общий крутящий момент – 840 Нм. Максимальная скорость автомобиля составляет 203 км/ч, при этом разгон от 0 до 100 км/ч занимает всего 5.9 секунды. Автомобиль комплектуется литиевыми батареями высокой емкости на 43 кВт⋅ч, обеспечивающими запас хода на электротяге в 236 км по циклу CLTC. Общий запас хода в гибридном режиме составляет 1231 км, что позволяет забыть о проблеме ограниченного пробега."
  },
  {//全新岚图梦想家 四驱尊贵鲲鹏版PHEV
    title: "全新岚图梦想家 四驱尊贵鲲鹏版PHEV",
    codeNumber: "cb6f5a801c93442a9165b506ec8e8017",//车型版本code
    description1: "Voyah Dreamer PHEV 4WD Premium Kunpeng Edition – представитель среднего и премиального сегмента в линейке Voyah. Созданная на базе 4WD Premium Kunpeng Edition PHEV, эта версия обогащена дополнительными опциями, чтобы соответствовать запросам покупателей, ищущих первоклассные поездки и передовые технологии. Это отражает стремление марки к постоянному совершенствованию взаимодействия с пользователем.",
    description2: "Внешний вид Voyah Dreamer PHEV 4WD Premium Kunpeng Edition выдержан в общей стилистике семейства, характеризующейся солидностью и технологической продвинутостью. Оригинальное оформление передней и задней частей выделяет его среди других минивэнов, а габариты кузова гарантируют вместительность внутреннего пространства.",
    description3: "Силовая установка Voyah Dreamer PHEV 4WD Premium Kunpeng Edition такая же, как и у PHEV 4WD Excellence Kunpeng Edition , что обеспечивает высокую мощность и отличный запас хода. Интерьер был значительно модернизирован и дополнен двухдверным «умным» холодильником объемом 13 л, что облегчает пассажирам хранение продуктов и напитков. Он оснащен 29-дюймовым AR-HUD, который усиливает ощущение технологичности и удобства отображения информации о вождении."
  },
  {//全新岚图梦想家 四驱旗舰鲲鹏版PHEV
    title: "全新岚图梦想家 四驱旗舰鲲鹏版PHEV",
    codeNumber: "86490fa021f74f53a3ff266563038623",//车型版本code
    description1: "Voyah Dreamer PHEV 4WD Flagship Kunpeng Edition Как топовая версия модели Voyah Dreamer PHEV, 4WD Flagship Kunpeng Edition сочетает в себе лучшие технологии и роскошные функции бренда, чтобы создать первоклассный автомобиль для потребителей, которые стремятся получить максимальный опыт путешествий, и установить планку бренда в области высококлассных MPV на новых источниках энергии.",
    description2: "Voyah Dreamer PHEV 4WD Flagship Kunpeng Edition сохраняет тот же дизайн кузова, что и другие версии, общий облик атмосферный и стильный, а уникальные элементы дизайна подчеркивают достоинства флагманской модели.",
    description3: "Voyah Dreamer PHEV 4WD Flagship Kunpeng Edition - это мощный автомобиль с той же гибридной системой plug-in, что и другие модели PHEV, обеспечивающей выдающуюся мощность и дальность хода. Интерьер роскошный, с сиденьями первого класса SPA Zero Gravity для максимального комфорта. Все семь сидений оснащены подогревом, чтобы пассажирам было тепло в холодную погоду."
  },
  {//全新岚图梦想家 四驱旗舰乾崑版PHEV
    title: "全新岚图梦想家 四驱旗舰乾崑版PHEV",
    codeNumber: "b955b9ec53214037899a56eefe7150d0",//车型版本code
    description1: "Основанный на флагманском Qiankun Edition PHEV, Voyah Dreamer PHEV 4WD Flagship Qiankun Edition отличается более высококлассным дизайном и эксклюзивными функциями, ориентированными на потребителей, стремящихся к максимальной роскоши и уникальным впечатлениям, и еще больше повышает конкурентоспособность бренда на рынке высококлассных MPV на новых источниках энергии.",
    description2: "Voyah Dreamer PHEV 4WD Flagship Qiankun Edition похож на другие модели, но с отличительными деталями, такими как эксклюзивные цвета кузова и дизайн колес, что обеспечивает уникальный и роскошный вид.",
    description3: "Voyah Dreamer PHEV 4WD Flagship Qiankun Edition оснащен тем же силовым агрегатом, что и другие модели PHEV. В интерьере использовано множество высококачественных материалов, а в обивке использованы цельное дерево и металл, что усиливает общее ощущение роскоши. Сиденья регулируются по высоте и имеют эксклюзивную программу массажа."
  },
  {//全新岚图梦想家 四驱卓越鲲鹏版EV 2
    title: "全新岚图梦想家 四驱卓越鲲鹏版EV",
    codeNumber: "7f27ca39acfa4c62b94a7543409b301c",//车型版本code
    description1: "",
    description2: "",
    description3: ""
  },
  {//全新岚图梦想家 四驱尊贵鲲鹏版EV 2
    title: "全新岚图梦想家 四驱尊贵鲲鹏版EV",
    codeNumber: "1020c8708cbf464e8830d6501591f75c",//车型版本code
    description1: "",
    description2: "",
    description3: ""
  },
  {//全新岚图梦想家 四驱旗舰鲲鹏版EV 2
    title: "全新岚图梦想家 四驱旗舰鲲鹏版EV",
    codeNumber: "e1dc9446c805459d802622246b6c9c84",//车型版本code
    description1: "",
    description2: "",
    description3: ""
  },
  {//全新岚图梦想家 四驱旗舰乾崑版EV 2
    title: "全新岚图梦想家 四驱旗舰乾崑版EV",
    codeNumber: "c8d7311d2858474788d657f381c4da15",//车型版本code
    description1: "",
    description2: "",
    description3: ""
  },
  //#endregion

  //#region 追光
  {//岚图追光 超长续航四驱行政版
    title: "岚图追光超长续航四驱行政版",
    codeNumber: "b57d1322669e45e6a913247f4b44dbfb",//车型版本code
    description1: "Voyah Auto активно развивается в секторе передовых электромобилей, опираясь на обширный технологический опыт и стремление к инновациям. Ставя во главу угла потребности клиентов, Voyah нацелена на создание интеллектуальных и надежных транспортных средств премиум-класса, стремясь задать новые ориентиры в автомобильной индустрии.",
    description2: "Voyah Passion в комплектации Ultra Long Range 4WD Executive Edition верен традиционному дизайну линейки, демонстрируя изысканный и сдержанный стиль, не лишенный современных акцентов. Габариты идентичны версии Long Range, а вместительный кузов гарантирует обилие пространства в салоне. Затемненные задние стекла повышают уровень конфиденциальности. Оформление передней части отличается лаконичностью и эффектностью, оригинальные фары и радиаторная решетка гармонично сочетаются, подчеркивая фирменный стиль производителя.",
    description3: "Voyah Passion в топовой комплектации Ultra Long Range 4WD Executive Edition оборудован системой полного привода с двумя электромоторами, что обеспечивает превосходную проходимость в любых дорожных ситуациях. Благодаря батарее увеличенной емкости, запас хода по циклу CLTC позволяет совершать поездки без лишнего беспокойства. Внутреннее пространство отделано первоклассными материалами, демонстрирующими безупречное мастерство исполнения и приятную текстуру. Второй ряд сидений предлагает пассажирам беспрецедентный уровень комфорта, сравнимый с автомобилями представительского класса. Просторное место для ног, регулируемая подставка для ног и электрическая регулировка наклона спинки создают идеальные условия для расслабления во время путешествия."
  },
  {//岚图追光 超长续航四驱旗舰版
    title: "岚图追光超长续航四驱旗舰版",
    codeNumber: "680f64e97ebb4d13a5d3833fc32a49eb",//车型版本code
    description1: "Voyah Passion Ultra Long Range 4WD Flagship Edition, являясь вершиной модельного ряда премиального бренда Dongfeng Motor Group в сфере новой энергии, всесторонне отражает мощный потенциал Voyah в технологических инновациях, строгом контроле качества и других ключевых областях. Этот автомобиль задает новые стандарты и определяет направление развития в сегменте интеллектуальных электромобилей премиум-класса.",
    description2: "Кузов Voyah Passion Ultra Long Range 4WD Flagship Edition преобразился, став более ориентированным на индивидуальность и демонстрирующим передовые технологии, но при этом сохранив элегантность. Неповторимые оттенки окраски кузова и фирменные эмблемы акцентируют уникальность флагманской комплектации.Оригинальная передняя часть выделяется эмблемой, символизирующей распростертые крылья мифической птицы Кунь Пэн, и ромбовидной радиаторной решеткой. Острые и выразительные фары в форме звезд создают впечатляющее световое шоу в темное время суток. Обтекаемые линии кузова придают автомобилю изысканный вид. ",
    description3: "Флагманская версия Voyah Passion Ultra Long Range 4WD обладает впечатляющей двухмоторной полноприводной системой, гарантирующей внушительную производительность и стремительный разгон до 100 км/ч. Значительный запас хода по циклу CLTC позволяет с комфортом совершать длительные путешествия. В оформлении салона применяются эксклюзивные материалы премиум-класса, в том числе украшенные гравировкой хрустальные кнопки  и оригинальная хрустальная ручка селектора передач, подчеркивающие высокий статус автомобиля."
  },
  {//岚图追光 长续航版
    title: "岚图追光 长续航版",
    codeNumber: "bad7b800ee654dbdacd3f81bd4173727",//车型版本code
    description1: "Voyah Passion Long Range Edition. Компания Voyah ставит перед собой цель – 'Реализовывать автомобильные мечты, открывая новые горизонты для более качественной жизни'. Бренд позиционирует себя как премиальный, интеллектуальный и электрический, стремясь обеспечить пользователям максимальный комфорт и избавить от любых забот. Название марки отражает амбициозные цели и светлое будущее. Вдохновением для логотипа послужили распростертые крылья мифической птицы Кунпенг из легенды, символизирующие мощь и инновационный дух.",
    description2: "Voyah Passion воплощает фирменный стиль Kunpeng Spreading Wings, безошибочно узнаваемый в каждой детали. Оригинальная передняя часть выделяется эмблемой, символизирующей распростертые крылья мифической птицы Кунь Пэн, и ромбовидной радиаторной решеткой. Острые и выразительные фары в форме звезд создают впечатляющее световое шоу в темное время суток. Обтекаемые линии кузова придают автомобилю изысканный вид. Габариты Voyah Passion составляют 5088 мм в длину, 1970 мм в ширину и 1505 мм в высоту, а колесная база достигает 3000 мм, обеспечивая внушительный и солидный облик.",
    description3: "Voyah Passion в версии Long Range Edition получил аккумулятор увеличенной ёмкости, обеспечивающий превосходный запас хода по циклу CLTC. Этого достаточно как для ежедневных поездок по городу, так и для небольших путешествий. В отделке салона использованы премиальные материалы, гарантирующие комфорт во время вождения. Интегрированная система мультимедиа '4+1', включающая в себя 12,3-дюймовую приборную панель и 15,7-дюймовый экран центрального управления с разрешением 3K, отличается интуитивно понятным интерфейсом и подчеркивает технологичность автомобиля."
  },
  {//岚图追光 标准续航版  2
    title: "岚图追光 标准续航版",
    codeNumber: "dd7bbddcf7224e9c9332cc535c240373",//车型版本code
    description1: "",
    description2: "",
    description3: ""
  },
  //#endregion

  //#region 岚图梦想家
  {//岚图梦想家 超长续航卓越版PHEV
    title: "岚图梦想家 超长续航卓越版PHEV",
    codeNumber: "cdc5e74186614a8c8a8d1e3bab43fb8b",//车型版本code
    description1: "В качестве гибридной модели начального уровня серии VOYAH MPV, Dreamer Ultra Long Range Premier Edition PHEV, с передовой гибридной технологией и практичными конфигурациями, предоставляет потребителям выбор MPV для путешествий с высокой производительностью и дальностью, а также обогащает продуктовую матрицу бренда на рынке MPV.",
    description2: "Гибридный автомобиль Dreamer Ultra Long Range Excellent Edition PHEV обладает силовой установкой типа plug-in, включающей 1,5-литровый двигатель Миллера, работающий в качестве генератора, и два электромотора (передний и задний) с общей мощностью 320 кВт и крутящим моментом 620 Нм, что гарантирует уверенную динамику. Благодаря литий-ионной батарее емкостью 39 кВт⋅ч, запас хода на электротяге составляет 215 км, а суммарный запас хода в гибридном режиме достигает 1201 км, избавляя от беспокойства о необходимости частой подзарядки.",
    description3: "Dreamer Ultra Long Range Excellent Edition PHEV обладает кузовом, чей дизайн напоминает элегантную яхту, излучая ощущение уверенности и надежности. Его отличительная радиаторная решетка и резкие фары формируют яркий и запоминающийся внешний вид. Плавные боковые линии кузова гармонично сочетаются с боковыми сдвижными дверями с электроприводом, расположенными с обеих сторон, что значительно облегчает посадку и высадку пассажиров. Задние фонари отличаются лаконичным и эффектным дизайном, обеспечивая отличную видимость в темное время суток."
  },
  {//岚图梦想家 超长续航尊贵版 PHEV
    title: "岚图梦想家 超长续航尊贵版 PHEV",
    codeNumber: "355ef28c17a04747984659d1a1ad29c6",//车型版本code
    description1: "Dreamer Ultra Long Range Premium Edition PHEV, базируясь на комплектации Excellent Edition PHEV, предлагает расширенную конфигурацию, разработанную для удовлетворения потребностей в высококлассных поездках на минивэнах. Эта модель демонстрирует новаторский потенциал бренда в сегменте MPV.",
    description2: "Dreamer Ultra Long Range Premium Edition PHEV использует аналогичную гибридную систему, что и Excellent Edition PHEV, предлагая сопоставимую производительность и дальность хода. Внутреннее пространство значительно улучшено, с использованием кожи Nappa для обивки сидений, что повышает уровень комфорта. Задние сиденья оборудованы электроприводными подножками и функцией массажа, создавая еще более удобные условия для пассажиров.",
    description3: "Внешний дизайн Dreamer Ultra Long Range Premium Edition PHEV такой же, как и у Excellent Edition PHEV, с элегантными линиями кузова, уникальным дизайном передней и задней части, демонстрирующим премиальное качество."
  },
  {//岚图梦想家 超长续航旗舰版PHEV
    title: "岚图梦想家 超长续航旗舰版PHEV",
    codeNumber: "568d1f7928644805aa6306809017bae8",//车型版本code
    description1: "Флагманский Dreamer Ultra Long Range PHEV, будучи топовой комплектацией PHEV-версии, объединяет передовые технологии и премиальное оснащение бренда. Это создает MPV высшего класса, ориентированный на тех, кто ценит бескомпромиссный комфорт в путешествиях. Модель призвана укрепить позиции бренда как лидера в сегменте высококлассных плагин-гибридных минивэнов.",
    description2: "Флагманский гибрид обладает аналогичной силовой установкой, что и другие PHEV модели, демонстрируя высокую производительность и значительный запас хода. Интерьер выполнен на премиальном уровне: сиденья отделаны кожей Nappa превосходного качества, а расширенный набор функций включает в себя подогрев, вентиляцию и массаж для максимального комфорта. Пассажиры смогут насладиться мультимедийными возможностями благодаря 12,3-дюймовому дисплею и 12,8-дюймовому экрану, расположенному на потолке, что существенно повышает уровень развлечений в поездке.",
    description3: "Внешний дизайн флагманского PHEV Dreamer Ultra Long Range продолжает стиль бренда - атмосферный и полный ощущения технологичности. Уникальный дизайн передней и задней части выделяет его на фоне других MPV."
  },
  {//岚图梦想家 超长续航卓越版 EV
    title: "岚图梦想家 超长续航卓越版 EV",
    codeNumber: "2500dbfd30564dbda0a8df404d2b837d",//车型版本code
    description1: "Электромобиль Dreamer Ultra Long Range Excellent Edition разработан для обеспечения экологически чистой мобильности. Используя наработки бренда в сфере электрических технологий, он предлагает потребителям экологически чистое и эффективное решение в сегменте минивэнов с большим запасом хода и комфортным управлением. Это расширяет ассортимент продукции бренда на рынке минивэнов.",
    description2: "Электромобиль Dreamer Ultra Long Range Excellent Edition использует двухмоторную систему полного привода, обеспечивающую высокую производительность, точные характеристики которой пока не обнародованы. Благодаря аккумулятору увеличенной емкости, запас хода по циклу CLTC позволяет комфортно совершать ежедневные поездки и путешествия на небольшие расстояния. Внутреннее пространство отличается отделкой премиум-класса и сиденьями с электрорегулировками, функциями обогрева и вентиляции.",
    description3: "Электромобиль Dreamer Ultra Long Range Excellent Edition EV в плане экстерьера перекликается с гибридными моделями PHEV: его силуэт, напоминающий яхту, создает ощущение основательности и уверенности. Яркие фары головного света и задние фонари делают автомобиль легко узнаваемым на дороге."
  },
  {//岚图梦想家 超长续航尊贵版 EV
    title: "岚图梦想家 超长续航尊贵版 EV",
    codeNumber: "3eacc51b2ca5439d9a6edf704fdce4a6",//车型版本code
    description1: "Dreamer Ultra Long Range Premium EV позиционируясь как представитель среднего и высокого класса, создан для удовлетворения запросов тех, кто ищет электромобиль MPV с возможностью преодолевать большие расстояния. Кроме того, благодаря своим расширенным возможностям, гарантирует пользователям первоклассный комфорт и удовольствие от управления, демонстрируя тем самым приверженность бренда к постоянному развитию технологий в области электрических MPV.",
    description2: "Модель Dreamer Ultra Long Range Premium EV в плане производительности оснащена двухдвигательной системой полного привода, аналогичной топовой комплектации, что гарантирует надежную и внушительную тягу. Аккумуляторная батарея обеспечивает превосходный запас хода по циклу CLTC, полностью покрывая потребности как в ежедневных поездках, так и в непродолжительных путешествиях. Интерьер отделан кожей Nappa, что повышает уровень комфорта, а задние сиденья оборудованы электрорегулируемыми подставками для ног, дополнительно улучшающими удобство пассажиров.",
    description3: "Внешний вид Dreamer Ultra Long Range Premium EV выдержан в общей стилистике семейства Dreamer: он элегантен, устойчив и излучает современность. Неповторимое оформление передней и задней частей выделяет его среди других минивэнов, а габариты кузова гарантируют вместительное внутреннее пространство."
  },
  {//岚图梦想家 超长续航旗舰版 EV
    title: "岚图梦想家 超长续航旗舰版 EV",
    codeNumber: "4bf9c016cf574d82a6340531740de972",//车型版本code
    description1: "Флагманская модель Dreamer Ultra Long Range EV, представляющая собой вершину линейки полностью электрических минивэнов бренда, задает новые стандарты в сегменте премиальных электромобилей. Она объединяет в себе передовые технологические решения и богатый набор роскошных опций.",
    description2: "Флагманская модель Dreamer Ultra Long Range EV оснащена системой полного привода с двумя моторами, обеспечивающую внушительную мощность, которая с легкостью покрывает потребности как в повседневных поездках, так и в динамичном вождении. Благодаря аккумулятору повышенной емкости, автомобиль демонстрирует превосходный запас хода по циклу CLTC, эффективно решая проблему ограниченного пробега, беспокоящую многих водителей. В салоне автомобиля установлены сиденья, отделанные кожей Nappa, с широким набором функций, включая обогрев, вентиляцию и массаж. Второй ряд оборудован двумя сиденьями премиум-класса с эффектом невесомости, как в SPA-салоне, гарантируя пассажирам несравненный комфорт во время поездки.",
    description3: "Флагманская модель Dreamer Ultra Long Range EV воплощает семейный стиль дизайна, именуемый 'Расправленные крылья кунпэна', благодаря чему его облик излучает изящество и солидность. Габариты кузова, составляющие 5315 мм в длину, 1998 мм в ширину и 1800 мм в высоту, при колесной базе в 3200 мм, обеспечивают не только внушительный внешний вид, но и просторный салон. Фирменные фары в стиле 'Расправленные крылья кунпэна' в сочетании с задними фонарями, выполненными в виде сверкающих бриллиантов, формируют неповторимый образ, делая автомобиль легко узнаваемым в темное время суток."
  },
  {//岚图梦想家 私人订制版 PHEV 2
    title: "岚图梦想家 私人订制版 PHEV",
    codeNumber: "8c9f596ef00f4c339cd9862f2aa86a24",//车型版本code
    description1: "",
    description2: "",
    description3: ""
  },
  //#endregion

  //#endregion


  //#region 小米

  //#region 小米SU7
  {//小米SU7
    title: "小米SU7",
    codeNumber: "898f42ed3bfe47369652cbd378d1e68d",//车型版本code
    description1: "Xiaomi SU7. Этот революционный автомобиль от Xiaomi устанавливает новые стандарты для седанов бизнес-класса, сочетая инновационные технологии, мощность и передовые решения в области безопасности и комфорта.",
    description2: "Xiaomi SU7 выполнен в минималистичном дизайне с плавными и строгими линиями. При длине, ширине и высоте 4997/1963/1440 мм (1455 мм) и колесной базе 3000 мм SU7 выглядит элегантно и атмосферно. Плавные линии боковых поверхностей кузова придают автомобилю динамичный и современный вид, подчёркивая спортивный характер.",
    description3: "Интерьер Xiaomi SU7 отличается минимализмом и высокой технологичностью. Автомобиль оснащен 12,3-дюймовой цифровой приборной панелью, 15,6-дюймовым центральным экраном управления. Xiaomi SU7 не просто электромобиль, а настоящий смарт-кар, который может взаимодействовать с другими устройствами, людьми и домами. Для этого используется операционная система Hyper OS от Xiaomi, которая обеспечивает высокую скорость, безопасность и интеллектуальность. Разумеется, предусмотрена полная интеграция с экосистемой Xiaomi. Комфортные сиденья: Эргономичные сиденья с множеством настроек и подогревом обеспечивают комфорт даже в длительных поездках. Сиденья Xiaomi SU7 выполнены из натуральной кожи Nappa, что подчеркивает их премиальное качество."
  },
  {//小米SU7 Pro
    title: "小米SU7 Pro",
    codeNumber: "0adf26a70f764e65805528f7140599e8",//车型版本code
    description1: "Xiaomi SU7 Pro позиционируется как средняя версия серии Xiaomi SU7 и является передовой разработкой бренда в области автономного и интеллектуального вождения. Благодаря улучшенной конфигурации он отвечает потребностям пользователей, которые предпочитают дальние поездки и предъявляют высокие требования к интеллектуальному вождению, демонстрируя постоянное стремление Xiaomi Auto к технологическим инновациям и удовлетворению потребностей пользователей.",
    description2: "Xiaomi SU7 Pro  продолжает традиции обозначенного дизайна бренда, сохраняя фирменный стиль первой модели – седана SU7. Передняя часть автомобиля выделяется стильной оптикой с «пулеобразными» фарами и модульными линзами, которые подчеркивают прогрессивный характер модели. Боковой профиль кроссовера выполнен в стиле купе, с динамичной линией крыши. Это придает автомобилю современный и стремительный вид. Задняя часть украшена фирменными фонарями Xiaomi в форме вогнутой дуги, которые обеспечивают высокую узнаваемость. Спойлер на крыше и стильный задний бампер усиливают спортивную атмосферу. Для удовлетворения индивидуальных потребностей пользователей предлагается множество вариантов оформления лобового стекла, шильдика, зеркал, крыши, дисков и системы LiDAR.",
    description3: "Заднеприводная версия Xiaomi SU7 Pro базируется на 400-вольтовой платформе, оснащена двигателем с максимальной мощностью 220 кВт (299 л.с.) и максимальным крутящим моментом 400 Н・м. Дальность хода по CLTC на одном заряде достигает  830 км благодаря литий-железо-фосфатной батарее от Ningde Times емкостью 94,3 кВт-ч. За 15 минут быстрой зарядки можно пополнить запас хода на 350 км. Также автомобиль оснащен трехспицевым многофункциональным рулевым колесом с тумблерами-переключателями. Полностью ЖК-приборная панель, рычаг переключения передач и напольная педаль акселератора - все это доступно."
  },
  {//小米SU7 Max
    title: "小米SU7 Max",
    codeNumber: "154bbb2ac800404284c4dcd68698abec",//车型版本code
    description1: "Флагманская версия Xiaomi SU7 Max представляет собой квинтэссенцию инноваций и премиального оснащения, демонстрируя технологическое превосходство и престиж Xiaomi Auto. В своем стремлении предоставить пользователям бескомпромиссную производительность, внушительный запас хода и передовые интеллектуальные функции, модель призвана занять лидирующие позиции в сегменте электромобилей премиум-класса. Xiaomi SU7 Max олицетворяет собой амбиции бренда в создании высокотехнологичных и экологичных транспортных средств, предлагая эксклюзивный опыт вождения.",
    description2: "Внешний вид Xiaomi SU7 Max – это результат работы Тяньюаня Ли, ранее занимавшего должность ведущего дизайнера BMW iX. Благодаря его усилиям, автомобиль обладает неповторимым обликом. Плавные линии кузова, большие колеса и оригинальные расцветки подчеркивают премиальный статус SU7 Max. Всё это создает образ роскоши и элегантности.",
    description3: "Топовая версия Xiaomi SU7 обладает двухмоторной силовой установкой, где спереди установлен асинхронный двигатель, а сзади – синхронный на постоянных магнитах. Суммарная отдача системы достигает впечатляющих 495 кВт (что эквивалентно 673 л.с.) и 838 Нм крутящего момента. Это позволяет электромобилю разгоняться до 100 км/ч всего за 2.78 секунды, а максимальная скорость ограничена на отметке 265 км/ч, демонстрируя выдающиеся динамические характеристики. Питается автомобиль от троичной литий-ионной батареи производства Ningde Times, емкость которой составляет 101 кВт/ч, обеспечивая запас хода в 800 км по циклу CLTC. Кроме того, поддерживается быстрая зарядка с напряжением 800 В. Благодаря собственной 800-вольтовой платформе Xiaomi на карбиде кремния с максимальным напряжением 871 В, можно пополнить запас хода на 220 км всего за 5 минут зарядки, на 510 км за 15 минут, а также зарядить батарею с 10% до 80% всего за 20 минут."
  },
  //#endregion

  //#endregion


  //#region 路特斯

  //#region ELETRE
  {//路特斯 ELETRE 600旅行版
    title: "路特斯 ELETRE 600旅行版",
    codeNumber: "8df6adcfc59d49c9ae170576570ce127",//车型版本code
    description1: "Эта модель представляет собой воплощение стремления бренда к созданию высокоэффективных электромобилей, предлагая передовые решения в области электрической тяги. Сохраняя при этом привычное управление, характерное для классических автомобилей, LOTUS ELETRE 600 Travel Edition обеспечивает уникальный баланс между инновациями и комфортом для водителя.",
    description2: "LOTUS ELETRE 600 Travel Edition сохраняет фирменный стиль LOTUS с его резкими линиями и угловатым дизайном. Габариты автомобиля составляют 5103 мм в длину, 2019 мм в ширину и 1636 мм в высоту, а колесная база – 3019 мм, что придает ему одновременно стремительный и уверенный вид. Семь аэродинамических каналов Race Aero, расположенных от передней решетки до задних дверей и и С-образной стойки, оптимизируют воздушный поток, снижая коэффициент аэродинамического сопротивления до 0,26 Cd. Кроме того, они генерируют 90 кг прижимной силы, что положительно сказывается на устойчивости автомобиля при движении на высоких скоростях.",
    description3: "Модель LOTUS ELETRE 600 Travel Edition  оснащен двухмоторной системой полного привода, выдающую пиковую мощность в 450 кВт (эквивалентно 612 лошадиным силам) и крутящий момент до 710 Нм. Это обеспечивает необходимую производительность для уверенного передвижения в любых дорожных обстоятельствах. Автомобиль оснащен батареей увеличенной емкости в 112 кВт/ч, что позволяет проехать до 650 км по циклу CLTC. Благодаря архитектуре высоковольтной платформы 800 В, зарядка от 10% до 80% занимает всего 30 минут."
  },
  {//路特斯 ELETRE 600豪华版
    title: "路特斯 ELETRE 600豪华版",
    codeNumber: "3705c3330c4e411597d3e76935dcf569",//车型版本code
    description1: "LOTUS ELETRE 600 Luxury Edition, занимая стратегическую позицию между моделями среднего и премиум-класса, обеспечивает характерные для LOTUS впечатления от вождения. Автомобиль предлагает обновленный интерьер и улучшенную комплектацию, чтобы удовлетворить запросы потребителей, ищущих высококлассный и мощный электрокроссовер.",
    description2: "Внешний вид LOTUS ELETRE 600 Deluxe Edition визуально не отличается от версии Travel Edition, сохраняя характерные острые грани, аэродинамические элементы и выразительные кузовные линии, формирующие энергичный и спортивный облик. Крупные колесные диски и яркие оттенки кузова акцентируют премиальный статус автомобиля.",
    description3: "Версия Luxury Edition LOTUS ELETRE 600 использует аналогичную силовую установку, что и модель Travel Edition 600, гарантируя сопоставимую производительность. Интерьер был улучшен с точки зрения материалов и мастерства исполнения, с широким использованием высококачественных материалов. Особое внимание уделено деталям, таким как подрулевые переключатели, регуляторы сидений, селектор передач и элементы управления термостатом. Эти элементы впервые в автомобильной индустрии выполнены из жидкого металла, что существенно улучшает тактильные ощущения и визуальное восприятие интерьера."
  },
  {//路特斯 ELETRE 600行政版
    title: "路特斯 ELETRE 600行政版",
    codeNumber: "a63f8091cc2f40dd8aa4437250892545",//车型版本code
    description1: "LOTUS ELETRE 600 Executive Edition, являясь флагманом модельного ряда электрических внедорожников LOTUS, воплощает в себе совокупность передовых технологий и эксклюзивных опций, созданных для клиентов, стремящихся к бескомпромиссному опыту вождения и придающих первостепенное значение комфорту в деловых поездках. Автомобиль подчеркивает лидерство бренда в сегменте премиальных электрических SUV.",
    description2: "Внешний вид LOTUS ELETRE 600 Executive Edition сохраняет семейные черты дизайна, при этом его неповторимый облик выделяется среди прочих внедорожников. Обтекаемые контуры кузова и утонченные элементы оформления, например, особенный логотип, акцентируют премиальность модели Executive Edition.",
    description3: "LOTUS ELETRE 600 Executive Edition представляет собой высокопроизводительный автомобиль, оборудованный системой полного привода с двумя электромоторами. Внутреннее пространство машины отделано премиальными материалами, которые формируют атмосферу роскоши и элегантности благодаря безупречному качеству исполнения. Пассажиры заднего ряда могут наслаждаться независимой мультимедийной системой в сочетании с аудиосистемой высокого класса, что обеспечивает неповторимые аудиовизуальные ощущения."
  },
  //#endregion

  //#region EMEYA
  {//路特斯EMEYA 600旅行版
    title: "路特斯EMEYA 600旅行版",
    codeNumber: "94c4baf333a84743917eaba9042a66fb",//车型版本code
    description1: "Будучи первым представителем серии чисто электрических суперкаров, LOTUS EMEYA 600 Travel Edition унаследовал трековые гены LOTUS и сочетает в себе производительность суперкара с практичностью универсала, предоставляя новый выбор для пользователей, ищущих скорость и страсть с повседневной практичностью, и обогащая линейку продуктов бренда на рынке новых энергетических транспортных средств.",
    description2: "LOTUS EMEYA 600 Travel Edition выделяется своим стильным и спортивным дизайном. Выполнен в уникальном стиле семейного дизайна, который мгновенно узнаваем по стремительным линиям кузова, акцентированным аэродинамическим элементам и смелому силуэту. Фирменная светодиодная оптика, расположенная как спереди, так и сзади, не только обеспечивает превосходную видимость, но и подчеркивает футуристичный облик автомобиля. Задняя часть Emeya притягивает взгляд световой полосой во всю ширину кузова, объединяющей тонкие светодиодные фонари. Активный спойлер, расположенный над задним стеклом, автоматически регулируется для оптимизации аэродинамики в зависимости от скорости движения. Нижняя часть бампера оформлена диффузором, добавляющим агрессивности облику электромобиля.",
    description3: "Модель LOTUS EMEYA 600 Travel Edition использует сдвоенные электромоторы, расположенные спереди и сзади. Передний двигатель развивает пиковую мощность 225 кВт, а задний – внушительные 450 кВт. Суммарный крутящий момент достигает 985 Нм, а двухступенчатая трансмиссия позволяет автомобилю разгоняться до максимальной скорости 256 км/ч, обеспечивая впечатляющую динамику. Благодаря аккумуляторной батарее емкостью 102 кВт-ч, запас хода EMEYA 600 Travel Edition по циклу CLTC составляет 600 км. Система быстрой зарядки 800 В позволяет всего за 5 минут (в условиях CLTC) увеличить пробег на 180 км, а зарядка от 10 до 80% занимает всего 15 минут."
  },
  {//路特斯EMEYA 600豪华版
    title: "路特斯EMEYA 600豪华版",
    codeNumber: "380ba43d36f94e25b7a37ce2c597e2d3",//车型版本code
    description1: "LOTUS EMEYA 600 Deluxe Edition представляет собой премиальную модель в линейке EMEYA. Улучшенные характеристики и изысканная отделка салона ориентированы на ценителей роскоши и высокой производительности. Автомобиль демонстрирует превосходное качество исполнения и динамические возможности, присущие электромобилям класса 'суперкар' от этого бренда.Эта версия призвана удовлетворить запросы самых требовательных клиентов, предлагая бескомпромиссное сочетание элегантности и мощности. Благодаря тщательно проработанным деталям и передовым технологиям, EMEYA 600 Deluxe Edition подчеркивает стремление бренда к совершенству в сегменте электрических спорткаров. Автомобиль является воплощением роскоши и передовых инженерных решений.",
    description2: "Версия LOTUS EMEYA 600 Deluxe Edition повторяет экстерьер EMEYA 600 Travel Edition, обладая обтекаемым силуэтом, фирменным оформлением оптики и крупными дисками, что выделяет её среди других моделей.",
    description3: "Версия LOTUS EMEYA 600 Deluxe оснащена идентичным двигателем, что и модель Travel Edition, гарантируя тем самым впечатляющую производительность. Интерьер отличается использованием премиальных материалов, а кресла обтянуты кожей высшего качества, предлагая расширенный диапазон настроек и усовершенствованную функцию массажа для максимального удобства в пути. В салоне установлена более современная аудиосистема и система ароматизации воздуха, создающая атмосферу роскоши и комфорта во время вождения. Интеллектуальная система помощи водителю была дополнительно усовершенствована, чтобы обеспечить еще более удобное и безопасное управление автомобилем."
  },
  {//路特斯EMEYA 600行政版
    title: "路特斯EMEYA 600行政版",
    codeNumber: "e823f52508f6475f8783bdc1bc9ba7d6",//车型版本code
    description1: "Топовая версия EMEYA, LOTUS EMEYA 600 Executive Edition, воплощает в себе сочетание инновационных технологий и премиальных опций, характерных для бренда. Этот эксклюзивный автомобиль создан для требовательных клиентов, стремящихся к наивысшему уровню комфорта и удовольствия от каждой поездки. EMEYA 600 Executive Edition поднимает стандарты бренда в сегменте электрических суперкаров класса люкс, предлагая непревзойденный опыт вождения и подчеркивая статус владельца.",
    description2: "Внешний дизайн LOTUS EMEYA 600 Executive Edition - продолжение семейного стиля, с уникальными элементами дизайна, выделяющими его из толпы, плавными линиями кузова, эксклюзивными цветами и логотипами, демонстрирующими его престижный статус.",
    description3: "LOTUS EMEYA 600 Executive Edition демонстрирует впечатляющие характеристики разгона, а система полного привода с двумя электромоторами гарантирует внушительную мощность. Внутреннее пространство автомобиля отделано с применением премиальных материалов, включая комбинацию натурального дерева и металла, что создает в салоне атмосферу изысканности. Кресла оснащены широким спектром настроек и уникальными программами массажа, обеспечивающими исключительный комфорт для водителя и пассажиров."
  },
  {//路特斯EMEYA 900性能版
    title: "路特斯EMEYA 900性能版",
    codeNumber: "f8fb36e6251a4a49a9d163769fe0e7fd",//车型版本code
    description1: "LOTUS EMEYA 900 Performance Edition, являясь новейшим творением бренда в области высокопроизводительных чисто электрических седанов, благодаря своей супермощности и высочайшему уровню управляемости создает эксклюзивный автомобиль для пользователей, которые стремятся получить максимальное удовольствие от вождения, и демонстрирует настойчивое стремление бренда к высоким характеристикам в эпоху электрификации.",
    description2: "LOTUS EMEYA 900 Performance Edition демонстрирует знакомый облик спортивных седанов LOTUS, отличаясь выразительными контурами кузова. Специально разработанный аэродинамический обвес и массивные колесные диски усиливают динамичный характер автомобиля. Особый логотип Performance Edition выделяет эксклюзивность данной версии.",
    description3: "Lotus Emeya 900 Performance Edition отличается от 600-й серии модернизированной силовой установкой, включающей сдвоенные электромоторы спереди и сзади, что существенно повышает выходную мощность. Это позволяет улучшить динамику разгона до 100 км/ч и достичь максимальной скорости свыше 256 км/ч, обеспечивая захватывающие впечатления от вождения. Автомобиль оборудован передовой тормозной системой, гарантирующей эффективное замедление даже на предельных скоростях."
  },
  {//路特斯EMEYA 900行政版
    title: "路特斯EMEYA 900行政版",
    codeNumber: "de47eb29002e44f8ac9cb49c0ff0d002",//车型版本code
    description1: "LOTUS EMEYA 900 Executive Edition – это флагман модельного ряда бренда, превосходно сочетающий в себе невероятную мощь и премиальную комплектацию. Он задаёт новые стандарты для бренда в сегменте премиальных электромобилей, предлагая лучшую динамику и высокий комфорт. ",
    description2: "Внешний дизайн LOTUS EMEYA 900 Executive Edition такой же, как и у EMEYA 900 Performance Edition, с уникальным языком дизайна, который делает его легко узнаваемым среди многих других моделей, плавные линии кузова, эксклюзивные цвета кузова и логотипы, подчеркивающие его престижный статус.",
    description3: "Мощность LOTUS EMEYA 900 Executive идентична версии Performance, обеспечивая выдающиеся характеристики. Салон отличается роскошью, с акцентом на материалы премиум-класса. Сиденья обтянуты первоклассной кожей Nappa и предлагают расширенный набор функций для удобства, включая обогрев, вентиляцию и массаж. Пассажирам предлагается 23,6-дюймовый дисплей развлекательной системы и задний экран для развлечений. Аудиосистема профессионального уровня с 14 динамиками создает эффект присутствия, обеспечивая незабываемые аудиовизуальные впечатления."
  },
  //#endregion

  //#endregion


  //#region 问界

  //#region 新M5 Ultra  2
  {
    title: "问界 新M5 增程 Ultra 后驱版",
    codeNumber: "ca8fa989533c400a9ae7c64fa590257c",
    description1: "",
    description2: "",
    description3: ""
  },
  {
    title: "问界 新M5 增程 Ultra 四驱版",
    codeNumber: "5dfb6a0e9c5a481eac5c66f12546e6f4",
    description1: "",
    description2: "",
    description3: ""
  },
  {
    title: "问界 新M5 纯电 Ultra 后驱版",
    codeNumber: "e126a043edd045ca834b8d6bf4e04a09",
    description1: "",
    description2: "",
    description3: ""
  },
  //#endregion

  //#region M7  2
  { 
    title: "问界 M7 旗舰版",
    codeNumber: "633870f63c5246bcb397f2a7ece8d1df",
    description1: "",
    description2: "",
    description3: ""
  }, 
  { 
    title: "问界 M7 舒适版",
    codeNumber: "33b303b2f40f4b9a9e5d4feb7556391a",
    description1: "",
    description2: "",
    description3: ""
  },
  { 
    title: "问界 M7 豪华版",
    codeNumber: "c6c60319969444caa4afa67dc1700358",
    description1: "",
    description2: "",
    description3: ""
  },
  //#endregion

  //#region M5（2023款）  2
  {
    title:"问界M5 后驱标准版（2023款）",
    codeNumber:"e225839558494adb8b85266726078a1e",
    description1:"",
    description2:"",
    description3:""
  },
  {
    title:"问界 M5 四驱标准版（2023款）",
    codeNumber:"129cb76c1cd14a4e8d1a70db3264e6a4",
    description1:"",
    description2:"",
    description3:""
  },
  {
    title:"问界 M5 纯电后驱标准版（2023款）",
    codeNumber:"fe93837f326342f982c0a33cfe80d772",
    description1:"",
    description2:"",
    description3:""
  },
  //#endregion

  //#region 新M7
  {
    title:"问界 新M7 Pro 五座后驱智驾版",
    codeNumber:"506785c481c34b239733ed43c8bcb794",
    description1:"M7 Pro Smart Driving Edition с задним приводом и пятью посадочными местами – это SUV среднего размера, идеально подходящий как для семейных выездов, так и для передвижения по городу. Он объединяет в себе современные интеллектуальные системы вождения и удобство управления, гарантируя пассажирам безопасность, комфорт и удовольствие от каждой поездки. Эта модель призвана подчеркнуть конкурентоспособность бренда в данном сегменте автомобильного рынка.",
    description2:"Версия M7 Pro Intelligent Driving Edition с задним приводом и пятью посадочными местами отличается лаконичным и прогрессивным обликом. Шестиугольная решетка радиатора в сочетании с пронзительными светодиодными ДХО формируют выразительный фронтальный вид. Двойная линия талии по бокам и прямая линия крыши придают силуэту автомобиля изящность. Задняя часть выделяется сложной формой и узнаваемыми сквозными задними фонарями. Габариты кузова составляют 5020 мм в длину, 1945 мм в ширину и 1760 мм в высоту. Колесная база равна 2820 мм, что обеспечивает автомобилю солидный и устойчивый вид.",
    description3:"M7 Pro Smart Driving Edition – это пятиместный автомобиль с задним приводом, использующий платформу HUAWEI DriveONE для увеличения дальности хода. Он оборудован гибридной силовой установкой с турбированным двигателем 1.5T и задним электромотором. Двигатель внутреннего сгорания развивает максимальную мощность в 152 лошадиные силы, а электромотор – 272 л.с. Автомобиль оснащен литиевой батареей емкостью 40 кВт/ч, обеспечивающей запас хода на электротяге в 240 км по циклу CLTC. Общий запас хода, при полном баке и полностью заряженной батарее, достигает 1300 км."
  },
  {
    title:"问界 新M7 Pro 五座四驱智驾版",
    codeNumber:"6d97ac61e560441398a90ca034ccbc80",
    description1:"M7 Pro в комплектации Intelligent Driving Edition с пятью посадочными местами и полным приводом – это часть экосистемы бренда, разработанная для удовлетворения запросов пользователей в отношении повышенной мощности и проходимости на сложных участках. Автомобиль сочетает в себе мощный двигатель и систему полного привода, сохраняя при этом фирменные преимущества бренда в области интеллектуальных систем помощи водителю и комфорта. Это позволяет предоставить пользователям всесторонний опыт премиальных поездок, предлагая высокий уровень удобства и безопасности для всех пассажиров.",
    description2:"Внешний вид полноприводной пятиместной версии M7 Pro Smart Driving Edition полностью повторяет дизайн заднеприводной модели. Автомобиль обладает внушительной передней частью, элегантными боковыми контурами и солидной задней частью, что подчеркивает его принадлежность к классу среднеразмерных SUV. Габариты кузова не претерпели изменений: длина составляет 5020 мм, ширина – 1945 мм, высота – 1760 мм, а колесная база равна 2820 мм. Таким образом, визуально обе версии M7 Pro Smart Driving Edition идентичны.",
    description3:"Модернизированная полноприводная версия M7 Pro 5-seat 4WD Intelligent Driving Edition получила силовую установку с двигателем 1.5T и двумя электромоторами (передним и задним). Совокупная пиковая мощность системы достигает 330 кВт (или 449 лошадиных сил), а максимальный суммарный крутящий момент – 660 Нм. Автомобиль способен разгоняться с места до 100 км/ч всего за 4.8 секунды. В отношении аккумулятора и запаса хода: комплектация включает в себя литий-ионную батарею на 40 кВт⋅ч, обеспечивающую запас хода на электротяге в 210 км по циклу CLTC. При полностью заправленном топливном баке и заряженной батарее общий запас хода увеличивается до 1250 км."
  },
  {
    title:"问界 新M7 Pro 六座后驱智驾版",
    codeNumber:"05485c5ce29947219e7a745b4c3f4f2d",
    description1:"M7 Pro в комплектации Smart Driving Edition с задним приводом и шестью посадочными местами разработан для комфортных поездок больших семей благодаря своей адаптивной компоновке. Интегрируя интеллектуальные системы вождения и продуманные удобства, M7 Pro формирует атмосферу уюта и комфорта в путешествиях, таким образом привлекая более широкую аудиторию в сегменте среднеразмерных и полноразмерных SUV.",
    description2:"Внешне M7 Pro в шестиместном исполнении с задним приводом и интеллектуальными функциями вождения практически идентичен пятиместной версии. Автомобиль выполнен в фирменном стиле, с характерной решеткой радиатора и объединенными фарами. Плавные линии кузова переходят в лаконичную заднюю часть с горизонтальными фонарями. Общий облик машины солидный и сбалансированный. Габариты кузова составляют 5020 мм в длину, 1945 мм в ширину и 1760 мм в высоту, а колесная база равна 2820 мм.",
    description3:"M7 Pro в шестиместной версии Rear Drive Smart Driving Edition приводится в движение гибридной установкой с турбонаддувом, включающей 1.5-литровый двигатель внутреннего сгорания и электромотор на задней оси. ДВС выдает максимальную мощность 152 лошадиные силы, а электромотор – 272 л.с. Система оснащена литий-ионной аккумуляторной батареей емкостью 40 кВт⋅ч, обеспечивающей запас хода в электрическом режиме по циклу CLTC до 240 километров. Общий запас хода, при полностью заправленном топливном баке и полностью заряженной батарее, достигает 1300 км."
  },
  {
    title:"问界 新M7 Pro 六座四驱智驾版",
    codeNumber:"74d5f6fd9a9647e6a785aa1a313c254c",
    description1:"M7 Pro 6WD Smart Driver Edition, являясь улучшенной версией шестиместного M7 Pro, предлагает увеличенную мощность и улучшенные характеристики полного привода. Он разработан для больших семей, стремящихся к сочетанию производительности и комфорта в поездках. Эта модель демонстрирует стремление бренда к расширению ассортимента продукции, предлагая оптимальный выбор для тех, кто ценит мощность и удобство.",
    description2:"M7 Pro в шестиместной комплектации 4WD Smart Driving Edition визуально не отличается от остальных моделей новой линейки M7 Pro. Унаследованный фамильный дизайн безошибочно идентифицирует автомобиль, а элегантные линии кузова в сочетании с оригинальным оформлением передней и задней частей подчеркивают статус премиального внедорожника среднего размера. Габаритные размеры автомобиля остались неизменными: длина 5020 мм, ширина 1945 мм, высота 1760 мм, а расстояние между осями составляет 2820 мм.",
    description3:"Полноприводная модификация M7 Pro с шестью посадочными местами использует систему привода на все колеса, включающую 1.5-литровый турбированный двигатель и два электромотора (передний и задний). Совокупная пиковая мощность этой установки достигает 330 кВт (эквивалентно 449 лошадиным силам), а общий максимальный крутящий момент – 660 Нм. Автомобиль способен разгоняться до 100 км/ч за 4.8 секунды. Аккумуляторная батарея и дальность хода идентичны характеристикам шестиместной версии с задним приводом: используется литий-ионная батарея, состоящая из тройных элементов, емкостью 40 кВт·ч. Запас хода на электротяге по циклу CLTC составляет 240 километров, а общий запас хода в гибридном режиме достигает 1300 километров."
  },
  //#endregion

  //#region 新M7 大五座  2
  {
    title:"问界 新M7 Plus 后驱版",
    codeNumber:"bc6f3c5063c84d6db9a2668678a8c558",
    description1:"",
    description2:"",
    description3:""
  },
  {
    title:"问界 新M7 Plus 四驱版",
    codeNumber:"71074801a97241d7a8eb6eab22f1fceb",
    description1:"",
    description2:"",
    description3:""
  },
  {
    title:"问界 新M7 Plus 后驱版",
    codeNumber:"e41a13c4fc2c498d8eef14b47a3948ba",
    description1:"",
    description2:"",
    description3:""
  },
  //#endregion

  //#region M9
  {
    title:"问界M9 增程 Max 六座版（42度电池）",
    codeNumber:"aa3690bc51a94d6da3fc4bb12d6a2826",
    description1:"AITO M9 Max, шестиместный гибрид, является флагманской моделью бренда, объединяющей современные технологии и премиальный комфорт. Этот автомобиль создан, чтобы предоставить исключительный опыт путешествий для самых взыскательных пользователей и задать новые стандарты в сегменте роскошных электромобилей.",
    description2:"Шестиместный гибрид M9 Max обладает неповторимым обликом, в котором ощущается технологичность и устойчивость форм. Габариты кузова (5230/1999/1800 мм при колесной базе 3110 мм) гарантируют обширное внутреннее пространство. Спереди выделяется массивная радиаторная решетка в сочетании с заостренными фарами. Плавные боковые линии кузова и 'парящая' крыша подчеркивают современный стиль. Задняя часть отличается оригинальными задними фонарями с впечатляющим световым оформлением.",
    description3:"Гибридный шестиместный автомобиль M9 Max использует турбированный 1.5-литровый двигатель в роли генератора, работающий в связке с двухмоторной системой привода на все колеса. Суммарная пиковая мощность установки достигает 445 кВт, а максимальный крутящий момент – 875 Н⋅м. Автомобиль комплектуется литий-ионной батареей на 42.6 кВт⋅ч, обеспечивающей запас хода на электротяге по циклу CLTC в 230 км. Общий запас хода в гибридном режиме составляет 1201 км."
  },
  {
    title:"问界 M9 增程 Ultra 五座版（42度电池）",
    codeNumber:"85fc7e5d79cd4119bc8bac6f50cd6f8b",
    description1:"Основанная на флагманской модели, пятиместная гибридная M9 Ultra, благодаря переосмыслению расположения сидений, стремится предложить исключительный комфорт и роскошь для небольших семей или индивидуальных клиентов. Эксклюзивное пространство демонстрирует глубокое понимание брендом разнообразных потребностей пользователей и его способность к персонализации продукта.",
    description2:"Пятиместная версия M9 Ultra унаследовала экстерьер от шестиместной, отличаясь плавными линиями кузова и оригинальным оформлением передней и задней частей. Это подчеркивает ее флагманский статус и современный облик. Габариты автомобиля остались прежними: длина 5230 мм, ширина 1999 мм и высота 1800 мм, а колесная база составляет 3110 мм. Эти размеры гарантируют простор и комфорт в салоне.",
    description3:"Пятиместный гибрид M9 Ultra по своим характеристикам схож с шестиместной версией M9 Max, предлагая эффективное сочетание 1.5T турбодвигателя, выступающего в роли генератора, и двухмоторной системы, обеспечивающей полный привод. В плане автономности, автомобиль оснащен тройной литий-ионной батареей емкостью 42.6 кВт-ч, что позволяет проехать на чистом электричестве до 230 км по циклу CLTC, а общий запас хода составляет 1201 км."
  },
  {
    title:"问界 M9 纯电 Max 六座版（100度电池）",
    codeNumber:"227c4937ea724c02ace47a4a4ea4550d",
    description1:"Шестиместный электромобиль M9 Max нацелен на обеспечение премиальной мобильности с нулевым уровнем выбросов. Он предлагает пользователям экологически безопасные, экономичные и роскошные решения для передвижения, сочетая передовые электротехнологии и интеллектуальные функции. Это способствует укреплению позиций бренда на рынке электромобилей класса люкс.",
    description2:"Электромобиль M9 Max, рассчитанный на шесть пассажиров, унаследовал дизайнерские решения серии M9, демонстрируя современный и внушительный облик. Обтекаемые линии кузова и оригинальное оформление передней части с выразительными фарами придают автомобилю особый характер. Задние фонари отличаются лаконичным и эффектным дизайном, что делает автомобиль легко узнаваемым. Габариты (длина, ширина, высота) и размер колесной базы идентичны гибридной версии, обеспечивая просторный салон.",
    description3:"Электрический шестиместный автомобиль M9 Max располагает полноприводной системой с двумя электромоторами, которые в совокупности выдают пиковую мощность в 430 кВт и крутящий момент до 800 Н·м, обеспечивая мгновенный отклик. Благодаря литий-ионной батарее емкостью 97 кВт·ч, запас хода по циклу CLTC достигает 630 км. Внутреннее пространство, рассчитанное на шесть посадочных мест, характеризуется высоким уровнем комфорта, премиальными отделочными материалами и множеством функций, повышающих удобство сидений."
  },
  {
    title:"问界M9 纯电Ultra 五座版（100度电池）",
    codeNumber:"c274c5e255c7476ea2ba03135b867e2f",
    description1:"Электрический кроссовер M9 Ultra, рассчитанный на пять мест, является воплощением передовых разработок компании в сфере электромобилей. Он сочетает в себе премиальный комфорт и роскошь, представляя собой идеальный выбор для тех, кто ценит экологичность и высокий класс. Эта модель укрепляет позиции бренда в сегменте электромобилей премиум-класса, предлагая бескомпромиссное качество и инновационные решения.",
    description2:"Электромобиль M9 Ultra, рассчитанный на пять мест, наследует дизайнерские решения серии M9: лаконичные очертания кузова, оригинальное оформление передней части и сплошная световая полоса сзади подчеркивают премиальный статус флагманской модели. Габаритные размеры автомобиля составляют 5230 мм в длину, 1999 мм в ширину и 1800 мм в высоту, а расстояние между осями равно 3110 мм.",
    description3:"Пятиместный электромобиль M9 Ultra обладает мощной двухмоторной системой полного привода, обеспечивающей превосходные впечатления от управления. Автомобиль питается от литий-ионных батарей значительной емкости, точные характеристики которых не разглашаются, но запас хода на электротяге по стандарту CLTC впечатляет. Внутреннее пространство автомобиля рассчитано на пять посадочных мест, сиденья отделаны премиальными материалами и предлагают широкий спектр регулировок для максимального комфорта, включая множество режимов массажа, а также ускоренный обогрев и вентиляцию."
  },
  //#endregion

  //#region 新 M5
  {
    title:"问界 新M5 增程 Max",
    codeNumber:"18b3b1cd747f4646b8260191f99f4b88",
    description1:"AITO представляет собой премиальную марку электромобилей нового поколения, разработанную в сотрудничестве Seres и Huawei. Этот альянс объединяет производственный опыт Seres с передовыми интеллектуальными технологиями Huawei. Гибридная модель M5 Max от AITO реализует технологическое взаимодействие двух корпораций, стремясь предоставить потребителям транспортное средство, в котором сочетаются высокие эксплуатационные характеристики и интеллектуальные возможности. Автомобиль демонстрирует новаторский потенциал бренда на рынке электромобилей.",
    description2:"Гибридный автомобиль M5 Max от AITO сохраняет узнаваемые черты фирменного дизайна, характеризующиеся обтекаемыми и динамичными линиями кузова. Габариты автомобиля составляют: длина – 4770 мм, ширина – 1930 мм, высота – 1625 мм, а расстояние между осями – 2880 мм. Данные параметры относят его к сегменту среднеразмерных SUV и гарантируют достаточное пространство в салоне. Передняя часть кузова отличается отсутствием традиционной решетки радиатора, затемненным логотипом и световой полосой, объединяющей фары. Серебристые элементы декора заменены на черные, что усиливает спортивный облик автомобиля.",
    description3:"AITO M5 Max представляет собой гибридный автомобиль, оснащенный 1,5-литровым турбированным двигателем внутреннего сгорания, который функционирует как генератор энергии, обеспечивая максимальную мощность в 152 лошадиные силы. Электрический двигатель в заднеприводной конфигурации способен развивать пиковую мощность в 200 кВт, что эквивалентно 272 л.с., а также максимальный крутящий момент в 360 Нм. Литий-ионная аккумуляторная батарея емкостью 40,06 кВт/ч обеспечивает запас хода на электротяге до 255 км в соответствии с циклом CLTC, а общий комбинированный запас хода достигает 1 440 км. Интерьер автомобиля выполнен в минималистичном стиле и предлагает три цветовых решения для салона: 'Красный', 'Янтарный' и 'Абрикосовый'. Все сиденья аналогичны установленным в AITO M9 и имеют подушки толщиной 100 мм, что должно обеспечить повышенный уровень комфорта и поддержки."
  },
  {
    title:"问界 新M5 增程 Max RS",
    codeNumber:"74bb49b9c936450c9af4d7207801a60f",
    description1:"AITO M5 Max RS представляет собой гибридную модель, разработанную на базе технологических достижений бренда AITO M5. Благодаря усовершенствованным характеристикам и расширенной комплектации, данный автомобиль ориентирован на потребителей, стремящихся к передовым технологиям и высокому уровню комфорта во время вождения. Эта модель отражает достижения бренда в области создания автомобилей премиум-класса, использующих новые источники энергии.",
    description2:"Внешне гибридная модель M5 Max RS от AITO имеет сходство с новой гибридной версией M5 Max, и наследует обтекаемые и динамичные очертания кузова. Отличительными чертами данной модели являются обновленный дизайн логотипа, выполненный в черном цвете, и новые спортивные колесные диски черного цвета. Эти элементы дизайна акцентируют внимание на повышенных эксплуатационных характеристиках автомобиля и подчеркивают его спортивный характер.",
    description3:"AITO M5 Max RS – это гибрид, использующий 1.5-литровый турбированный двигатель как генератор для подзарядки батареи. Электрическая часть реализована по схеме с двумя двигателями и полным приводом. Суммарная отдача системы достигает 365 кВт (эквивалентно 496 лошадиным силам) и 675 Н·м крутящего момента, что позволяет автомобилю разгоняться до 100 км/ч всего за 4.3 секунды, демонстрируя впечатляющую динамику. Аккумуляторная батарея и характеристики запаса хода идентичны модели M5 Max. Автомобиль комплектуется литий-ионной батареей емкостью 40.06 кВт·ч, обеспечивающей запас хода на электротяге в 255 км по циклу CLTC. Общий запас хода в гибридном режиме составляет 1440 км."
  },
  {
    title:"问界 新M5 纯电 Max",
    codeNumber:"3dd511483d1b41c281969eb0823b5956",
    description1:"AITO представляет новую модель M5 Max, разработанную исключительно для электромобильного передвижения. Используя передовые технологии бренда, автомобиль предлагает увеличенный запас хода и выдающуюся производительность, обеспечивая экологически чистые, эффективные и интеллектуальные решения в сфере электромобильности. Данное расширение модельного ряда укрепляет позиции AITO на рынке новых энергетических транспортных средств, предлагая пользователям больше возможностей выбора.",
    description2:"Эстетика экстерьера нового электрического M5 Max от AITO выдержана в фирменном стиле бренда, сочетая элегантные очертания кузова, оригинальное оформление передней части и спортивные диски, что создает впечатление современного и динамичного автомобиля. Габаритные размеры соответствуют модели M5 Plus: длина составляет 4770 мм, ширина – 1930 мм, высота – 1625 мм, а колесная база – 2880 мм. Обновленная палитра цветов и усовершенствованные детали внешнего вида способствуют повышению узнаваемости и визуальной привлекательности автомобиля.",
    description3:"AITO представляет новую электрическую версию M5 Max, доступную исключительно с приводом на задние колеса. Автомобиль оснащен электрическим двигателем, развивающим пиковую мощность в 200 кВт и максимальный крутящий момент в 360 Н·м. Заявленный запас хода по циклу CLTC составляет 602 км, что обеспечивается литий-ионной батареей емкостью 75 кВт·ч. Салон электромобиля M5 Max повторяет дизайн гибридной версии AITO M5 Max, предлагая комфортабельную обивку, передовую интеллектуальную систему управления и широкий набор опций для повышения удобства."
  },
  {//问界M5 四驱旗舰版  2
    title:"问界M5 四驱旗舰版",
    codeNumber:"48c7758264f64ecc862f7dafaa4b2f8a",
    description1:"",
    description2:"",
    description3:""
  },
  //#endregion

  //#endregion


  //#region 仰望

  //#region U8
  {
    title:"仰望U8 豪华版",
    codeNumber:"4b9a30b01c814db48074df8d883c5b40",
    description1:"Yangwang, являясь брендом высококлассных новых энергетических автомобилей BYD, ориентируется на прорывные инновационные технологии и концепцию абсолютной роскоши. Опираясь на глубокое накопление технологий BYD, компания стремится создавать высококлассные модели новых энергоносителей с глобальным влиянием. Будучи первым шедевром бренда, Yangwang U8 Deluxe Edition открывает новую главу в Китае в области новых энергетических автомобилей повышенной проходимости благодаря таким передовым технологиям, как технологическая платформа E-Square(e⁴ ) и интеллектуальная гидравлическая система управления кузовом Cloud Chariot-P, что подчеркивает лидирующие позиции бренда в области высококлассных новых энергетических автомобилей.",
    description2:"U8 Deluxe Edition оснащен гибридной системой plug-in, состоящей из 2,0-литрового двигателя с турбонаддувом и четырех приводных моторов, которые независимо друг от друга управляются с помощью технологии E-Square(e⁴ ). Емкость блока батарей составляет 49,05 кВт/ч, суммарная максимальная мощность - 880 кВт (около 1200 л.с.), максимальный крутящий момент - 1280 Н・м, разгон с нуля до сотни - всего за 3,6 секунды. Запас хода CLTC на чистом электричестве составляет 180 км, а комбинированный запас хода - 1 000 км.",
    description3:"В дизайне экстерьера Yangwang U8 используется язык дизайна 'Врата времени и пространства'. Это огромный автомобиль с размерами 5319 х 2050 х 1930 мм и колёсной базой 3050 мм. Многоугольные колесные арки с LIDAR, камерой и другим оборудованием добавляют ощущение технологичности, а D-стойка украшена светодиодными полосками, создающими особый эффект при зарядке, разрядке и разблокировке."
  },
  {
    title:"仰望U8 越野玩家版",
    codeNumber:"9672bf589faf45a198561b36dcc66cb5",
    description1:"Yangwang U8 Off-road Player Edition - эксклюзивная модель для любителей езды по бездорожью, разработанная на основе позиционирования бренда Yangwang как высококлассного внедорожника. Сохраняя основные технические преимущества бренда, она еще больше усиливает свои внедорожные характеристики и персонализированные конфигурации, чтобы предоставить любителям бездорожья более экстремальный опыт езды по бездорожью, демонстрируя точное понимание брендом сегментов рынка и разнообразия продукции.",
    description2:"Силовая установка U8 Off-road Gamer Edition такая же, как и у Deluxe Edition, включает в себя подключаемую гибридную силовую установку, состоящую из двигателя 2.0T и четырех электромоторов, с технологией E-Square(e⁴ ), обеспечивающей высокую мощность и точное управление крутящим моментом, суммарная мощность 880 кВт, крутящий момент 1280 Н・м, разгон от нуля до сотни за 0,6 секунды. Запас хода на чистом электричестве составляет 180 км, а запас хода в комбинированном режиме - 1000 км. Что касается внедорожных характеристик, то на основе интеллектуальной гидравлической системы управления кузовом Cloud Chariot-P, по сравнению с Deluxe Edition, добавлены режимы Rally Mode и Off-road Custom Mode, позволяющие свободно настраивать параметры автомобиля в соответствии с различными сценариями бездорожья.",
    description3:"Внешний дизайн U8 Off-Road Gamer Edition похож на дизайн Deluxe Edition, сохраняя стиль дизайна семейства «Врата времени и пространства». Его размеры составляют 5325 (включая запасное колесо)/2095/1940 мм, а колесная база - 3050 мм. Среди уникальных особенностей - более внедорожный стиль, например, возможность установки более прочного защитного пакета для кузова. Эта версия имеет более агрессивный внешний вид с черными элементами и 21-дюймовыми легкосплавными дисками."
  },
  //#endregion

  //#region U9
  {
    title:"仰望 U9",
    codeNumber:"470e71f9a5e7432a8baa56b19f37e183",
    description1:"Являясь суперкаром марки, U9 воплощает в себе неустанное стремление бренда к экстремальным характеристикам и передовым технологиям. Благодаря глубоким техническим достижениям BYD в области новых источников энергии и интеграции передовой электрификации и интеллектуальных технологий, компания стремится создать первоклассный суперкар для пользователей, стремящихся получить максимальный опыт вождения, установить новую планку для отечественных суперкаров и усилить влияние бренда в области высокопроизводительных автомобилей.",
    description2:"U9 отличается футуристичным и агрессивным дизайном экстерьера с плавными и мощными линиями. Низкая посадка автомобиля в сочетании с увеличенными аэродинамическими элементами, такими как большие воздухозаборники и спойлеры, подчеркивает его статус суперкара. Уникальные фары сливаются с линиями кузова, создавая легко узнаваемый облик.",
    description3:"В основе U9 лежат передовые электрические технологии BYD и высокопроизводительная система электропривода. Хотя конкретные параметры мощности не раскрываются, ожидается, что он будет обладать потрясающей мощностью и крутящим моментом, что позволит достичь чрезвычайно быстрого ускорения, а разгон от нуля до сотни может войти в «2-секундный клуб». Что касается аккумуляторных батарей, то ожидается, что они будут оснащены батареями с высокой плотностью энергии, что позволит обеспечить запас хода для повседневной езды и трека."
  },
  //#endregion

  //#endregion


  //#region 领克

  //#region 08 EM-P
  {
    title:"领克08 EM-P 220四驱性能Halo",
    codeNumber:"63dd8106825c4f96b2bdd9c7b51289a3",
    description1:"LYNK&Co, являясь высококлассным брендом Geely Automobile, объединяет технологии безопасности Volvo и производственную мощь Geely, чтобы создавать уникальные автомобили с неповторимым дизайном, превосходными характеристиками и передовыми технологиями. LYNK&Co 08 EM-P 220 4WD Performance Halo, новая модель LYNK&Co. Она сочетает в себе модный дизайн и передовую гибридную технологию, чтобы подарить пользователям удовольствие от вождения с экономией энергии.",
    description2:"LYNK&Co 08 EM-P 220 4WD Performance Halo оснащен интеллектуальной гибридной электросистемой Lynk E-Mix, состоящей из 1,5-литрового двигателя с турбонаддувом и электромотора, суммарной мощностью 220 кВт и суммарным крутящим моментом 568 Н・м, а также мощным разгоном от 0 до 100 всего за 5,9 секунды. Для повышения эффективности трансмиссии используется трехступенчатая электроприводная трансмиссия DHT Pro. Запас хода на чистом электричестве составляет 245 км, на комбинированном - 1400 км.",
    description3:"LYNK&Co 08 EM-P 220 4WD Performance Halo был разработан целью предоставить потребителям инновационные технологии, удобство и безопасность, которые соответствуют современному образу жизни. Ходовые огни и уникальные кластеры фар создают мощную технологическую атмосферу. Динамичные боковые линии, скрытые дверные ручки и большие колеса не только подчеркивают стиль автомобиля, но и снижают сопротивление ветра."
  },
  {
    title:"领克 08 EM-P 245超长续航Halo",
    codeNumber:"43e0f8760d734065960b41b3e88f4915",
    description1:"LYNK&Co 08 EM-P 245 Extended Range Halo подтверждает приверженность LYNK&Co высоким стандартам и инновациям. Автомобиль, обеспечивающий исключительное удовольствие от управления, значительно увеличивает запас хода благодаря усовершенствованной аккумуляторной технологии. Это позволяет решить проблему ограниченного запаса хода, волнующую многих пользователей, и предоставляет более комфортное и функциональное решение для электромобильности.",
    description2:"Модель LYNK&Co 08 EM-P 245 Extended Range Halo выделяется наличием интеллектуальной гибридной системы Lynk E-Mix. В отличие от версии 220 4WD Halo, она имеет иные параметры мощности, но сохраняет при этом высокую производительность. Главным преимуществом является впечатляющий запас хода на электротяге в 245 км, а общий запас хода достигает 1400 км. Это полностью удовлетворяет запросы пользователей как для ежедневных поездок по городу, так и для длительных путешествий.",
    description3:"Внешний вид LYNK&Co 08 EM-P 245 Extended Range Halo идентичен версии 220 4WD Performance Halo, демонстрируя 'городскую контрастную эстетику'. Плавные очертания кузова и оригинальное оформление передней оптики гармонично сочетаются с выразительными задними фонарями, создавая запоминающийся и стильный облик. Общий силуэт автомобиля – среднеразмерный и подчеркнуто спортивный."
  },
  {
    title:"领克08 EM-P 245超长续航Pro",
    codeNumber:"424921511d864037b672ce061db3a38a",
    description1:"Модификация LYNK&Co 08 EM-P 245 Extended Range Pro, представляющая собой экономичный вариант в рамках продуктовой линейки компании, разработана с учетом запросов покупателей на увеличенный запас хода и функциональность. Это позволяет бренду привлечь большее число потребителей на рынке электромобилей.",
    description2:"Модель LYNK&Co 08 EM-P 245 Extended Range Pro укомплектована идентичной силовой установкой, что и версия 245 Extended Range Halo. Она также использует интеллектуальную гибридную систему Lynk E-Mix, предлагая запас хода в 245 км на электротяге и суммарный запас хода в 1400 км. В отличие от комплектации Halo, данная версия имеет упрощенную конфигурацию, однако сохраняет функциональность, достаточную для ежедневной эксплуатации. Это делает автомобиль привлекательным выбором для тех, кто ценит сбалансированное сочетание характеристик и стоимости.",
    description3:"LYNK&Co 08 EM-P 245 Extended Range Pro демонстрирует фамильный дизайн LYNK&Co, обладая современным и внушительным силуэтом. Оригинальное оформление передней части гармонично сочетается с обтекаемыми линиями кузова, а крупные колесные диски подчеркивают динамичный характер автомобиля. Задние фонари отличаются лаконичным стилем и обеспечивают хорошую видимость в темное время суток."
  },
  {
    title:"领克 08 EM-P 120长续航Halo",
    codeNumber:"9a1ad4ac69e54590a72762e3d27124a6",
    description1:"Основываясь на принципах бренда, LYNK&Co 08 EM-P 120 Long Range Halo предлагает покупателям экономически выгодный вариант электромобиля. Благодаря более привлекательной цене и практичным характеристикам запаса хода, он отражает стремление компании к расширению модельного ряда и удовлетворению различных потребностей клиентов.",
    description2:"LYNK&Co 08 EM-P 120 Long Range Halo оснащен интеллектуальной гибридной технологией Lynk E-Mix, позволяющей проехать 120 км исключительно на электротяге и свыше 1000 км в гибридном режиме. Салон отличается роскошным оформлением с крупной центральной консолью и цифровой приборной панелью, а мультимедийная система поддерживает голосовое управление и множество других опций. Кресла отделаны первоклассными материалами и оборудованы функциями подогрева, вентиляции и другими удобствами для комфортной поездки.",
    description3:"Внешний облик LYNK&Co 08 EM-P 120 Long Range Halo перекликается с остальными представителями линейки LYNK&Co 08 EM-P, воплощая в себе дизайнерскую философию 'Городской контрастной эстетики'. Для него характерны текучие линии кузова, необычное оформление передней оптики и сквозная конструкция задних фонарей, что делает автомобиль легко узнаваемым и подчеркивает его современный стиль."
  },
  {
    title:"领克 08 EM-P 120长续航Pro",
    codeNumber:"d11b1698d73549f79c7d2ab8eabac657",
    description1:"LYNK&Co 08 EM-P 120 Long Range Pro, занимающий свое место в модельном ряду бренда, создан для удовлетворения запросов покупателей, нуждающихся в автомобиле для ежедневных поездок и перемещений по городу. Благодаря оптимальным комплектациям и доступной стоимости, он предлагает клиентам экономичные и функциональные решения в сегменте новых энергетических автомобилей.",
    description2:"Lynk&Co 08 EM-P 120 Long Range Pro использует интеллектуальную гибридную технологию Lynk E-Mix, предлагая 120 км пути исключительно на электротяге и свыше 1000 км в гибридном режиме. Интерьер ориентирован на практическое применение, выделяется большим центральным дисплеем управления, а возможности автомобильной системы в целом соответствуют потребностям ежедневной эксплуатации. Сиденья изготовлены с использованием удобных материалов и включают основные опции настройки. Интеллектуальная система помощи водителю располагает стандартными функциями, такими как камера заднего вида и круиз-контроль, оказывая определенную поддержку во время вождения.",
    description3:"LYNK&Co 08 EM-P 120 Long Range Pro демонстрирует узнаваемую стилистику семейства LYNK&Co 08 EM-P, характеризуясь лаконичными, объемными формами и современным обликом. Неповторимое оформление передней и задней частей обеспечивает автомобилю мгновенную узнаваемость на дороге."
  },
  {
    title:"领克08 EM-P120 长续航Plus",
    codeNumber:"a06eb9b063a949618fff0a04051c9243",
    description1:"LYNK&Co 08 EM-P 120 Long Range Plus, будучи базовой версией линейки LYNK&Co 08 EM-P, открывает двери в мир бренда LYNK&Co и инновационных электромобилей для более широкой аудитории, благодаря своей доступной цене. Это способствует укреплению позиций бренда на рынке.",
    description2:"LYNK&Co 08 EM-P 120 Long Range Plus использует гибридную систему Lynk E-Mix, позволяющую проехать 120 км на электротяге и более 1000 км в гибридном режиме. Салон автомобиля предлагает практичный функционал, включая базовые возможности телефонной связи и комфортные кресла. Несмотря на упрощенную систему интеллектуальной помощи водителю, в автомобиле предусмотрены основные функции, такие как камера заднего вида, повышающая безопасность вождения.",
    description3:"Внешний вид LYNK&Co 08 EM-P 120 Long Range Plus унаследовал фамильные черты бренда LYNK&Co. Его общие очертания отличаются простотой и элегантностью линий, обеспечивая высокую узнаваемость. Отличительные блоки передних фар и оригинальное оформление задних фонарей, выполненное в виде единой полосы, наделяют автомобиль ярким и современным стилем."
  },
  //#endregion

  //#region Z10
  {
    title:"领克z10 71kWh 602km后驱Pro",
    codeNumber:"3077bd0805644c4c8027c2c138879228",
    description1:"LYNK&Co, будучи премиальным и стильным подразделением Geely Automobile, сочетает в себе передовые технологии безопасности Volvo и производственные мощности Geely. Бренд неуклонно следует принципам инноваций, стремясь выпускать автомобили, выделяющиеся современным дизайном, превосходными характеристиками и передовыми технологическими решениями. Новая модель LYNK&Co Z10 71kWh 602km Rear Drive Pro развивает успех бренда, предлагая покупателям эффективные решения в сфере электромобильности с помощью доступных конфигураций. Это расширяет модельный ряд LYNK&Co, делая его еще более привлекательным для широкого круга потребителей.",
    description2:"Lynk & Co Z10 71kWh 602km Rear Drive Pro с батареей 71 кВт/ч и запасом хода 602 км по циклу CLTC, использует один двигатель, приводящий в движение задние колеса. Пиковая мощность мотора достаточна для динамичной езды в городе и на трассе. Этот электромобиль с запасом хода 602 км идеально подходит как для ежедневных поездок на работу, так и для непродолжительных путешествий. Салон отличается премиальной отделкой. Мягкие и комфортабельные сиденья предлагают необходимый набор регулировок, обеспечивая удобство во время поездок.",
    description3:"Узнаваемый облик LYNK&Co Z10 71kWh 602km Rear Drive Pro формируется за счет закрытой радиаторной решетки и раздельной оптики головного света. Плавные боковые линии кузова и покатая крыша, переходящая в заднюю часть, в сочетании с большими колесами, подчеркивают спортивный характер автомобиля. Задние сквозные фонари отличаются лаконичным и эффектным дизайном, обеспечивая высокую узнаваемость в темное время суток и гармонично завершая общий стиль."
  },
  {
    title:"领克z10 95kWh 766km 后驱Max",
    codeNumber:"95c799bbc6894f54994cbd1dcfabe5fb",
    description1:"LYNK&Co Z10 Rear-Drive Max с батареей 95 кВт⋅ч и запасом хода 766 км, опираясь на узнаваемый стиль LYNK&Co , предлагает оптимальное сочетание дальности поездок и оснащения. Это делает его привлекательным выбором для тех, кто ценит дальние путешествия и комфортное вождение, демонстрируя прогрессивный подход бренда к новым энергетическим технологиям.",
    description2:"LYNK&Co Z10 95kWh 766km Rear-Drive Max оборудован одномоторной системой с задним приводом, гарантирующей уверенную и достаточную тягу. Автомобиль, укомплектованный батареей на 95 кВт⋅ч, обладает запасом хода в 766 км по циклу CLTC, что позволяет забыть о беспокойстве из-за разряженного аккумулятора. Внутреннее пространство отличается изысканной отделкой, а сиденья, обтянутые премиальной кожей, дополнены опциями подогрева и вентиляции для максимально комфортных поездок.",
    description3:"LYNK&Co Z10 95kWh 766km Rear-Drive Max придерживается общего дизайна семейства LYNK&Co Z10, выделяясь обтекаемыми формами и современным видом. Передняя часть отличается оригинальным оформлением с раздельными фарами и глухой решеткой, что подчеркивает технологичный характер. Боковой силуэт характеризуется покатой линией крыши, гармонично сочетающейся с крупными колесными дисками, а задняя часть привлекает внимание лаконичным и многослойным дизайном оптики."
  },
  {
    title:"领克z10 95kWh 806km 后驱智驾版Max",
    codeNumber:"d52fd0c078954ebc9b3ed894f8a27133",
    description1:"Новейшая разработка LYNK&Co, модель Z10, является воплощением передовых технологий и актуальных дизайнерских решений. LYNK&Co Z10 95kWh 806km Rear-Drive Intelligent Drive Max, обладая внушительным запасом хода и интеллектуальной системой управления, предоставляет пользователям комфортное и продуктивное вождение. Это также подчеркивает новаторский потенциал компании в секторе электромобилей.",
    description2:"Электромобиль LYNK&Co Z10 Intelligent Drive Max с батареей 95 кВт-ч и задним приводом обладает запасом хода в 806 км по циклу CLTC. Его одномоторная конфигурация обеспечивает пиковую мощность в 200 кВт и плавное ускорение. Внутри салона расположены крупный дисплей мультимедийной системы и цифровая приборная панель. Автомобиль поддерживает интеллектуальное управление голосом, телематические сервисы и другие современные функции.",
    description3:"LYNK&Co Z10 Intelligent Drive Max с задним приводом и батареей на 95 кВт⋅ч, обеспечивающей запас хода в 806 км, отличается обновленным стилем. Его силуэт выглядит модно и энергично. Обтекаемые контуры кузова и покатая задняя часть в сочетании с большими колесными дисками подчеркивают атлетичный вид. Спереди автомобиль имеет закрытую конструкцию радиаторной решетки, которая в сочетании с тонкими блоками фар создает впечатление высокотехнологичности. Задняя часть выделяется оригинальным дизайном сквозных фонарей, обеспечивающих впечатляющий визуальный эффект в темное время суток."
  },
  {
    title:"领克z10 95kWh 702km四驱Halo",
    codeNumber:"537bc8c27d9b4f23904bf95e00f46f92",
    description1:"LYNK&Co Z10 Halo с батареей 95 кВтч и запасом хода 702 км в версии 4WD – это флагманская модель бренда, сочетающая в себе высокую производительность полного привода и широкий набор опций. Благодаря своим характеристикам, Z10 Halo станет отличным выбором для тех, кто ищет бескомпромиссное качество и современные технологии в одном автомобиле.",
    description2:"Полноприводная версия LYNK&Co Z10 Halo, оснащенная батареей на 95 кВт⋅ч, способна проехать до 702 км по циклу CLTC. Ее двухмоторная силовая установка обеспечивает совокупную пиковую мощность в 300 кВт и общий крутящий момент 600 Н⋅м. Салон авто отличается премиальной отделкой, а сиденья, обтянутые дорогой кожей, располагают опциями обогрева, вентиляции и массажа.",
    description3:"LYNK&Co Z10 Halo с батареей 95 кВт⋅ч и запасом хода 702 км, оснащенный полным приводом, создан на базе концепции Rear Drive Intelligent Driving Max. Автомобиль отличается элегантным и динамичным обликом, а также оригинальным оформлением передней и задней частей, подчеркивающим фирменный стиль LYNK&Co. Габариты кузова обеспечивают наличие большого внутреннего пространства."
  },
  {
    title:"领克z10 95kWh 702km四驱智驾Ultra",
    codeNumber:"2c4f34cc3e3042f1b9a62e77392b2783",
    description1:"Флагманская версия LYNK&Co Z10, а именно модель 95kWh 702km 4WD Intelligent Drive Ultra, представляет собой симбиоз передовых технологических решений и премиальных особенностей, присущих марке LYNK&Co. Этот автомобиль создан для для потребителей, стремящихся к бескомпромиссному комфорту в поездках, и призван укрепить позиции бренда в сегменте электромобилей класса люкс.",
    description2:"Lynk & Co Z10 в версии Intelligent Driving Ultra с батареей 95 кВт⋅ч и полным приводом 4WD использует аналогичную трансмиссию, что и комплектация 4WD Halo, предлагая двухмоторную систему и полный привод. Автономность хода достигает 702 км благодаря установленной батарее на 95 кВт⋅ч. В плане интеллектуальных функций вождения, данная модификация выделяется улучшенными сенсорами и более производительной вычислительной платформой. Это позволяет реализовать систему помощи водителю уровня L3, обеспечивая более точное распознавание дорожной обстановки и более эффективное принятие решений в различных ситуациях на дороге.",
    description3:"LYNK&Co Z10 с батареей 95кВтч и полным приводом предлагает запас хода в 702 км, а также интеллектуальные системы вождения. Экстерьер Z10 перекликается с остальными моделями LYNK&Co, демонстрируя элегантный и энергичный облик, дополненный оригинальными деталями, подчеркивающими премиальность. Плавные линии кузова, крупные колеса и эксклюзивные оттенки кузова выделяют автомобиль и создают ощущение роскоши."
  },
  //#endregion

  //#endregion


  //#region 腾势

  //#region D9 DM-i
  {
    title:"腾势D9 DM-i970豪华型 24款",
    codeNumber:"19892e13c645468f84ae629e4428b468",
    description1:"Denza, марка автомобилей на альтернативных источниках энергии, возникшая благодаря сотрудничеству BYD и Mercedes-Benz, объединяет в себе лидирующие разработки BYD в сфере аккумуляторных и электромобильных технологий с премиальным уровнем Mercedes-Benz и его отточенным автомобильным производством. Denza D9 DM-i 970 Deluxe, будучи среднеразмерным минивэном этого бренда, благодаря своей выдающейся гибридной системе и удобству управления, предлагает покупателям первоклассные и функциональные решения для поездок, задавая новую планку для минивэнов на новых источниках энергии.",
    description2:"Denza D9 DM-i 970 Deluxe использует подключаемую гибридную установку, которая включает в себя 1,5-литровый турбированный двигатель и электрический мотор. Мощность бензинового двигателя составляет 139 лошадиных сил (102 кВт), а электрический двигатель обеспечивает 231 л.с. (170 кВт). Общая мощность гибридной системы достигает 221 кВт, а суммарный крутящий момент – 571 Нм. Автомобиль оборудован литий-железо-фосфатной аккумуляторной батареей с емкостью 20,39 кВт⋅ч, обеспечивающей запас хода на электротяге по циклу CLTC в 103 километра. Общий запас хода в гибридном режиме по стандарту NEDC составляет 970 километров.",
    description3:"Denza D9 DM-i 970 Deluxe отличается внушительными габаритами: длина 5250 мм, ширина 1960 мм, высота 1920 мм. Колесная база в 3110 мм гарантирует просторный салон благодаря большому кузову. Спереди автомобиль выделяется массивной хромированной многоугольной решеткой радиатора, которая в сочетании с заостренными светодиодными фарами создает эффектный и внушительный образ."
  },
  {
    title:"腾势D9 DM-i1050尊享型 24款",
    codeNumber:"ffe7d07c222b41c2b2f7fb17e7fdf101",
    description1:"Модель Denza D9 DM-i 1050 Premium еще больше укрепляет позиции бренда на рынке высококлассных минивэнов с улучшенным функционалом и богатыми конфигурациями в линейке бренда, чтобы удовлетворить потребности потребителей в дальних поездках и комфортном вождении.",
    description2:"Denza D9 DM-i 1050 Premium В силовом агрегате также используется гибридная система plug-in, состоящая из 1,5-литрового двигателя и электромотора. В качестве батареи использована литий-железо-фосфатная батарея большей емкости, а запас хода на чистом электричестве по WLTC составляет 155 км, что улучшает общие характеристики. Сиденья в салоне выполнены из высококачественной кожи, что еще больше повышает комфорт.",
    description3:"Внешний дизайн Denza D9 DM-i 1050 Premium такой же, как у 970 Deluxe, с выразительной формой кузова, уникальным дизайном передней и задней части, демонстрирующим темперамент роскошного минивэна. Габариты кузова остались прежними: длина, ширина и высота 5250/1960/1920 мм, колесная база 3110 мм, что обеспечивает комфортное пространство."
  },
  {
    title:"腾势D9 DM-i 980四驱尊贵型 24款",
    codeNumber:"d9af82626d7e4981b8adc48bd5b55762",
    description1:"Denza D9 DM-i 980 4WD Premium оснащен системой полного привода и мощной силовой установкой, что создает эксклюзивную поездку для потребителей, которые стремятся к превосходным характеристикам и долговечности, обогащает модельный ряд бренда в области новых энергетических MPV и демонстрирует технологическую мощь бренда.",
    description2:"Модель Denza D9 DM-i 980 4WD Premium использует подключаемую гибридную установку, которая включает в себя 1,5-литровый турбированный двигатель и 2 электрических мотора, расположенные спереди и сзади. Конкретные параметры мощности и крутящего момента пока не раскрываются, но запас хода в комбинированном режиме достигает 980 км.",
    description3:"Модель Denza D9 DM-i 980 4WD Premium сохраняет единый стиль с другими моделями D9 DM-i: атмосферная решетка радиатора и острые фары спереди, плавные линии по бокам кузова, удобные и практичные боковые сдвижные двери с электроприводом с обеих сторон. Дизайн задних фонарей прост и великолепен, что повышает узнаваемость автомобиля."
  },
  {
    title:"腾势D9 DM-i980四驱尊荣型 24款",
    codeNumber:"12a2979ba19742bd827c7cc23b0653f2",
    description1:"Модель Denza D9 DM-i 980 4WD Deluxe еще больше оптимизирует конфигурацию на основе модели 4WD Premium, чтобы усилить ощущение роскоши и комфорта, предоставляя потребителям лучшие впечатления от вождения и удовлетворяя потребности пользователей с более высокими требованиями к качеству. ",
    description2:"Denza D9 DM-i 980 4WD Deluxe оснащен гибридной системой полного привода, которая гарантирует высокую мощность и отличную долговечность, а запас хода составляет 980 км. Сиденья в салоне выполнены из высококачественной кожи, оснащены подогревом, вентиляцией, массажем и другими функциями комфорта. Аудиосистема премиум-класса создает превосходные звуковые эффекты в салоне. Интеллектуальная система помощи водителю усовершенствована, она лучше распознает и реагирует на дорожные условия, обеспечивая более надежную гарантию безопасности вождения.",
    description3:"Дизайн экстерьера Denza D9 DM-i 980 4WD Deluxe продолжает семейный стиль, общая форма атмосферы стабильна, линии кузова плавны, а уникальный дизайн передней и задней части демонстрирует роскошный темперамент. Размеры автомобиля обеспечивают просторный салон."
  },
  {
    title:"腾势D9 DM-i980四驱旗舰型 24款",
    codeNumber:"ad6e61ddfbc74ba493df8b82e7accdde",
    description1:"Флагманская модель Denza D9 DM-i 980 4WD, являющаяся топовой моделью серии D9 DM-i, сочетает в себе передовые технологии и роскошные характеристики, чтобы создать эталон бренда в области высококлассных минивэнов и создать высококлассный автомобиль для потребителей, стремящихся получить максимальный комфорт в путешествии.",
    description2:"Флагманская модель Denza D9 DM-i 980 4WD оснащена подключаемой гибридной системой полного привода с отличными характеристиками мощности и управляемости, а запас хода составляет 980 км. Интерьер выполнен на высшем уровне: сиденья из высококачественных материалов с большим количеством удобных регулировок. Оснащенный высококачественной аудиосистемой, системой ароматизации и несколькими дисплеями высокой четкости (включая задние развлекательные экраны), автомобиль создает чрезвычайно комфортную и технологически насыщенную среду для вождения.",
    description3:"Внешний вид флагманской модели Denza D9 DM-i 980 4WD остался таким же, как и у других моделей, общий стиль - атмосферный и полный ощущения технологичности, с уникальными элементами дизайна, которые выделяют его из толпы. Благодаря своим внушительным размерам автомобиль создает мощный эффект."
  },
  //#endregion

  //#region D9 EV
  {
    title:"腾势D9 EV620 尊享型 24款",
    codeNumber:"898dba1adab24390a658f367257609e2",
    description1:"Denza D9 EV 620 Premium разработан для обеспечения исключительно электрической мобильности. Опираясь на передовой опыт бренда в сфере электротехнологий, он предоставляет покупателям экологичные и высокоэффективные полностью электрические минивэны. Благодаря значительному запасу хода и комфортному управлению, он расширяет ассортимент экологически чистых автомобилей бренда.",
    description2:"Электромобиль Denza D9 EV 620 Premium использует одномоторную систему с приводом на задние колеса, чья мощность оптимизирована для повседневного использования. Автономность хода, измеренная по циклу CLTC, достигает 620 км благодаря аккумулятору оптимальной емкости. Салон отличается премиальной отделкой, где применяются высококачественные материалы для обивки сидений, а также реализованы опции комфорта, включая подогрев и вентиляцию.",
    description3:"Электромобиль Denza D9 EV 620 Premium имеет экстерьер, визуально перекликающийся с гибридной модификацией, создавая впечатление солидности и уверенности. Передняя часть отличается лаконичным дизайном без радиаторной решетки и выделяется узкими светодиодными фарами, подчеркивающими современный облик. Плавные боковые линии кузова дополняются электрическими сдвижными дверями с обеих сторон, обеспечивающими удобный доступ в салон для всех пассажиров."
  },
  {
    title:"腾势D9-EV600 四驱尊贵型 24款",
    codeNumber:"c8d44b7bbf9c4dd3a4453696b6e5b767",
    description1:"Denza D9 EV 600 4WD Premium разработана для тех, кто ценит динамику и управляемость, предлагая полный привод и экологически чистую электрическую тягу. Модель не изменяет ключевым преимуществам бренда, таким как комфорт и современные интеллектуальные решения, гарантируя пользователям полноценный опыт вождения электромобиля.",
    description2:"Электромобиль Denza D9 EV 600 4WD Premium располагает системой полного привода с двумя электромоторами, чья точная мощность производителем не указана. Заявленный запас хода по циклу CLTC с использованием батареи оптимальной емкости достигает 600 км. Интерьер отличается премиальной отделкой с удобными сиденьями, поддерживающими широкий спектр регулировок. Массивная центральная консоль, цифровая приборная панель и атмосферная подсветка салона обеспечивают водителю и пассажирам высокий уровень комфорта во время поездки.",
    description3:"Внешний дизайн модели Denza D9 EV 600 4WD Premium выдержан в том же стиле, что и другие модели Denza D9, с общим атмосферным и современным обликом. Уникальный дизайн передней части и плавные линии кузова демонстрируют стильный и стабильный характер. Широкие габариты кузова обеспечивают просторный салон."
  },
  {
    title:"腾势D9 EV600四驱旗舰型 24款",
    codeNumber:"099f8bca3776480d9f1272d794c1c190",
    description1:"Топовая версия полностью электрического Denza D9 EV 600 4WD объединяет передовые разработки бренда в сфере электрификации, интеллектуальных систем и премиального комфорта. Эта флагманская модель создана для взыскательных клиентов, стремящихся к максимальному удовольствию от поездок на электромобиле. Автомобиль призван укрепить позиции бренда в сегменте роскошных электрических минивэнов и повысить его конкурентоспособность.",
    description2:"Топовая комплектация Denza D9 EV 600 4WD отличается системой полного привода с двумя электромоторами, что гарантирует превосходную динамику. Автономность хода по циклу CLTC, достигаемая благодаря батарее увеличенной емкости, равна 600 км. Салон автомобиля выполнен с использованием премиальных материалов, а кресла обладают расширенным набором функций для удобства, включая обогрев, вентиляцию и массаж. Кроме того, машина располагает аудиосистемой высокого класса, системой ароматизации воздуха и несколькими дисплеями с высоким разрешением, что создает атмосферу максимального комфорта и удовольствия от вождения.",
    description3:"Дизайн экстерьера топовой модели Denza D9 EV 600 4WD выдержан в общей стилистике бренда, силуэт автомобиля создает ощущение солидности и уверенности. Плавные очертания кузова, а также оригинальное оформление передней и задней частей подчеркивают премиальный статус флагманской модели. Габариты автомобиля обеспечивают просторный и комфортабельный салон для пассажиров."
  },
  //#endregion

  //#endregion


  //#region 小鹏

  //#region X9
  {
    title:"小鹏X9 610长续航Pro",
    codeNumber:"a5ea51eaf7124fd2aeaa704f376751e5",
    description1:"Являясь ведущим брендом среди новых автопроизводителей, компания Xpeng Auto всегда стремилась к тому, чтобы предоставить возможность путешествовать с помощью передовых интеллектуальных технологий. Благодаря самостоятельно разработанной технологии интеллектуального ассистирующего вождения XNGP и ведущим трем электрическим системам, Xpeng продолжает продвигать преобразование автомобильного интеллекта. Xpeng X9 610 Long Range Pro - шедевр бренда в области интеллектуальных MPV высокого класса - объединяет в себе комфортное пространство и передовые технологии, создавая интеллектуальный и удобный опыт путешествий для пользователей.",
    description2:"Xpeng X9 610 Long Range Pro оснащен литий-железо-фосфатной батареей емкостью 84,5 кВт-ч, а дальность хода на чистом электричестве по циклу CLTC составляет 610 км, что позволяет эффективно устранить беспокойство по поводу дальности поездки. Автомобиль имеет семиместную компоновку 2+2+3, сиденья выполнены из высококачественных кожаных материалов, а второй ряд оснащен индивидуальными сиденьями с электрорегулировками и подогревом, обеспечивающими комфортную езду.",
    description3:"Длина, ширина и высота кузова Xpeng X9 610 Long Range Pro составляют 5293/1988/1785 мм, а колесная база - 3160 мм, что создает просторный салон. Уникальные раздвоенные фары и активная решетка радиатора сочетают в себе технологичность и практичность. Плавающая крыша и скрытая конструкция дверных ручек не только улучшают общую эстетику автомобиля, но и снижают сопротивление ветра."
  },
  {
    title:"小鹏X9 610长续航 Max",
    codeNumber:"16fc5ca652d74afa82a80d7abd7db58e",
    description1:"Xpeng X9 610 Long Range Max еще больше расширяет конфигурацию на основе версии Long Range Pro, чтобы удовлетворить стремление пользователей к высококачественным путешествиям и интеллектуальному опыту, и демонстрирует инновационную силу Xpeng Auto в области интеллектуальных MPV.",
    description2:"Силовая установка Xiaopeng X9 610 Long Range Max такая же, как у 610 Long Range Pro, с задним приводом с одним двигателем, обеспечивающим стабильную мощность. Интерьер полностью обновлен, а на сиденьях второго ряда добавлены функции вентиляции и массажа, что значительно повышает комфорт. В салоне установлен 14,96-дюймовый экран для развлечения пассажиров.",
    description3:"Внешний дизайн Xpeng X9 610 Long Range Max повторяет дизайн 610 Long Range Pro: плавные линии корпуса, уникальный дизайн передней и задней части, демонстрирующий чувство технологии и стиля. Аналогичные размеры корпуса обеспечивают просторный салон."
  },
  {
    title:"小鹏X9 702 超长续航Pro",
    codeNumber:"1f6b415fdfb948d0abd41f3a05ca3bab",
    description1:"Xpeng X9 702 Ultra Long Range Pro ориентирован на потребности пользователей в большом запасе хода и решает их проблемы с запасом хода, оптимизируя технологию батареи, сохраняя при этом сильные стороны бренда в интеллектуальной конфигурации и комфортной езде, предоставляя пользователям более надежное и удобное решение для путешествий на чистом электричестве.",
    description2:"Xpeng X9 702 Ultra Long Range Pro оснащен системой заднего привода с одним двигателем и максимальной мощностью 235 кВт. Благодаря литий-железо-фосфатной батарее емкостью 101,5 кВт/ч запас хода на электротяге составляет 702 км, что отвечает потребностям дальних поездок. Расположение сидений в салоне и материалы отделки аналогичны тем, что используются в 610 Long Range Pro, обеспечивая комфортное вождение.",
    description3:"Дизайн экстерьера Xpeng X9 702 ultra-long range Pro продолжает стиль семейства Xpeng X9, общая форма проста и атмосферна, наполнена чувством технологии. Уникальные фары и пронизывающие задние фонари повышают узнаваемость автомобиля. Боковые линии автомобиля плавные, а плавающая крыша с большими колесами подчеркивает чувство стиля."
  },
  {
    title:"小鹏X9 702超长续航 Max",
    codeNumber:"90853689434748cf97ae8c1a1587c6f0",
    description1:"Xpeng X9 702 Ultra Long Range Max, являясь первоклассной версией модели с большим запасом хода, сочетает в себе большой запас хода, высокий интеллект и роскошные характеристики, создавая универсальный автомобиль высокого класса для пользователей, которые стремятся получить максимальный опыт путешествий, и повышая конкурентоспособность бренда на рынке интеллектуальных MPV высокого класса.",
    description2:"Xpeng X9 702 Ultra Long Range Max оснащен задним приводом с одним мотором и имеет запас хода 702 км, а внутренняя конфигурация была обновлена: кожаные сиденья высшего качества с подогревом, вентиляцией, массажем и другими функциями комфорта. Оснащен 14,96-дюймовым экраном для развлечения пассажиров и 12,8-дюймовым потолочным экраном, что обеспечивает пассажирам богатые возможности для развлечений во время поездки.",
    description3:"Внешний дизайн Xpeng X9 702 Ultra Long Range Max выполнен в едином стиле с другими моделями Xpeng X9: устойчивая общая форма, плавные линии и уникальные элементы дизайна выделяют его среди множества MPV. Размеры автомобиля обеспечивают просторное и комфортное пространство внутри."
  },
  {
    title:"小鹏X9 640四驱高性能 Max",
    codeNumber:"46e1cdaaff6049979922cd410076dd47",
    description1:"Благодаря мощному полному приводу и высокопроизводительной конфигурации Xpeng X9 640 4WD High Performance Max создает эксклюзивную поездку для пользователей, которые стремятся к отличным ходовым качествам и проходимости, обогащает ассортимент продукции бренда в области интеллектуальных MPV и демонстрирует силу бренда в технологических инновациях.",
    description2:"Xiaopeng X9 640 4WD High Performance Max оснащен системой полного привода с двумя моторами, суммарная максимальная мощность которых составляет 360 кВт, мощная отдача и отличное ускорение с нуля до сотни. Благодаря литий-железо-фосфатной батарее емкостью 84,5 кВт-ч запас хода на электротяге составляет 640 км, а интерьер аналогичен интерьеру 702 Ultra Long Range Max , оснащенного высококачественными сиденьями, несколькими развлекательными экранами и аудиосистемой премиум-класса, обеспечивающими комфорт и удобство вождения.",
    description3:"Внешний дизайн Xpeng X9 640 4WD High Performance Max соответствует другим моделям Xpeng X9: стильные и атмосферные очертания, полное ощущение технологичности. Уникальный дизайн передней и задней части демонстрирует неповторимый темперамент высокопроизводительной модели. Боковые линии кузова плавные, а колеса большого размера добавляют ощущение спортивности."
  },
  //#endregion

  //#endregion


  //#region 坦克

  //#region 400 Hi4T
  {
    title:"2024款坦克400 Hi4-T越野版",
    codeNumber:"d67b0e6c0e9c48b89abee3b0ed2a5d6c",
    description1:"Tank, как подразделение Great Wall Motor, специализирующееся на автомобилях повышенной проходимости, опирается на богатый опыт в разработке внедорожной техники. Придерживаясь принципа 'интеллектуальное бездорожье, свободные путешествия', бренд нацелен на создание профессиональных внедорожников, сочетающих выдающиеся характеристики на пересеченной местности и современные технологии. Tank 400 Hi4-T Off-road Edition, флагманская гибридная модель бренда, объединяет в себе передовые гибридные решения и внушительный внедорожный потенциал. Этот автомобиль открывает новую страницу в истории гибридных внедорожников, отвечая потребностям любителей экстремального вождения в сложных дорожных условиях. Модель создана для удовлетворения запросов энтузиастов, стремящихся к покорению самого сложного бездорожья.",
    description2:"Версия Tank 400 Hi4-T Off-Road Edition отличается смелым и практичным дизайном кузова, создавая неповторимый образ 'бойца из будущего'. Решетка радиатора с двойными горизонтальными элементами и отдельно стоящей передней панелью, фары с характерными акцентами и углубленный козырек формируют выразительный внешний вид. Габариты транспортного средства составляют: длина - 4985 мм, ширина - 1960 мм, высота - 1905 мм; расстояние между осями - 2850 мм. Прямоугольные и строгие очертания кузова, дополненные колесными арками с заклепками, подчеркивают его внушительный характер.",
    description3:"Внедорожник Tank 400 Hi4-T, построенный на базе гибридной платформы Hi4-T, сочетает в себе 2,0-литровый двигатель с турбонаддувом и 9-ступенчатую автоматическую коробку передач 9HAT. Эта система дополнена электромотором P2 мощностью 120 кВт, образуя двухмоторный привод. Суммарная мощность установки составляет 300 кВт, а крутящий момент достигает 750 Н·м, что обеспечивает разгон до 100 км/ч всего за 6,8 секунды. Аккумуляторная батарея емкостью 37,1 кВт·ч позволяет проехать на электротяге более 105 км в цикле WLTC. Быстрая зарядка позволяет восполнить заряд с 30% до 80% всего за 24 минуты."
  },
  {
    title:"2024款坦克400 Hi4-T城市版",
    codeNumber:"ff35fdcc3edf4e9381e98cd1c2489adc",
    description1:"Tank 400 Hi4-T City Edition, опираясь на наследие внедорожных характеристик бренда, предлагает усовершенствованную комплектацию, адаптированную для городских условий. Этот гибридный внедорожник разработан специально для городских жителей, сочетая в себе функциональность для ежедневных поездок и возможности для преодоления бездорожья. Данное расширение модельного ряда призвано привлечь более широкую аудиторию, предлагая универсальное решение для различных потребностей. Автомобиль представляет собой оптимальный выбор для тех, кто ищет надежный и адаптируемый транспорт, способный удовлетворить как повседневные задачи, так и стремление к приключениям за пределами города.",
    description2:"Внешний дизайн Tank 400 Hi4-T City Edition остался таким же, как и у внедорожной версии в целом. Жесткие линии кузова в сочетании с выразительной формой передней части демонстрируют хардкорный стиль. Крупногабаритный кузов с плавающей крышей добавляет ощущение стиля. Квадратный кузов с клепаными колесными арками и внешним запасным колесом подчеркивает его внедорожный темперамент. Задние фонари, протянувшиеся по всей ширине кузова, характеризуются лаконичной конструкцией и обеспечивают отличную видимость в темное время суток.",
    description3:"Модель Tank 400 Hi4-T City Edition использует аналогичную гибридную систему Hi4-T, что и ее внедорожный аналог. Сочетание двухлитрового турбированного двигателя и электромотора обеспечивает общую производительность в 300 кВт, а также крутящий момент в 750 Н⋅м, гарантируя высокую мощность. Салон отличается премиальным исполнением с сиденьями, отделанными кожей Nappa, которые располагают множеством опций для обеспечения комфорта. Интеллектуальное оснащение включает в себя большой центральный дисплей управления и полностью цифровую приборную панель. Автомобильная система характеризуется многофункциональностью, поддерживая голосовое управление, телематические сервисы, беспроводные обновления и другие современные функции."
  },
  //#endregion

  //#endregion


  //#region 猛士

  //#region 917
  {
    title:"猛士917 纯电版",
    codeNumber:"81b0839fdb13424c8128617dc9ab4e00",
    description1:"M-Hero, премиальный бренд внедорожников, работающий на новых источниках энергии и являющийся частью Dongfeng Motor Group, опирается на технологическое мастерство Dongfeng. Придерживаясь принципа 'Бесстрашное покорение, ведущее в будущее', компания нацелена на создание элитных электрических внедорожников, сочетающих исключительные внедорожные качества и передовые технологические решения. Первая серийная модель бренда, M-Hero 917 в чисто электрической версии, стирает границы между классическими внедорожниками и транспортными средствами на новых источниках энергии, благодаря своим выдающимся технологиям и новаторскому дизайну. Этот автомобиль открывает новую главу в истории электрических внедорожников для поклонников бездорожья и тех, кто стремится к уникальным приключениям в путешествиях.",
    description2:"Электромобиль M-Hero 917 воплощает дизайнерскую философию 'Восточный лев', что отражается в его брутальном и безошибочно узнаваемом облике. Габариты внедорожника составляют: длина – 4987 мм, ширина – 2080 мм, высота – 1935 мм при межосевом расстоянии в 2950 мм. Прямоугольные формы кузова, дополненные выразительными расширителями арок, подчеркивают его внушительный характер. Передняя часть автомобиля выделяется крупной вертикальной решеткой радиатора, напоминающей водопад, и острыми светодиодными фарами. Логотип компании, расположенный в центре решетки, акцентирует внимание и создает сильный визуальный эффект.",
    description3:"M-Hero 917 в полностью электрической версии использует интеллектуальную внедорожную платформу M TECH от Dongfeng. Автомобиль оснащен системой полного привода с четырьмя электромоторами, расположенными спереди и сзади. Суммарная мощность силовой установки превышает 1000 лошадиных сил, достигая максимального значения более 800 кВт. Крутящий момент в пике составляет свыше 16 000 Н・м, что позволяет автомобилю разгоняться до 100 км/ч за 4,2 секунды. Уникальной особенностью является применение двухступенчатой коробки передач с интегрированной блокировкой дифференциала. Данная конструкция обеспечивает стопроцентную механическую блокировку, существенно повышая проходимость в сложных внедорожных условиях."
  },
  {
    title:"猛士917 增程版",
    codeNumber:"7930192d787d4400bff5adc4b14e34cc",
    description1:"M-Hero 917 Extended Range Edition представляет собой дальнейшее развитие концепции M-Hero, направленной на объединение внедорожных возможностей и передовых энергетических решений. В отличие от полностью электрической модели, данная версия оснащена гибридной силовой установкой, увеличивающей запас хода. Это позволяет решить проблему ограниченного запаса хода, с которой сталкиваются пользователи, и повысить надежность при длительных путешествиях по пересеченной местности. Таким образом, M-Hero расширяет свой модельный ряд на рынке внедорожников, предлагая альтернативный источник энергии.",
    description2:"Облик гибридного M-Hero 917 не претерпел изменений по сравнению с полностью электрической моделью. Сохранены строгие очертания кузова, оригинальное оформление передней части и цельные задние фонари, подчеркивающие его внедорожные характеристики. Прямоугольная форма кузова и крупные колеса акцентируют его брутальный стиль, предназначенный для бездорожья. Эффект 'парящей' крыши придает автомобилю современный вид.",
    description3:"M-Hero 917, гибридный автомобиль, использует три электромотора в сочетании с 1,5-литровым турбированным двигателем, обеспечивая общую мощность свыше 800 лошадиных сил. Пиковая выходная мощность превышает 600 кВт, а совокупный крутящий момент достигает более 16 000 Н⋅м, демонстрируя выдающиеся характеристики. Автомобиль оснащен специализированным аккумуляторным блоком повышенной безопасности емкостью 65,8 кВт⋅ч, обеспечивающим запас хода на электротяге до 200 км по циклу CLTC. Общий запас хода гибридной системы превышает 800 км по стандарту CLTC, что позволяет использовать автомобиль как для длительных внедорожных, так и для повседневных поездок. M-Hero 917 построен на базе интеллектуальной платформы M-Hero M TECH, разработанной для эксплуатации в условиях бездорожья. Автомобиль укомплектован тремя дифференциальными блокировками, пневматической подвеской и системой управления задними колесами, включающей режим 'Краб', обеспечивающими превосходные возможности проходимости и маневрирования в сложных условиях."
  },
  //#endregion

  //#endregion


  //#region 比亚迪

  //#region 唐EV
  {
    title:"比亚迪 唐EV 635KM 四驱旗舰型（六座）",
    codeNumber:"23e650c77c1149fda26761dab4b60186",
    description1:"Компания BYD, являясь одним из лидеров в сфере разработки и производства электромобилей, активно стимулирует прогресс и внедрение инноваций в этой области. Это достигается благодаря накопленному опыту и стремлению к новаторству. Модель BYD Tang EV 635KM 4WD Flagship (шестиместная версия) объединяет в себе передовые разработки BYD в области электроэнергетики. Автомобиль демонстрирует превосходные ходовые качества и высокий уровень комфорта, предлагая покупателям современное и эффективное решение для передвижения на электрическом внедорожнике премиум-класса. Этот электромобиль сочетает в себе высокую производительность, большой запас хода и просторный салон, что делает его привлекательным выбором для семейных поездок и повседневного использования.",
    description2:"Электромобиль BYD Tang EV 635KM 4WD Flagship (6 -местный) демонстрирует эволюцию фирменного стиля 'Dragon Face', воплощая мощный и уверенный облик. Массивная радиаторная решетка, интегрированная с выразительными светодиодными фарами, формирует эффектный фронтальный вид. Обтекаемый силуэт кузова дополнен 'парящей' крышей, подчеркивающей современный дизайн. 22-дюймовые легкосплавные диски двухцветного исполнения, акцентированные красными тормозными суппортами, добавляют автомобилю динамичности и спортивного характера.",
    description3:"BYD Tang EV 635KM 4WD в комплектации Flagship (6-местный) использует систему полного привода с двумя электромоторами, расположенными спереди и сзади. Передний мотор развивает пиковую мощность в 180 кВт, а задний – 200 кВт, что в сумме обеспечивает 380 кВт общей максимальной мощности и 700 Н·м крутящего момента. Автомобиль способен разгоняться с 0 до 100 км/ч за 4,4 секунды. Электромобиль комплектуется литий-железо-фосфатной батареей типа «blade» ёмкостью 108,8 кВт·ч, что позволяет ему проезжать до 635 км по циклу CLTC на одной зарядке. Этот запас хода делает автомобиль подходящим как для ежедневных поездок по городу, так и для путешествий на значительные расстояния."
  },
  {
    title:"比亚迪 唐EV 730KM尊享型（六座臻享组合套装）",
    codeNumber:"363dc22a7b7b4aa18bd08467c24a7509",
    description1:"Обновленная модель предлагает увеличенную дальность хода и улучшенные характеристики, отвечая потребностям клиентов, ценящих комфорт и передовые технологии. BYD Tang EV 730KM Premium (6-местный премиум) демонстрирует приверженность компании к инновациям и экологически чистому транспорту, одновременно укрепляя свои позиции в сегменте электромобилей.",
    description2:"BYD Tang EV 730KM Premium (шестиместный премиум) обладает экстерьером, аналогичным флагманской модели 635KM 4WD. В его основе лежит фирменный стиль «Dragon Face», что проявляется в солидной и гармоничной передней части. Плавные линии кузова, внушительные 22-дюймовые диски и сквозные задние фонари подчеркивают его выразительную индивидуальность. Плавающий дизайн крыши и хромированные декоративные планки добавляют облику автомобиля современности и элегантности, формируя целостное впечатление о премиальном статусе. Дизайн сочетает в себе мощь и изысканность, что делает его визуально привлекательным и узнаваемым.",
    description3:"BYD Tang EV 730KM Premium в шестиместном исполнении оснащается электромотором, установленным на задней оси, с выходной мощностью 200 кВт, что обеспечивает плавную передачу мощности. Этот автомобиль комплектуется литий-железо-фосфатным аккумулятором типа 'Blade' емкостью 116 кВт-ч, обеспечивающим запас хода до 730 км по циклу CLTC, тем самым значительно уменьшая опасения относительно дальности поездок на электротяге."
  },
  {
    title:"比亚迪 唐EV 730KM尊享型（7座）",
    codeNumber:"89576d97054445f4b12ba65ce8062c40",
    description1:"BYD Tang EV 730KM Premium, семиместный вариант, разработан для удовлетворения запросов больших семей, ценящих комфортные и длительные поездки. Обладая значительным запасом хода и плавностью управления, этот автомобиль представляет собой более функциональный электромобиль класса SUV. Оптимизированная конфигурация сидений расширяет ассортимент продукции бренда в данном сегменте автомобильного рынка, предлагая потребителям практичное и экологичное решение для семейных путешествий. ",
    description2:"BYD Tang EV 730KM Premium, семиместная версия, визуально соответствует общей стилистике линейки Tang EV. Его передняя часть оформлена в узнаваемом фамильном стиле, кузов характеризуется обтекаемыми линиями, автомобиль оснащен крупными колесами и имеет эффектные задние фонари, что подчеркивает его современный и выразительный облик. Автомобиль обладает следующими габаритами: длина – 4870 мм, ширина – 1950 мм, высота – 1725 мм. Колесная база составляет 2820 мм, что позволяет обеспечить просторный и комфортабельный салон для пассажиров.",
    description3:"BYD Tang EV 730KM Premium в семиместной конфигурации использует одномоторную систему с задним приводом, обеспечиваемую двигателем мощностью 200 кВт, гарантирующим стабильную производительность. Данный электромобиль обладает запасом хода в 730 км, измеренным по циклу CLTC, благодаря установленной литий-железо-фосфатной батарее 'Blade' емкостью 116 кВт/ч. В салоне реализована семиместная планировка по схеме 2+3+2, ориентированная на комфортное размещение пассажиров во время длительных поездок. Сиденья изготовлены из премиальных материалов и предлагают стандартный набор регулировок, а также функции вентиляции и обогрева для повышения удобства."
  },
  {
    title:"比亚迪 唐EV 600KM尊享型（六座臻享组合套装）",
    codeNumber:"32df52330ae8438c8b2221680a5ac749",
    description1:"BYD Tang EV 600KM Premium (шестиместная премиум-версия) разработан, чтобы удовлетворить запросы потребителей, стремящихся к высококлассному опыту путешествий, предлагая комфортное управление и интеллектуальные функции. Данная модель демонстрирует технологическое превосходство и привлекательность продукции BYD в сфере транспортных средств на новых источниках энергии. Автомобиль объединяет передовые разработки и инновационные решения, свойственные бренду BYD.",
    description2:"Электрический кроссовер BYD Tang EV 600KM Premium в шестиместной комплектации сохраняет узнаваемые черты дизайна 'Dragon Face'. Автомобиль отличается внушительной передней частью, характеризующейся резкими очертаниями фар. Плавные линии кузова и эффект 'парящей' крыши, дополненные большими колесными дисками, создают впечатление элегантности и стремительности. Задняя часть автомобиля выполнена в минималистичном стиле, где основную роль играют задние фонари, обеспечивающие высокую узнаваемость в темное время суток. Дизайн экстерьера подчеркивает премиальный статус и современный характер электромобиля.",
    description3:"BYD Tang EV 600KM Premium в шестиместной комплектации предлагает одномоторную систему с задним приводом, где двигатель развивает пиковую мощность в 180 кВт. Данная мощность оптимальна для стандартных условий эксплуатации. Автомобиль укомплектован литий-железо-фосфатной батареей типа 'Blade' емкостью 86,4 кВт-ч, обеспечивающей запас хода на электротяге до 600 км по циклу CLTC. Внутреннее пространство организовано по схеме с шестью посадочными местами, при этом отдельные кресла второго ряда оборудованы электрорегулировкой, системами вентиляции, обогрева и массажа, что значительно повышает уровень комфорта для пассажиров."
  },
  {
    title:"比亚迪 唐EV 600KM尊享版（7座）",
    codeNumber:"6ee0f5c98648490eb0aa2d9ccdb0b7c4",
    description1:"BYD Tang EV 600KM Premium Edition в семиместном исполнении – это выгодный и функциональный электромобиль для больших семей. Благодаря своим практичным характеристикам и просторному салону, он расширяет охват аудитории бренда в сегменте электрических внедорожников. Этот автомобиль представляет собой разумный выбор для тех, кто ценит экономичность и экологичность в сочетании с вместительностью и комфортом.",
    description2:"Облик семиместной версии BYD Tang EV 600KM Premium Edition во многом перекликается с другими представителями линейки Tang EV, что достигается за счет использования общего фирменного стиля, делающего автомобиль легко узнаваемым. Крупная радиаторная решетка гармонично сочетается с заостренными передними фарами, боковые линии кузова отличаются плавностью, а задние фонари имеют лаконичный, но выразительный дизайн, привлекающий внимание к автомобилю.",
    description3:"BYD Tang EV 600KM Premium Edition в семиместной конфигурации использует систему заднего привода с одним электродвигателем, достигающим пиковой мощности в 180 кВт, что гарантирует надежную производительность. Литий-железо-фосфатный аккумулятор емкостью 86,4 кВт/ч обеспечивает запас хода до 600 км. Салон автомобиля спроектирован с учетом размещения семи пассажиров по схеме 2+3+2, что делает его подходящим для больших семей или групп. Сиденья изготовлены с применением высококачественных материалов, обеспечивающих комфорт, и оснащены стандартными функциями регулировки для удобства пассажиров. Автомобиль сочетает в себе простор, практичность и современные электрические технологии."
  },
  //#endregion

  //#endregion


  //#region 红旗

  //#region H9
  {//红旗H9 2022款2.0T 智联 旗悦版  2
    title:"红旗H9 2022款2.0T 智联 旗悦版",
    codeNumber:"a9d0f5fb9ab24a3491b0a2268b150b4c",
    description1:"",
    description2:"",
    description3:""
  },
  {//红旗H9 2023款 2.0T 智联 旗享版  2
    title:"红旗H9 2023款 2.0T 智联 旗享版",
    codeNumber:"107d3dd7063d49438f802dcdf7a33e14",
    description1:"",
    description2:"",
    description3:""
  },
  {//红旗H9 2023款2.0T 智联 旗畅版  2
    title:"红旗H9 2023款2.0T 智联 旗畅版",
    codeNumber:"a9e0ce99912e4ce89cad4744ff70e1ea",
    description1:"",
    description2:"",
    description3:""
  },
  {//红旗H9 2022款3.0T智联旗领四座版  2
    title:"红旗H9 2022款3.0T智联旗领四座版",
    codeNumber:"fbeb02e423c645b6ab71febdb30af224",
    description1:"",
    description2:"",
    description3:""
  },
  {//红旗H9 2022款3.0T智联 旗畅版  2
    title:"红旗H9 2022款3.0T智联 旗畅版",
    codeNumber:"79b10974ae0a41a59e39e541e6ac8c4d",
    description1:"",
    description2:"",
    description3:""
  },
  //#endregion

  //#region E-HS9
  {
    title:"红旗E-HS9 2025款 120kWh旗享六座版",
    codeNumber:"99c09bd1bc8c4eb996329586475d075d",
    description1:"Будучи знаковым именем в китайском автомобилестроении, Hongqi, с его богатой историей и патриотическим значением, неизменно воплощает философию 'новой китайской роскоши и утонченности', стремясь создавать автомобили премиум-класса с бескомпромиссным качеством и выдающимися характеристиками. Шестиместная модификация Hongqi E-HS9 2025 с батареей 120kWh представляет собой вершину мастерства бренда в сегменте люксовых внедорожников на новой энергии, объединяя в себе передовые технологии и эксклюзивный комфорт, демонстрируя глубокое понимание потребностей в высококлассных путешествиях. Этот автомобиль является воплощением стремления бренда к совершенству и инновациям в области электромобильности.",
    description2:"Шестиместный вариант Hongqi E-HS9 2025 с батареей на 120 кВт*ч выполнен в традиционном стиле Hongqi, что придает ему солидный вид. Спереди располагается массивная вертикальная решетка радиатора, напоминающая водопад, и узкие двухэтажные светодиодные фары. Узнаваемая красная полоса тянется через капот, делая автомобиль мгновенно узнаваемым. Габариты кузова составляют 5209 мм в длину, 2010 мм в ширину и 1731 мм в высоту, а колесная база равна 3110 мм. Плавные линии кузова подчеркивают его мощь. Двухцветное оформление и большие 22-дюймовые колеса создают стильный и представительный облик.",
    description3:"Флагманская шестиместная версия Hongqi E-HS9 2025 с батареей 120 кВт/ч использует двухмоторную систему полного привода, где передний и задний двигатели выдают по 160 кВт каждый, обеспечивая суммарный крутящий момент до 600 Н·м. Такая конфигурация обеспечивает плавную и мощную тягу, достаточную как для повседневных поездок, так и для динамичного вождения на высоких скоростях. Благодаря увеличенной емкости батареи до 120 кВт/ч, запас хода по циклу CLTC достигает 690 км, что значительно снижает беспокойство, связанное с недостаточным запасом хода."
  },
  {
    title:"红旗E-HS9 2025款 120kWh旗悦七座版",
    codeNumber:"364d2782d5954c36a2e82fc6f148913b",
    description1:"Семиместный флагман Hongqi E-HS9 2025 с батареей 120 кВтч воплощает неизменную приверженность Hongqi высоким стандартам качества и удобства. Разумные комплектации предлагают просторные и комфортабельные решения для поездок на электротяге для больших семей, расширяя ассортимент электромобилей SUV от этого бренда.",
    description2:"Семиместный флагман Hongqi E-HS9 2025 с батареей 120 кВт·ч визуально идентичен шестиместной модели. Он унаследовал фамильный стиль оформления передней части с вертикальной решеткой радиатора, имитирующей водопад, и оригинальными блоками фар, подчеркивающими внушительный облик. Плавные линии кузова и его солидные размеры создают ощущение роскоши и динамики, которое усиливается 22-дюймовыми дисками. Дизайн задней части, благодаря форме задних фонарей, отличается лаконичностью и элегантностью, а в темное время суток подсветка обеспечивает отличную узнаваемость.",
    description3:"7-местный флагман Hongqi E-HS9 2025 с батареей 120 кВтч обладает системой полного привода, использующей два электромотора, расположенных спереди и сзади. Каждый из этих двигателей развивает мощность 160 кВт и крутящий момент до 600 Нм, гарантируя устойчивую тягу. Запас хода на одной зарядке, согласно стандарту CLTC, составляет 690 км благодаря аккумулятору емкостью 120 кВтч. Внутри автомобиль предлагает семиместную конфигурацию, что позволяет с удобством разместить большую семью или группу путешественников. Отделка салона выполнена с применением премиальных материалов, создающих атмосферу комфорта и уюта для водителя и пассажиров."
  },
  {
    title:"红旗E-HS9 2025款 120kWh 旗畅六座版",
    codeNumber:"2ebe7e3d499d4190a02dff2cbc60c844",
    description1:"Hongqi E-HS9 2025 120kWh  Deluxe в шестиместном исполнении позиционируется как представитель среднего и премиального сегмента в линейке бренда. Автомобиль обладает расширенным набором функций и превосходными характеристиками, что позволяет удовлетворить запросы взыскательных клиентов, стремящихся к высокому уровню комфорта в поездках. Модель наглядно демонстрирует технологическое превосходство и привлекательность продукции Hongqi в сфере электромобилей класса люкс.",
    description2:"Шестиместная версия Hongqi E-HS9 2025 120kWh  Deluxe продолжает семейный атмосферный стиль, с прямой водопадной решеткой радиатора и острыми фарами, демонстрирующими величественность. Линии кузова плавные, а размеры длины, ширины, высоты и колесной базы обеспечивают простор внутри автомобиля. Двухцветный кузов с колесами большого размера, спортивный и роскошный. Уникальный дизайн задних фонарей с выдающимся световым эффектом.",
    description3:"Hongqi E-HS9 2025  Deluxe в шестиместной версии представляет собой значительное обновление в сегменте электрических внедорожников премиум-класса. Силовой агрегат подвергся модернизации, теперь передний двигатель выдает 160kW, а задний – впечатляющие 245kW. Суммарная максимальная мощность достигает 405kW, а максимальный крутящий момент – 750N・m, что обеспечивает превосходную динамику и улучшенные показатели разгона до 100 км/ч. Впечатляющая батарея емкостью 120kWh обеспечивает запас хода по циклу CLTC до 660 км, позволяя совершать длительные поездки без необходимости частой подзарядки. Интерьер Hongqi E-HS9 2025 отделан материалами высочайшего качества, обеспечивая беспрецедентный уровень комфорта для всех пассажиров. Сиденья оснащены функцией массажа, что делает каждую поездку максимально расслабляющей и приятной. "
  },
  {
    title:"红旗E-HS9 2025款 120kWh旗领四座版",
    codeNumber:"e1642b04169b4c518722b7a692eafd71",
    description1:"Hongqi E-HS9 2025 с батареей 120 кВтч в 4-местном исполнении, являясь флагманом модельного ряда, воплощает собой пик развития Hongqi в сегменте премиальных электро-внедорожников. В этой модели гармонично сочетаются исключительная роскошь и передовые технологические решения, формируя уникальный автомобиль для тех, кто ценит бескомпромиссный комфорт в поездках. E-HS9 задаёт новые стандарты для отечественного рынка электромобилей класса люкс.",
    description2:"Hongqi E-HS9 2025 в 4-местном исполнении с батареей на 120 кВтч обладает безошибочно узнаваемым экстерьером. Его передняя часть излучает торжественность и величие, а плавные линии кузова подчеркивают статус флагманской модели. Большой кузов, выделяющийся своим уникальным двухцветным решением и элегантной хромированной отделкой, акцентирует аристократичный характер автомобиля. Дизайн задней части лаконичен и роскошен, а эффектные задние фонари значительно повышают общую узнаваемость автомобиля.",
    description3:"Hongqi E-HS9 2025 с батареей 120 кВт-ч – это мощный 4 - местный полноприводный автомобиль.  Его передний и задний электромоторы, мощностью 160 кВт и 245 кВт соответственно, в сумме выдают 405 кВт и 750 Н-м крутящего момента. Аккумуляторная батарея ёмкостью 120 кВт-ч позволяет проезжать до 660 км по циклу CLTC. Внутреннее пространство этого автомобиля премиум-класса рассчитано на четверых пассажиров. Задний ряд представлен двумя раздельными сиденьями с функциями подогрева, вентиляции и массажа, а также электрическими подставками для ног, обеспечивающими максимальный комфорт."
  },
  {//红旗E-HS9 2022款 改款 510km 旗领版四座  2
    title:"红旗E-HS9 2022款 改款 510km 旗领版四座",
    codeNumber:"00fa604f7a8449ca89621dc36cb06e04",
    description1:"",
    description2:"",
    description3:""
  },
  {//红旗E-HS92022款改款 510km 旗畅版六座  2
    title:"红旗E-HS92022款改款 510km 旗畅版六座",
    codeNumber:"76ca6646660646ea9db1e5f74743e485",
    description1:"",
    description2:"",
    description3:""
  },
  {//红旗E-HS92022款改款 460km 旗享版六座  2
    title:"红旗E-HS92022款改款 460km 旗享版六座",
    codeNumber:"ad06a4323e9b4efcaf3fbfab584850ff",
    description1:"",
    description2:"",
    description3:""
  },
  //#endregion

  //#region H5
  {//红旗H5 2024款 1.5T HEV智联旗韵版  2
    title:"红旗H5 2024款 1.5T HEV智联旗韵版",
    codeNumber:"26473f874a6a424787ac1e69ccd6ac88",
    description1:"",
    description2:"",
    description3:""
  },
  {//红旗H5  2
    title:"红旗H5",
    codeNumber:"7af89a76699c46d8b7de9cb439140d8d",
    description1:"",
    description2:"",
    description3:""
  },
  {//红旗H52024款1.5T HEV 智联旗畅版  2
    title:"红旗H52024款1.5T HEV 智联旗畅版",
    codeNumber:"16f4fca8d30549c1ba9c750dafba36fa",
    description1:"",
    description2:"",
    description3:""
  },
  {//红旗H52023款1.5T HEV 智联旗畅版  2
    title:"红旗H52023款1.5T HEV 智联旗畅版",
    codeNumber:"4755ae160cc84727a95f611c5de1fb62",
    description1:"",
    description2:"",
    description3:""
  },
  {//红旗H52023款1.5T HEV 智联旗领版  2
    title:"红旗H52023款1.5T HEV 智联旗领版",
    codeNumber:"4852fd02a72547c9ac2616f99e8a5fa2",
    description1:"",
    description2:"",
    description3:""
  },
  //#endregion

  //#region EH7
  {
    title:"红旗EH7 600Pro",
    codeNumber:"7ad6565375d24f94828bc09cb8fc3aad",
    description1:"Hongqi EH7 600Pro – значимый вклад Hongqi в сегмент электромобилей, отражающий приверженность марки передовым технологиям и комфорту. Он разработан, чтобы предоставить покупателям первоклассный, интеллектуальный электроседан, идеально подходящий для городских поездок и путешествий на небольшие и средние расстояния. Этот автомобиль демонстрирует инновационный потенциал Hongqi на рынке электрокаров.",
    description2:"Hongqi EH7 600Pro использует новый язык дизайна:кузов характеризуется мягкими, изящными очертаниями, силуэтом в стиле купе и увеличенными колесами, что создает впечатление современности и энергии. Передняя часть выделяется глухой решеткой радиатора, дополненной заостренными светодиодными фарами, а необычная конструкция нижних воздухозаборников добавляет облику атлетичности. Боковые линии автомобиля просты, а скрытые дверные ручки  улучшают аэродинамические показатели.  Задняя часть с тонкими фонарями и небольшим спойлером типа 'ducktail' акцентирует спортивный характер автомобиля.",
    description3:"Hongqi EH7 600Pro использует задний привод и один электромотор, выдающий до 180 кВт мощности. Эта конфигурация обеспечивает мягкое и контролируемое ускорение, идеально подходящее для ежедневных поездок. Аккумуляторная батарея, выполненная по литий-железо-фосфатной технологии и обладающая емкостью 62,66 кВт-ч, позволяет автомобилю проезжать до 600 км по циклу CLTC. Салон автомобиля отделан премиальными материалами, создающими ощущение комфорта и роскоши. Сиденья спроектированы с учетом эргономики и предлагают широкий спектр регулировок для оптимальной поддержки и удобства пассажиров."
  },
  {
    title:"红旗EH7 640 Pro+四驱版",
    codeNumber:"fd4d198e0c9643d08bb3ffcc4de50d5f",
    description1:"Hongqi EH7 в модификации 640Pro с полным приводом – это ответ на запросы тех, кто ищет яркие ощущения от вождения и нуждается в автомобиле, способном справиться с трудными дорожными условиями. Эта модель демонстрирует достижения бренда Hongqi в сфере электромобилей, предлагая пользователям высокую мощность и передовые технологии управления, гарантирующие более захватывающие и безопасные поездки. Версия 4WD призвана удовлетворить потребность в уверенности на дороге и динамичном вождении.",
    description2:"Полноприводная версия Hongqi EH7640Pro визуально перекликается с EH7 600Pro, сохраняя утонченный и изящный облик с выраженными чертами купе. Неординарный дизайн передней части органично сочетается с обтекаемыми линиями силуэта, а крупные колесные диски идеально соответствуют пропорциям кузова, подчеркивая его неповторимую привлекательность. Задние фонари отличаются лаконичным и сдержанным исполнением, что делает автомобиль более узнаваемым.",
    description3:"Полноприводная модификация Hongqi EH7640Pro оснащена двухмоторной системой, где передний электромотор развивает пиковую мощность в 110 кВт, а задний – 180 кВт. Суммарная мощность установки достигает 290 кВт, а максимальный крутящий момент – 600 Нм, что обеспечивает впечатляющую динамику при разгоне до 100 км/ч. Аккумуляторная батарея емкостью 79,02 кВт-ч, выполненная по литий-ионной технологии, обеспечивает запас хода в 640 км по циклу CLTC."
  },
  {
    title:"红旗EH7 690 Pro",
    codeNumber:"bc3e87ffcb474221b56687c0c2b24aa9",
    description1:"Hongqi EH 690Pro разработан с учетом запросов потребителей, которым важен увеличенный запас хода. Сочетая комфортное управление и интеллектуальные системы, автомобиль использует передовые аккумуляторные технологии, чтобы устранить опасения пользователей, связанные с дальностью поездок. Это обеспечивает более безопасное и приятное вождение на электротяге, укрепляя позиции бренда на рынке электромобилей.",
    description2:"Hongqi EH 690Pro, следуя дизайнерским решениям линейки EH7, демонстрирует внушительный и технологичный облик. Отличительной чертой является глухая передняя панель в сочетании с выразительной оптикой головного света. Боковой силуэт характеризуется обтекаемыми линиями, а покатая крыша придает динамичности. Лаконичные задние фонари гармонично завершают экстерьер автомобиля.",
    description3:"Hongqi EH 690Pro использует задний привод с одним двигателем, выдающим пиковую мощность в 180 кВт, обеспечивая плавную и устойчивую подачу энергии. Автомобиль оборудован литий-железо-фосфатным аккумулятором на 82,76 кВт/ч, что позволяет ему проезжать до 690 км по циклу CLTC, идеально подходя для продолжительных путешествий. Внутреннее пространство отличается обтекаемым дизайном кабины, а также применением мягких и приятных на ощупь материалов отделки. Сиденья спроектированы таким образом, чтобы обеспечить оптимальную поддержку и комфортную посадку."
  },
  {
    title:"红旗EH7 760 Pro+四驱版",
    codeNumber:"011bcc27d143476888a1918c597107f5",
    description1:"Hongqi EH7 760Pro + 4WD, будучи флагманской моделью EH7, демонстрирует передовые разработки бренда, гармонично сочетая в себе выдающуюся мощность, увеличенный запас хода и интеллектуальные функции. Этот автомобиль премиум-класса создан для водителей, стремящихся к максимально комфортным и насыщенным поездкам. Он призван укрепить позиции бренда в сегменте электроседанов высокого класса, предлагая пользователям универсальное решение, отвечающее самым высоким требованиям к качеству и технологичности.",
    description2:"Hongqi EH7 760Pro + 4WD Edition обладает эффектным и динамичным обликом. Обтекаемый силуэт кузова, крупные диски и оригинальное оформление передней части создают мощный визуальный эффект. Задние фонари обеспечивают отличную видимость в темное время суток, а общий дизайн акцентирует внимание на премиальном уровне автомобиля.",
    description3:"Полноприводная модификация Hongqi EH7 760Pro+ использует двухмоторную систему: 110 кВт спереди и 180 кВт сзади. Суммарная отдача силовой установки достигает впечатляющих 290 кВт, а пиковый крутящий момент – 600 Нм, обеспечивая высокую динамику. Автомобиль укомплектован тройной литий-ионной батареей емкостью 100,45 кВт·ч, что позволяет ему преодолевать до 760 километров по циклу CLTC без подзарядки. Такой запас хода делает электромобиль привлекательным для дальних поездок."
  },
  {
    title:"红旗EH7 820 Pro",
    codeNumber:"fe348f8d7ecc46f7b02a220c52c5162f",
    description1:"Hongqi EH7 820Pro делает ставку на внушительный запас хода, как на ключевое преимущество, чтобы соответствовать требованиям тех, кто часто отправляется в дальние поездки. При этом автомобиль остается верен высоким стандартам качества и технологичности, характерным для бренда. Цель – предоставить потребителям надежный, удобный и интеллектуальный электромобиль для путешествий, устанавливая новый ориентир по дальности хода в сегменте электрических седанов.",
    description2:"Дизайн экстерьера Hongqi EH7 820Pro выдержан в изящном стиле, характерном для линейки Hongqi EH7, с лаконичными и внушительными общими формами. Передняя часть отличается глухой решеткой радиатора и оригинальными блоками фар, подчеркивающими современный технологичный вид. Плавные и сбалансированные боковые линии в сочетании с большими колесами добавляют спортивности. Задняя часть оснащена лаконичными, но эффектными сквозными фонарями, которые делают автомобиль более узнаваемым.",
    description3:"Hongqi EH7 820Pro использует систему с одним электромотором на задней оси, обеспечивающим пиковую мощность в 180 кВт и мягкий, контролируемый разгон. Автомобиль комплектуется литий-железо-фосфатным аккумулятором высокой плотности, точные характеристики которого производитель не сообщает. Тем не менее, благодаря современной системе управления энергопотреблением, достигается внушительный запас хода по циклу CLTC, составляющий 820 км. Салон машины отделан премиальными материалами, создающими атмосферу комфорта и роскоши. Сиденья отличаются эргономичной формой и оснащены функциями обогрева и вентиляции, обеспечивая максимальный комфорт в любое время года."
  },
  //#endregion

  //#endregion


  //#region 智己

  //#region LS6
  {
    title:"智己 LS6 超强性能灵蜥智享版",
    codeNumber:"cff3625fea3a4e98b7680584d4989953",
    description1:"Компания IM Auto ставит перед собой цель – внедрение инновационных технологий в процесс создания автомобилей, чтобы обеспечить потребителям незабываемые ощущения от каждой поездки. Модель IM LS6 Ultra Performance Lizard Intelligent Edition является воплощением стремления компании к сочетанию высокой мощности и интеллектуальных возможностей. Благодаря своей исключительной комплектации и передовым технологиям, она задает новые стандарты в сегменте интеллектуальных полностью электрических внедорожников.",
    description2:"Очертания корпуса отличаются мягкими и внушительными линиями, формируя своеобразный силуэт задней части, сочетающий современный дизайн и спортивный характер. Заостренная передняя часть привлекает внимание оригинальными блоками фар, подчеркивающими технологичность. Отсутствие рамок у дверей не только придает автомобилю изысканный внешний вид, но и создает ощущение торжественности при входе в салон.",
    description3:"IM LS6 Ultra Performance Lizard Intelligent Edition Автомобиль оснащен системой полного привода с двумя моторами спереди и сзади, общей мощностью 579 кВт, суммарным максимальным крутящим моментом 800 Н・м, разгоняется с нуля до сотни всего за 3,48 секунды, а максимальная скорость составляет 305 километров в час.  Благодаря аккумуляторной батарее увеличенной мощности в 100 кВт/ч, запас хода CLTC достигает 750 километров, что значительно снижает опасения, связанные с недостаточным запасом хода. Пассажирское сиденье с углом наклона 121°, спроектированное для создания эффекта нулевой гравитации, обеспечивает повышенный комфорт во время поездки."
  },
  {
    title:"智己 LS6 超长续航灵蜥智驾版",
    codeNumber:"0853d442f71f4466905e192db9fd1aec",
    description1:"IM LS6 Ultra Long Range Lizard Intelligent Driving Edition демонстрирует дальнейшие шаги компании в развитии интеллектуальных транспортных решений. Этот автомобиль не только обеспечивает продвинутое интеллектуальное управление, но и предлагает улучшенную батарею и другие компоненты, нацеленные на увеличение запаса хода. Таким образом, решается проблема ограниченного пробега, предоставляя пользователям более комфортное и надежное средство передвижения.",
    description2:"IM LS6 Ultra Long Range Lizard Intelligent Driving Edition Внешний вид автомобиля является продолжением дизайнерской концепции семейства IM, а значительные габариты кузова подчеркивают представительный облик среднеразмерного SUV. Сбалансированные пропорции кузова и ниспадающая от средней стойки линия крыши создают изящный силуэт, визуально смещенный назад. Оригинальная конструкция задних фонарей, объединенных в единую полосу, обеспечивает высокую узнаваемость в темное время суток.",
    description3:"IM LS6 Ultra Long Range Lizard Intelligen Driving Edition Аккумуляторная батарея емкостью 100 кВт/ч обеспечивает электромобилю запас хода до 802 км по циклу CLTC, что делает его подходящим для длительных путешествий. Передовая система интеллектуального вождения IM AD 3.0, использующая лидар высокой точности с большим радиусом действия и процессор NVIDIA Orin X®, предоставляет расширенные возможности. Комплексная система поддерживает функцию навигации NOA без необходимости использования карт города, обеспечивая безопасное и комфортное вождение с интеллектуальной поддержкой в различных дорожных условиях. Это позволяет водителю и пассажирам наслаждаться поездкой, будучи уверенными в надежности и интеллектуальных возможностях автомобиля."
  },
  {
    title:"智己 LS6 长续航灵蜥智享版",
    codeNumber:"6e2d74631b1045629bc66532014d8db9",
    description1:"Используя современные технологии и новаторский подход к дизайну, компания IM Auto постоянно расширяет границы классического автомобилестроения, стремясь к формированию интеллектуальной, экологически чистой и ориентированной на человека транспортной среды для потребителей.",
    description2:"IM LS6 Long Range Lizard Intelligent Edition. Длина, ширина и высота автомобиля составляют 4904/1988/1669 мм, а колесная база - 2950 мм, что обеспечивает просторный салон. Усовершенствованная система освещения, включающая передние фары, способна в автоматическом режиме минимизировать участки с ограниченной видимостью, что способствует повышению уровня безопасности при управлении транспортным средством в темное время суток. Задние фонари, оснащенные интеллектуальной системой, могут передавать визуальные сигналы, отражающие определенные состояния или режимы работы автомобиля, что придает транспортному средству индивидуальность и выразительность.",
    description3:"IM LS6  Long Range Lizard Intelligent Edition. Автомобиль, оснащенный батареей мощностью 83 кВт/ч, имеет запас хода по циклу CLTC в 701 км, что вполне достаточно как для ежедневных поездок на работу, так и для непродолжительных путешествий. Интерьер, выполненный в светлых тонах с элементами итальянского дизайна, создает атмосферу роскоши и комфорта во время вождения. Передние сиденья оборудованы функциями массажа, подогрева и вентиляции, обеспечивая индивидуальный комфорт для каждого пассажира. Просторный задний ряд с ровным полом и регулируемой в 10 положениях спинкой гарантирует комфортную поездку."
  },
  {
    title:"智己 LS6 长续航灵蜥智驾版",
    codeNumber:"eafd658e6d554320b86dfe3f3ddcfe6a",
    description1:"Будучи новым игроком на рынке интеллектуальных транспортных средств, компания IM Auto демонстрирует мощь своих технических ресурсов и инновационного подхода, последовательно представляя конкурентоспособные продукты. Цель IM Auto – предоставить пользователям уникальный опыт интеллектуальных поездок, превосходящий существующие решения.",
    description2:"IM LS6 Ultra Long Range Lizard Intelligen Driving Edition Используя актуальный и энергичный дизайн, силуэт автомобиля отличается лаконичностью и плавностью линий, акцентируя чувство изысканности и силы. Оригинальная передняя часть с выразительными фарами обеспечивает машине запоминающийся внешний вид. Интегрированные дверные ручки не только улучшают эстетику боковой поверхности, делая ее более обтекаемой, но и способствуют снижению аэродинамического сопротивления.",
    description3:"IM LS6 Ultra Long Range Lizard Intelligen Driving Edition. Аккумуляторная батарея емкостью 83 кВт/ч обеспечивает электромобилю запас хода до 701 км по циклу CLTC, что делает его подходящим для длительных путешествий. Передовая система интеллектуального вождения IM AD 3.0, использующая лидар высокой точности с большим радиусом действия и процессор NVIDIA Orin X®, предоставляет расширенные возможности. Комплексная система поддерживает функцию навигации NOA без необходимости использования карт города, обеспечивая безопасное и комфортное вождение с интеллектуальной поддержкой в различных дорожных условиях. Это позволяет водителю и пассажирам наслаждаться поездкой, будучи уверенными в надежности и интеллектуальных возможностях автомобиля. Цифровое шасси Lizard обеспечивает точное управление, а четырёхколёсная система рулевого управления делает автомобиль манёвренным."
  },
  //#endregion

  //#endregion


  //#region 合创

  //#region V09
  {
    title:"合创V09 豪华版",
    codeNumber:"05946c642c694fda9d5dd3893eb3b292",
    description1:"HYCAN - совместное предприятие концерна GAC и фирмы Nio, специализирующееся на исследованиях, разработке и производстве чисто электрических автомобилей, в линейке которых представлены седаны, внедорожники, минивэны и т.д. HYCAN V09 Deluxe - важная модель в сегменте минивэнов, сочетающая в себе передовые технологии и комфортное вождение, призвана создать высококачественное чисто электрическое решение для семейных и деловых поездок.",
    description2:"HYCAN V09 в комплектации Deluxe Edition – это необычное сочетание эксклюзивного оформления и передовых решений, выходящее за рамки привычного восприятия минивэнов. Полностью глухая передняя панель радиатора ясно демонстрирует его электрическую природу, объединяющую элегантность и экономию энергии. Задняя часть автомобиля привлекает внимание цельной линией задних фонарей, а оригинальная кнопка для открытия багажника, расположенная слева от заднего фонаря, оборудована сенсорным механизмом, обеспечивающим комфортное и современное использование.",
    description3:"HYCAN V09 Deluxe Edition создан на основе глобальной чисто электрической архитектуры H-GEA HYCAN и является первым в мире автомобилем, который в стандартной комплектации оснащен системой высокого напряжения 800 В, поддерживающей супербыструю зарядку 4C. Оснащенный двигателем мощностью 202 кВт с пиковым крутящим моментом 335 Н・м, обладает высокой мощностью и легко справляется с повседневными задачами. Запас хода по циклу CLTC составляет 620 км, что соответствует потребностям ежедневных поездок, а также поездок на дальние расстояния."
  },
  {
    title:"合创V09 尊荣版",
    codeNumber:"0ef85b09aff44af3b5b28a3b7d79eda0",
    description1:"HYCAN V09 в комплектации Premium Edition расширяет возможности версии Deluxe, укрепляя свои позиции в премиальном сегменте электрических минивэнов. Цель – предложить оптимальный выбор для потребителей, стремящихся к повышенному комфорту в поездках, и укрепить позиции бренда на рынке.",
    description2:"Премиальная версия HYCAN V09 унаследовала экстерьер от комплектации Deluxe, сохранив фирменную глухую решетку радиатора, обтекаемый силуэт и хромированные акценты, подчеркивающие элегантность и премиальность. Внушительная ширина кузова гарантирует просторный салон, а оригинальная конструкция задних фонарей делает автомобиль легко узнаваемым в темное время суток. Сенсорное управление задними дверями добавляет удобства и практичности.",
    description3:"В версии HYCAN V09 Premium Edition силовая установка аналогична комплектации Deluxe Edition, базируясь на высоковольтной архитектуре 800 В и технологии сверхбыстрой зарядки 4C, гарантирующей эффективное энергоснабжение и быструю подзарядку. Конфигурация сидений получила улучшенные материалы и качество исполнения, что позволило значительно повысить комфорт во время поездок. Пассажирам второго ряда предлагается новый 17,3-дюймовый потолочный экран 2k+ со сверхвысоким разрешением, интегрированная система Tencent Conference с поддержкой сети 5G и индивидуальными микрофонами, что позволяет проводить телеконференции в любом месте и в любое время, особенно актуально для деловых поездок."
  },
  {
    title:"合创V09 旗舰版",
    codeNumber:"c3d45509abe34bea86ee635dc7ba2473",
    description1:"Флагманская версия HYCAN V09, представляющая собой вершину линейки V09, сочетает в себе самые современные технологии и эксклюзивную комплектацию, отражая стремление HYCAN к созданию премиальных электромобилей MPV. Эта модель разработана для обеспечения всеобъемлющего опыта вождения высокого класса для тех, кто ищет максимум от своих путешествий, и устанавливает новый стандарт для бренда в сегменте электрических MPV.",
    description2:"Флагманская версия HYCAN V09 демонстрирует преемственность дизайнерских решений, характерных для всей линейки HYCAN V09, выделяясь элегантностью, премиальностью и запоминающимся обликом. Габариты кузова гарантируют вместительность, силуэт отличается плавностью линий, а хромированные элементы отделки акцентируют внимание на высоком уровне исполнения. Дизайн задних фонарей лаконичен и практичен.",
    description3:"Флагманская версия HYCAN V09 претерпела кардинальную переработку в части производительности и систем автономного управления. Автомобиль базируется на электроархитектуре H-EGA и штатно комплектуется 800-вольтовой высоковольтной SiC платформой на основе карбида кремния. Кроме того, машина получила интеллектуальную систему управления H-VIP 3.0, в основе которой лежит интегрированный контроллер, отвечающий за вождение и парковку. Он использует высокопроизводительный AI-чип и 23 сенсора премиум-класса, чтобы обеспечить до 37 интеллектуальных функций помощи водителю ADAS, а также возможности автопилота NOA."
  },
  //#endregion

  //#endregion


  //#region 魏牌

  //#region 高山
  {
    title:"魏牌高山 四驱尊贵版",
    codeNumber:"d6d8e87d94034b8dae86d8cc29282a17",
    description1:"Wei, будучи премиальным суббрендом Great Wall Motor, неуклонно следует философии 'сделать люкс достижимым', гармонично объединяя передовые технологические решения с проверенными временем методами автомобилестроения. Модель Wei Alpine 4WD Premium Edition, представляющая собой вершину инженерной мысли в сегменте минивэнов, является очередным подтверждением приверженности бренда к безупречному качеству и высокой эффективности, предоставляя удобные и интеллектуальные решения для семейных путешествий и бизнес-поездок. Этот автомобиль воплощает в себе стремление Wei к созданию комфортабельного и технологичного пространства для своих клиентов.",
    description2:"Great Wall Wei Alpine 4WD Premium Edition демонстрирует дизайнерскую концепцию 'Небесного Купола', что придает облику автомобиля солидность и уверенность. Габариты этого внедорожника составляют 5045 мм в длину, 1960 мм в ширину и 1880 мм в высоту, а расстояние между осями равно 3085 мм, обеспечивая тем самым обширное внутреннее пространство. Отличительной чертой является массивная хромированная решетка радиатора, эффектно сочетающаяся с выразительными светодиодными фарами.",
    description3:"Wei Alpine 4WD Premium Edition от Great Wall Автомобиль оснащен подключаемой гибридной силовой установкой, состоящей из 1,5-литрового двигателя с высокой тепловой эффективностью и мотора P4 на задней оси, суммарной мощностью 358 кВт, суммарным крутящим моментом 762 Н・м и мощным разгоном от нуля до сотни всего за 5,7 секунды. Запас хода на чистом электричестве достигает 210 км, а комбинированный запас хода - 1200 км, что позволяет удовлетворить различные потребности в путешествиях. Интерьер автомобиля имеет шестиместную компоновку 2+2+2, а сиденья выполнены из высококачественной кожи Nappa, мягкие и удобные, поддерживают функции разнонаправленной электрорегулировки, вентиляции, подогрева и массажа."
  },
  {
    title:"魏牌高山 四驱旗舰版",
    codeNumber:"b897d695194d417c9720fba5b22d8ed8",
    description1:"Топовая модификация Wei Alpine 4WD от Great Wall являет собой кульминацию линейки Wei Alpine, интегрируя в себе самые современные технологии и элитную отделку, нацеленную на обеспечение потребителя непревзойденным уровнем комфорта. Этот автомобиль создан, чтобы соответствовать запросам самых требовательных любителей путешествий, и предстает как многофункциональное решение для комфортабельных и роскошных поездок. Данный флагман призван усилить позиции марки Wei в категории премиальных минивэнов, предлагая бескомпромиссный альянс инноваций и изысканности.",
    description2:"Внешний вид флагманской версии Wei Alpine 4WD не претерпел изменений по сравнению с комплектацией 4WD Premium Edition. Сохранились солидные и гармоничные очертания кузова, эффектная передняя часть и выразительные задние фонари, подчеркивающие элегантность и престиж этого минивэна. Габариты автомобиля остались прежними: длина – 5045 мм, ширина – 1960 мм, высота – 1880 мм. Колесная база в 3085 мм гарантирует большой запас пространства в салоне.",
    description3:"Топовая версия Wei Alpine с приводом на все колеса укомплектована аналогичной силовой установкой, что и вариант 4WD Premium Edition. Это гибридный автомобиль с возможностью подзарядки от сети, использующий 1,5-литровый турбодвигатель с оптимизированной тепловой отдачей и электрический двигатель P4, расположенный на задней оси. Такое сочетание обеспечивает высокую производительность и солидный запас хода. В салоне произошли заметные изменения к лучшему. В частности, в отделке сидений применяется кожа Nappa повышенного качества, а задние кресла получили функцию 'невесомости'. Они отличаются расширенными возможностями регулировки и более богатым набором опций для обеспечения комфорта, включая возможность активации положения 'нулевой гравитации' одним нажатием и функцию поясничного массажа. Помимо этого, пассажиры, сидящие сзади, могут воспользоваться новым 27-дюймовым экраном развлекательной системы, интегрированным в потолок, для приятного времяпрепровождения в пути."
  },
  //#endregion

  //#endregion


  //#region 极越

  //#region 01
  {
    title:"极越01 Max",
    codeNumber:"7878db4a0e5348baaf6842a6cb80e319",
    description1:"Jiyue, совместное детище Baidu и Geely Holding Group, представляет собой премиальный бренд интеллектуальных роботизированных автомобилей, стремящийся к гармоничному сочетанию передовых технологий и мастерства в производстве. Jiyue 01 Max, ключевая модель в линейке Jiyue 01, демонстрирует достижения бренда в сфере интеллектуальной мобильности благодаря своей впечатляющей интеллектуальной начинке и исключительным характеристикам. Автомобиль предлагает пользователям опыт вождения, значительно превосходящий возможности обычных транспортных средств.",
    description2:"Внешний вид Jiyue 01 Max отличается динамичностью и технологичностью, с обтекаемыми и органичными формами кузова, создающими футуристическое впечатление. Передняя часть выделяется оригинальной заглушенной решеткой радиатора, которая гармонично сочетается с выразительными светодиодными фарами, обеспечивая высокую узнаваемость. Боковой силуэт автомобиля лаконичен, а конструкция скрытых дверных ручек не только улучшает внешний вид, но и уменьшает аэродинамическое сопротивление.",
    description3:"Jiyue 01 Max использует архитектуру Geely SEA (Sustainable Experience Architecture) и оборудован двумя процессорами NVIDIADRIVE Orin. Общая производительность его системы искусственного интеллекта достигает 508 TOPS, а для интеллектуального вождения задействовано 28 сенсоров, в том числе 11 HD-камер (7 с разрешением 8 Мп), 12 ультразвуковых радаров и 5 радаров миллиметрового диапазона. Благодаря этому, автомобиль может предложить современные интеллектуальные функции вождения, например, автоматическое управление на автомагистралях и помощь при вождении в городских условиях с использованием навигационных данных."
  },
  {
    title:"极越01 Max 长续航版",
    codeNumber:"1765b5d5628549d4a499e9720fa70732",
    description1:"Версия Jiyue 01 Max Long Range Edition остается верна философии бренда, делая акцент на интеллектуальных функциях и увеличенном запасе хода. Взяв за основу модель Jiyue 01 Max и усовершенствовав аккумуляторную систему, она устраняет опасения пользователей относительно дальности поездок. Это позволяет удовлетворить как ежедневные потребности в передвижении, так и запросы на более продолжительные путешествия, что, в свою очередь, укрепляет позиции продукта на рынке. Jiyue 01 Max Long Range Edition, таким образом, еще больше подчеркивает конкурентоспособность электромобилей Jiyue.",
    description2:"Внешний дизайн Jiyue 01 Max Long Range Edition такой же, как у Jiyue 01 Max, с плавными линиями кузова, уникальной передней частью и пронизывающими задними фонарями, демонстрирующими чувство технологии и стиля. Размеры автомобиля также составляют 4853/1990/1611 мм, а колесная база - 2999 мм, что обеспечивает просторный и комфортный салон.",
    description3:"Jiyue 01 Max Long Range Edition повторяет Jiyue 01 Max в части интеллектуальных возможностей и стандартной комплектации, включая два чипа NVIDIADRIVE Orin и обширный набор датчиков для автономного вождения, обеспечивающих высокий уровень интеллекта. Ключевое отличие – в силовой установке и аккумуляторе. Эта версия оснащена одним электромотором, приводящим в движение задние колеса, с пиковой мощностью 200 кВт и крутящим моментом в 343 Нм. Разгон до 100 км/ч, по данным производителя, занимает 6,9 секунды."
  },
  {
    title:"极越01 Max性能版",
    codeNumber:"9a7c0063573d43e79819120d5080d5e7",
    description1:"Версия Max Performance Edition, как топовая комплектация Jiyue 01, объединяет в себе инновационные интеллектуальные технологии и выдающиеся характеристики мощности, создавая оптимальное водительское пространство для любителей скорости и энергичного вождения. Автомобиль, сфокусированный на интеллектуальных возможностях, подчеркивает лидерство Jiyue в области производительности и укрепляет позиции бренда.",
    description2:"Модель Jiyue 01 Max Performance Edition придерживается общей стилистики линейки Jiyue 01, акцентируя внимание на динамике и современных технологиях. Плавные контуры кузова и своеобразная передняя часть с заостренными фарами создают эффектный визуальный образ. Задние фонари обладают запоминающимся дизайном, особенно в темное время суток.",
    description3:"В плане интеллектуальных возможностей вождения, Jiyue 01 Max Performance Edition обладает передовой системой помощи водителю, благодаря двум чипам NVIDIA DRIVE Orin и комплекту из 28 датчиков. Его силовая установка представлена двухмоторной системой полного привода, развивающей общую мощность двигателей в 400 кВт, что эквивалентно 544 лошадиным силам. Пиковый крутящий момент достигает 686 Нм. Официальное время разгона с места до 100 км/ч составляет впечатляющие 3,8 секунды, демонстрируя превосходную динамику автомобиля."
  },
  //#endregion

  //#endregion


  //#region 方程豹

  //#region 豹5
  {
    title:"方程豹 豹5 探索版",
    codeNumber:"b569916362604dcca08af873b7e70126",
    description1:"Fangchengbao, дочерняя марка BYD, ориентированная на выпуск кастомизированных электромобилей, ставит своей целью удовлетворение уникальных запросов потребителей, используя передовые технологии в области новой энергии. Leopard 5 Explorer Edition, выступающая в качестве базовой комплектации дебютной модели Leopard 5, предлагает передовые технологии и впечатляющие внедорожные качества бренда по привлекательной цене, открывая новые горизонты для персонализированных приключений на бездорожье.",
    description2:"Leopard 5 Explorer выделяется своим угловатым и брутальным дизайном, типичным для бескомпромиссных внедорожников. Габариты автомобиля составляют 4890 мм в длину, 1970 мм в ширину и 1920 мм в высоту, при колесной базе 2800 мм. Прямые линии кузова акцентируют его суровый характер, а массивные колесные арки с большими колесами подчеркивают внедорожную сущность. Задняя часть автомобиля выполнена в лаконичном стиле, а оригинальные проникающие задние фонари создают неповторимый световой рисунок, повышая безопасность и узнаваемость в темное время суток.",
    description3:"Leopard 5 Explorer Edition использует передовую гибридную внедорожную архитектуру DMO от BYD. Автомобиль оснащен 1.5-литровым продольным двигателем Snapdragon с турбонаддувом и системой полного привода, реализованной посредством двух электромоторов, расположенных спереди и сзади. Суммарная мощность силовой установки достигает 505 кВт, а пиковый крутящий момент – 760 Нм. Разгон до 100 км/ч занимает всего 4.8 секунды, что свидетельствует о высокой динамике. Запас хода на электротяге составляет 125 км по циклу CLTC, а общий запас хода при смешанном режиме достигает 1200 км при экономичном расходе топлива – 7.8 л на 100 км. Салон отделан премиальной кожей. Несмотря на отсутствие развлекательного экрана для пассажиров и расширенных функций комфорта, автомобиль полностью удовлетворяет основные потребности водителя."
  },
  {
    title:"方程豹 豹5 领航版",
    codeNumber:"ef90ddff7be54b73a5c84407c2097522",
    description1:"Leopard 5 Pilot Edition – это продолжение курса Fangchengbao на персонализацию и максимальную отдачу, дополненное улучшенной комплектацией, основанной на версии Explorer Edition. Он представляет собой оптимальное решение для потребителей, ценящих премиальное качество и инновационные технологии, предпочитающих семейные выезды на природу, а также расширяет модельный ряд бренда, предлагая больше возможностей выбора.",
    description2:"Внешний вид автомобиля вторит стилистике Explorer Edition, где угловатые и строгие формы кузова, массивная решетка радиатора и резкие фары формируют внушительный облик. Боковые панели и задняя часть кузова отличаются лаконичностью и солидностью, а выразительный дизайн задних фонарей делает автомобиль более узнаваемым и добавляет ему современности.",
    description3:"Leopard 5 Pilot Edition, разделяющий силовую установку с Explorer Edition и базирующийся на платформе DMO, выделяется не только высокой производительностью, но и внушительным запасом хода. Комфорт пассажиров поднят на новый уровень благодаря сиденьям, обитым кожей Nappa, функции массажа в передних креслах и встроенным в подголовник динамикам. Впечатляющая 50-дюймовая проекционная система HUD, дополненная 12,3-дюймовым экраном для развлечения задних пассажиров и автомобильным холодильником, обеспечивают превосходное развлечение и комфорт в поездках."
  },
  {
    title:"方程豹 豹5 云辇豪华版",
    codeNumber:"d13cdf36ebb94327bef7485b5fabafbd",
    description1:"Leopard 5 Yunnian Deluxe Edition создан для водителей, жаждущих непревзойденного комфорта за рулем. Он использует передовые разработки бренда, чтобы обеспечить исключительные ощущения. Автомобиль оснащен инновационной гидравлической подвеской Cloud Chariot - P, что подчеркивает технологическое лидерство Fangchengbao в разработке и производстве.",
    description2:"Сохраняя брутальный дух линейки Leopard 5, экстерьер отличается бескомпромиссным стилем с характерными угловатыми очертаниями. Уникальность передней части подчеркнута массивной радиаторной решеткой в сочетании с агрессивной оптикой, акцентирующей внушительность автомобиля. Боковые поверхности кузова отличаются плавными линиями, визуально демонстрируя мощь, а лаконичная и запоминающаяся конструкция задних фонарей завершает образ.",
    description3:"Leopard 5 Yunnian Deluxe Edition схож с другими моделями в плане производительности и основных интеллектуальных возможностей. Автомобиль построен на базе супергибридной внедорожной платформы DMO, гарантирующей высокую мощность и внушительный запас хода. Основные изменения коснулись комплектации: отсутствует дисплей для развлечения задних пассажиров, электропривод двери багажника и 50-дюймовый проекционный экран. Шасси было улучшено до версии Cloud Chariot-P с гидравлической подвеской, аналогичной той, что используется в U8."
  },
  {
    title:"方程豹 豹5 云辇旗舰版",
    codeNumber:"1ef514ea85e74125a258a48f51e6a5d3",
    description1:"Leopard 5 Yunnian Signature Edition, представляя собой флагманскую модификацию Leopard 5, интегрирует в себе передовые технологии и уникальную комплектацию, характерные для Fangchengbao. Автомобиль нацелен на взыскательных клиентов, ценящих выдающиеся впечатления от вождения и обладающих соответствующими финансовыми возможностями. Эта модель формирует премиальный имидж бренда и подчеркивает стремление компании к созданию персонализированных внедорожников, работающих на новой энергии.",
    description2:"Leopard 5 Yunnian Signature Edition сохраняет узнаваемый внешний вид, характерный и для других моделей этой серии. Его кузов отличается угловатыми формами и брутальным дизайном. Специфическая конструкция передней части и выразительные задние фонари безошибочно идентифицируют автомобиль и акцентируют его внедорожный потенциал. Дизайн подчеркивает его бескомпромиссный характер и готовность к сложным дорожным условиям.",
    description3:"Leopard 5 Yunnian Signature Edition – это впечатляющий автомобиль, построенный на платформе DMO, сочетающий в себе значительную мощность и внушительный запас хода. Особое внимание уделено улучшению комфорта и внедрению интеллектуальных возможностей. Салон отличается замшевой отделкой сидений, а пассажирам второго ряда доступны функции вентиляции и обогрева. Удобство использования повышается благодаря наличию цифрового ключа UWB, потокового внутреннего зеркала и беспроводной зарядки для двух смартфонов в передней части салона. Автомобиль оборудован гидравлической подвеской Cloud Chariot-P с возможностью хода в 140 мм и многоступенчатой регулировкой амортизации, что значительно повышает комфорт при вождении и улучшает внедорожные характеристики."
  },
  //#endregion

  //#endregion


  //#region 极石

  //#region 01
  {//极石01 长续航7座版  2
    title:"极石01 长续航7座版",
    codeNumber:"4a19a51d88fe474286e3141e72206082",
    description1:"",
    description2:"",
    description3:""
  },
  {
    title:"极石 01 长续航 6座版",
    codeNumber:"b1abab7dd7664cc580fc76ae0d3d949b",
    description1:"6-местный Polar Stone 01 Long Range оформлен в строгом стиле, демонстрируя свой жесткий характер. Большая решетка радиатора с характерными Y-образными кластерами фар с обеих сторон, толстая черная окантовка по краю решетки и логотип бренда в новом стиле усиливают визуальный эффект жесткости автомобиля.",
    description2:"Внешне автомобиль, подобно своему семиместному аналогу, обладает строгим и внушительным силуэтом, подчёркнутым плавными линиями кузова. Массивная радиаторная решётка в сочетании с заострёнными фарами акцентирует властный характер машины. Боковой и задний дизайн кузова отличается лаконичностью и элегантностью, а сквозные задние фонари не только делают автомобиль более узнаваемым, но и придают задней части более сложную структуру.",
    description3:"Силовая установка шестиместного Polar Stone 01 Long Range, аналогично семиместной модификации, включает 1.5-литровый турбированный двигатель и два электромотора, образующих подключаемый гибрид. На одной электротяге автомобиль способен проехать до 210 км, а общий запас хода достигает 1 115 км. Салон организован по схеме 2+2+2, предлагая два отдельных кресла во втором ряду. Эти кресла оснащены электрорегулируемыми подставками для ног, функциями подогрева, вентиляции и массажа, обеспечивая исключительный комфорт пассажирам. Пространство для ног и над головой в третьем ряду также отличается достаточным объемом."
  },
  {
    title:"极石 01 标准续航 7座版",
    codeNumber:"1ab5dfa9dc9642b0a97e22799da41ada",
    description1:"Семиместная версия Polar Stone 01 Standard Range создана для ежедневных семейных поездок, предлагая более доступный вариант благодаря продуманной оптимизации комплектации. Сохраняя функциональность, она позволяет большему числу семей ощутить комфорт и качество путешествий на автомобилях Polar Stone.",
    description2:"Внешний вид этой модели выдержан в стилистике линейки Polar Stone 01, характеризующейся угловатыми формами и выразительным оформлением передней части, излучающим силу. Массивная радиаторная решетка и заостренные фары создают единый образ, делая автомобиль легко узнаваемым. Боковые панели кузова отличаются плавностью линий, а задняя часть с цельными фонарями выполнена в лаконичном и элегантном стиле, что придает автомобилю современный вид.",
    description3:"Семиместный Polar Stone 01 в комплектации со стандартным запасом хода использует гибридную систему, включающую 1,5-литровый четырехцилиндровый двигатель, выступающий в роли генератора, и пару электрических моторов. Пробег на электротяге достигает 160 км, а суммарный – 860 км, что вполне достаточно для ежедневных поездок по городу и небольших путешествий. Интеллектуальное оснащение автомобиля представлено крупным 15,6-дюймовым центральным дисплеем, объединяющим множество автомобильных функций для удобного управления. Помимо этого, предусмотрены полезные системы помощи водителю, такие как камера заднего вида и круиз-контроль, повышающие комфорт и безопасность вождения."
  },
  {
    title:"极石 01 标准续航 6座版",
    codeNumber:"6a85ab28869a4d86b68a2d96c20f21df",
    description1:"Обычная комплектация Polar Stone 01, рассчитанная на шестерых пассажиров, идеально подходит для семейных поездок. Благодаря тщательно продуманной планировке и настройке сидений, она предоставляет пользователям возможность индивидуального выбора, что свидетельствует о глубоком понимании компанией Polar Stone потребностей своих клиентов.",
    description2:"Внешний вид автомобиля выдержан в единой стилистике с остальными моделями Polar Stone 01, демонстрируя строгий и запоминающийся дизайн, который внушает чувство уверенности и безмятежности. Массивная передняя решетка радиатора в сочетании с заостренными фарами подчеркивает неповторимый характер автомобиля. Плавные боковые линии кузова гармонично перетекают в заднюю часть, где установлены сквозные задние фонари, что делает автомобиль более узнаваемым и добавляет ему современности.",
    description3:"Автомобиль использует ту же силовую установку, что и обычная семиместная модель, включая 1.5-литровый четырехцилиндровый двигатель с турбонаддувом и пару электромоторов, формирующих гибридную систему. На чистом электричестве можно проехать до 160 км, а общий запас хода достигает 860 км. Салон организован по схеме 2+2+2, предлагая шесть раздельных мест. Сиденья второго ряда оборудованы электрическими подставками для ног и обогревом, повышая комфорт пассажиров. Мультимедийная система управляется через 15.6-дюймовый экран и обладает широким набором возможностей. В базовую комплектацию входят камера заднего вида, круиз-контроль и другие системы помощи водителю, делающие вождение более удобным и безопасным."
  },
  //#endregion

  //#endregion
  
]

export function selectSEOIntroduction(code) {
  return vehicleModelCode.find(item => item.codeNumber === code)
}